import { BasePage } from "components/BasePage/BasePage";
import { QuestionaireTabs } from "./QuestionaireTabs";

export const QuestionaireBackOffice = () => {
  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        { label: "Questionaire", location: "/_backoffice/_questionaire" },
      ]}
    >
      <QuestionaireTabs />
    </BasePage>
  );
};
