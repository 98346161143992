import { ColumnSortConfig, Sorting } from "types";

export const sorting = <T>(
  a: T,
  b: T,
  sortOrder: Sorting,
  sortColumn: string,
  columnSortMap: Record<string, ColumnSortConfig<T>>
) => {
  const columnConfig = columnSortMap[sortColumn];

  if (!columnConfig) {
    return 0;
  }

  const comparison = columnConfig
    .accessor(a)
    .localeCompare(columnConfig.accessor(b));

  return sortOrder === Sorting.asc ? comparison : -comparison;
};
