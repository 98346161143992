import Form from "forms/Form";
import React, { useContext, useTransition } from "react";
import { AxiosInstance } from "axios";
import { CEForm } from "@slootsantos/certain-forms";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FormFieldType } from "@slootsantos/certain-forms/dist/types";

import { useClient } from "utils/client";
import { Project, TeamMember } from "types";
import {
  ProjectPayload,
  requestDelete,
  requestUpload,
} from "./Project/hooks/useCreateProject";
import { ControlContext } from "context/Controls";
import { TrackingContext } from "context/Tracking";
import { FeedbackContext } from "context/Feedback";
import { BasePage } from "components/BasePage/BasePage";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";
import { useTranslation } from "react-i18next";

interface EditRouteParams {
  projectId: string;
}

const updateProject = async (
  client: AxiosInstance,
  projectId: string,
  payload: ProjectPayload
) => {
  await client.put(`/projects/${projectId}`, {
    payload: payload.project,
  });

  // Do not parallelize those calls!
  for (const fileKey of Object.keys(payload.uploads)) {
    const file = payload.uploads[fileKey];
    if (!file || !file.touched) continue;

    if (!file.image && !file.key && file.touched) {
      await requestDelete(client, fileKey, projectId);
      continue;
    }

    await requestUpload(
      client,
      payload.uploads[fileKey].image,
      fileKey,
      projectId
    );
  }
};

const useDerivedData = (client: AxiosInstance, projectId: string) => {
  const { data, isLoading } = useQuery(["projects", projectId], async () => {
    const { data } = await client.get<Project>(`/projects/${projectId}`);

    return data;
  });

  const _uploads = data?.documents?.reduce((agg, doc) => {
    agg[doc.type] = {
      image: new File([], doc.name.split("/upload/")[1]),
      location: doc.location,
    };
    return agg;
  }, {} as Record<string, { image: File; location?: string }>);

  return { data: { ...data, _uploads }, isLoading };
};

const enhanceProjectDefaults = (data: Project & any) => {
  if (!data.engineer) return;
  const enhancedData = { ...data };

  enhancedData.engineer.country = data.engineer.country;
  enhancedData.manufacturer.country = data.manufacturer.country;

  return enhancedData;
};

export const useUpdateProject = (projectId: string) => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { track } = React.useContext(TrackingContext);
  const { displayNotification } = useContext(ControlContext);
  const { displayFeedback } = React.useContext(FeedbackContext);

  const { mutate, isLoading } = useMutation(
    async (params: {
      projectId: string;
      payload: ProjectPayload;
      oldTeam?: TeamMember[];
    }) => await updateProject(client, params.projectId, params.payload),
    {
      onError: (e) =>
        displayNotification!(
          t("notification.saving.generic_failure"),
          (e as Error).message,
          "error"
        ),
      onSuccess: async (_, v) => {
        const { is_blueprint } = v.payload.project.manufacturer;
        await queryClient.refetchQueries(["projects", projectId]);

        if (is_blueprint) {
          queryClient.invalidateQueries(["manufacturers"]);
        }

        track("update project");

        displayFeedback!(FeedbackLocation.BaseInformation);
        displayNotification!(t("notification.saving.generic_success"), "");

        history.push("/projects/" + projectId + "?saved=true");
      },
    }
  );

  return { updateProject: mutate, isLoading };
};

const useAutosaveProject = (projectId: string) => {
  const { t } = useTranslation();
  const client = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    async (params: {
      projectId: string;
      payload: ProjectPayload;
      oldTeam?: TeamMember[];
    }) => await updateProject(client, params.projectId, params.payload),
    {
      onError: (e) =>
        displayNotification!(
          t("notification.saving.generic_failure"),
          (e as Error).message,
          "error"
        ),
      onSuccess: async (_, v) => {
        await queryClient.refetchQueries(["projects", projectId]);
        displayNotification!(
          t("notification.saving.generic_success"),
          t("notification.saving.autosave_success")
        );
      },
    }
  );

  return { autosaveProject: mutate, isLoading };
};

export const EditProject = () => {
  const client = useClient();
  const { projectId } = useParams<EditRouteParams>();
  const { autosaveProject } = useAutosaveProject(projectId);
  const { updateProject, isLoading: isLoadingUpdate } =
    useUpdateProject(projectId);
  const { data, isLoading } = useDerivedData(client, projectId);
  const [blocking, setBlocking] = React.useState(true);
  const [formData, setFormData] = React.useState(CEForm);

  React.useEffect(() => {
    if (!formData?.sections?.length) return;

    const copy = structuredClone(formData);
    const idx = copy.sections.findIndex((s) => s.name === "team");
    copy.sections[idx].count = data.team?.length;
    const manuSectionIdx = copy.sections.findIndex(
      (s) => s.name === "manufacturer"
    );
    const companyFieldIdx = copy.sections[manuSectionIdx].fields.findIndex(
      (f) => f.name === "company"
    );
    copy.sections[manuSectionIdx].fields[companyFieldIdx].type =
      FormFieldType.manufacturerSelect;

    setFormData(copy);
    setBlocking(
      isLoading || !Boolean(data.projectdata) || !Boolean(copy.sections?.length)
    );
  }, [data.projectdata, isLoading, data.team]);

  return (
    <BasePage
      loading={blocking || isLoadingUpdate}
      overLay={isLoadingUpdate}
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: data?.projectdata?.product_name! || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.basedata.label",
          location: `/projects/${projectId}/edit`,
        },
      ]}
    >
      <Form
        blocking={blocking}
        formData={formData}
        context="editProject"
        defaultValues={{ ...enhanceProjectDefaults(data) }}
        onAutosave={(values: any) => {
          const { _uploads, ...project } = values;

          autosaveProject({
            projectId,
            payload: {
              project: {
                ...project,
                // DO NOT SAVE manufacturer as blueprint on autosave
                manufacturer: { ...project.manufacturer, is_blueprint: false },
              },
              uploads: _uploads,
            },
            oldTeam: data.team,
          });
        }}
        confirmationSubline={"form.project.delete_confirmation_subline"}
        onSubmit={(values: any) => {
          const { _uploads, ...project } = values;

          updateProject({
            projectId,
            payload: { project, uploads: _uploads },
            oldTeam: data.team,
          });
        }}
      />
    </BasePage>
  );
};
