import React, { useEffect } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

interface EditorProps {
  onBlur: (content: string) => void;
  defaultValue?: string;
  label?: string;
  id?: string;
  required?: boolean;
}

export const WysiwygInput = (props: EditorProps) => {
  const [value, setValue] = React.useState(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue?.replaceAll("\n", "<br>"));
  }, [props.defaultValue]);

  return (
    <div className="py-4">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {props.label}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>

      <div className="bg-white">
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          onBlur={(_, __, editor) => props.onBlur(editor.getHTML())}
        />
      </div>
    </div>
  );
};
