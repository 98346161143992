import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { SelectInputProps } from "./types";
import { classNames } from "utils/classNames";
import { useCountries } from "hooks/useCountries";
import Collapsible from "components/Collapsible/Collapsible";

const styles = {
  control: (css: any) => ({
    ...css,
    borderColor: "none",
    borderWidth: "none",
    borderRadius: "none",
  }),
  option: (css: any) => ({
    ...css,
    backgroundColor: "none",
    textColor: "none",
  }),
  input: (css: any) => ({
    ...css,
    input: {
      outline: "none",
    },
    "*:focus": {
      boxShadow: "none",
    },
  }),
};

const CountrySelectInput: React.FunctionComponent<SelectInputProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  const { countries, isLoading, getCountryByLanguage } = useCountries();

  return (
    <div
      className="col-span-6 sm:col-span-3"
      data-test="country-select-wrapper"
    >
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
      </div>

      <Select
        data-test="country-select"
        required
        placeholder={t("form.basic.choose_country")}
        value={
          !!props.defaultValue && {
            value: props.defaultValue,
            label: props.defaultValue,
          }
        }
        onChange={(value) => {
          // @ts-ignore
          props.onBlur!({ target: { value: value?.value } });
        }}
        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 sm:text-sm"
        styles={styles}
        classNames={{
          control: ({ isFocused }) =>
            `border border-gray-300 rounded-md ${
              isFocused ? "border-cyan-500" : ""
            }`,
          option: ({ isFocused, isSelected }) =>
            classNames(
              isFocused ? "bg-cyan-500" : "",
              isSelected ? "bg-cyan-400" : ""
            ),
        }}
        options={countries?.map((option) => ({
          value: getCountryByLanguage(option, i18n.language),
          label: getCountryByLanguage(option, i18n.language),
        }))}
        isLoading={isLoading}
        menuPosition="fixed"
      />
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default CountrySelectInput;
