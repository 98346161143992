import * as React from "react";
import { useParams } from "react-router-dom";

import { RiskEdit } from "components/Risk/Edit";
import { BasePage } from "components/BasePage/BasePage";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";

interface RiskCreateRouteParams {
  riskId: string;
  projectId: string;
}

export const EditRisk = () => {
  const { projectId, riskId } = useParams<RiskCreateRouteParams>();
  const { project } = useProjectDetails(projectId);

  const risk = project?.risks?.find(
    ({ resource_id }: any) => resource_id === riskId
  );

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.risks.label",
          location: `/projects/${projectId}/risks`,
        },
        {
          label: "steps.risks.edit.label",
          location: `/projects/${projectId}/risks/${riskId}/edit`,
        },
      ]}
      loading={!risk}
    >
      <div>
        <RiskEdit projectId={projectId} risk={risk!} />
      </div>
    </BasePage>
  );
};
