import * as React from "react";
import Form from "forms/Form";
import { manufacturerSection } from "@slootsantos/certain-forms/dist/forms/ce/sections/manufacturer.section";

import { Manufacturer } from "types";
import { useUpdateManufacturer } from "./hooks/useUpdateManufacturer";
import { ImageUploads } from "pages/Project/hooks/useCreateProject";

interface ManufacturerEditProps {
  manufacturerId: string;
  manufacturer: Manufacturer & { _uploads: ImageUploads };
}

export const ManufacturerEdit: React.FunctionComponent<
  ManufacturerEditProps
> = ({ manufacturer }) => {
  const update = useUpdateManufacturer(manufacturer.crn);

  return (
    <div>
      <Form
        context="editManufacturer"
        formData={{ sections: [manufacturerSection] }}
        defaultValues={{ manufacturer, _uploads: manufacturer._uploads }}
        onSubmit={(v: any) => update(v)}
        saveText="form.manufacturer.save"
      />
    </div>
  );
};
