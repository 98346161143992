import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Project } from "types";
import { DocType } from "components/Documents/types";
import { BasePage } from "components/BasePage/BasePage";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { DocumentTable } from "components/Documents/DocumentTable";
import { zipDownload } from "components/FileExplorer/FileExplorer";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import { usableDocs } from "components/Documents/data/docDefinitions";
import { requiredDocsMapping } from "components/Documents/data/requiredDocsMapping";

interface RiskRouteParams {
  projectId: string;
}

const useRenderableDocs = (project?: Project) => {
  if (!project || !project.documents?.length) {
    return false;
  }

  return project.documents
    ?.filter(
      (doc) =>
        Object.values(DocType).includes(doc.type) &&
        requiredDocsMapping[project.projectdata.category].includes(doc.type)
    )
    ?.map((doc) => doc.type as DocType)
    ?.concat(...requiredDocsMapping[project.projectdata.category])
    ?.every((doc) => !!usableDocs[doc].completeFn(project));
};

export const Documents = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<RiskRouteParams>();
  const { project, fetchInProgress, updateVersion, updateInProgress } =
    useProjectDetails(projectId);

  const [selected, setSelected] = useState<DocType[]>([]);
  const [downloadable, setDownloadable] = useState(false);
  const renderable = useRenderableDocs(project);

  const handleSelect = useCallback(
    (docs: DocType[]) => {
      setSelected(docs);
      setDownloadable(
        docs.length > 0 &&
          docs.every((d) => project?.documents.find((pd) => pd.type === d))
      );
    },
    [project]
  );

  const handleDownload = useCallback(() => {
    if (!project) return;

    zipDownload(
      project.documents.filter((d) => selected.includes(d.type)),
      projectId,
      project.projectdata.product_name
    );
  }, [project, projectId, selected]);

  const handleGenerator = useCallback(() => {
    if (!project || !renderable) return;

    // pass empty array for default behavior, that is rendering **all** documents
    updateVersion([]);
  }, [project, renderable, updateVersion]);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.documents.label",
          location: `/projects/${projectId}/documents`,
        },
      ]}
      loading={fetchInProgress}
    >
      <div>
        <div className="pb-5 ">
          <h3 className="text-3xl leading-6 font-medium text-gray-900">
            {t("documents.label")}
            <span className="inline text-sm pl-2 text-cyan-600 cursor-pointer">
              Wiki
            </span>
          </h3>

          <p className="text-sm text-gray-500 block pt-8 w-1/2">
            <Trans
              i18nKey="documents.subline"
              values={{
                category: t(
                  `common.selector.product_category.${project?.projectdata.category}`
                ),
              }}
            />
          </p>
        </div>
      </div>
      <div className="pt-2 text-right space-x-2 flex items-center justify-end">
        <PrimaryButton onClick={handleDownload} disabled={!downloadable}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
            />
          </svg>
          {t("documents.download.load_selected")}
        </PrimaryButton>

        <PrimaryButton
          onClick={handleGenerator}
          disabled={updateInProgress || !renderable}
        >
          {updateInProgress
            ? `Erstelle Dokumenten-Revision ${
                project!.projectdata.version + 1
              }...`
            : t("documents.download.generate_revision")}
        </PrimaryButton>
      </div>
      <div>
        <DocumentTable project={project} onSelect={handleSelect} />
      </div>
    </BasePage>
  );
};
