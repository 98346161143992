import React, { useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Transforms,
  Descendant,
  BaseEditor,
  Text,
} from "slate";
import {
  Slate,
  Editable,
  withReact,
  useSlate,
  ReactEditor,
  useSlateStatic,
} from "slate-react";
import { withHistory, HistoryEditor } from "slate-history";
import {
  CustomEditor,
  CustomText,
  RenderElementProps,
  FormatMark,
  HOTKEYS,
  ManualEditorProps,
  MessageBoxElement,
  MessageBoxType,
  HeadingLevel,
  ListType,
} from "components/ManualBuilder/types";
import {
  toggleMark,
  Toolbar,
} from "components/ManualBuilder/components/toolbar";

const getParentMessageBox = (
  editor: CustomEditor,
  path: number[]
): MessageBoxType => {
  try {
    const [node] = Editor.parent(editor, path);
    if (SlateElement.isElement(node) && node.type === "message-box") {
      return node.boxType;
    }
    return "warning"; // fallback
  } catch {
    return "warning"; // fallback
  }
};

const getMessageBoxStyle = (
  boxType: MessageBoxType
): Record<string, string> => {
  const styles = {
    warning: {
      box: "bg-yellow-50 border border-yellow-400 rounded-md my-4 overflow-hidden",
      header: "bg-yellow-100 px-4 py-2 font-semibold text-yellow-800",
      content: "p-4",
    },
    info: {
      box: "bg-blue-50 border border-blue-400 rounded-md my-4 overflow-hidden",
      header: "bg-blue-100 px-4 py-2 font-semibold text-blue-800",
      content: "p-4",
    },
    danger: {
      box: "bg-red-50 border border-red-400 rounded-md my-4 overflow-hidden",
      header: "bg-red-100 px-4 py-2 font-semibold text-red-800",
      content: "p-4",
    },
  };
  return styles[boxType];
};

const HeadingComponent = ({
  level,
  attributes,
  children,
}: {
  level: HeadingLevel;
  attributes: React.HTMLAttributes<HTMLHeadingElement>;
  children: React.ReactNode;
}) => {
  const className = {
    1: "text-2xl font-bold mb-4",
    2: "text-xl font-bold mb-3",
    3: "text-lg font-bold mb-2",
  }[level];

  switch (level) {
    case 1:
      return (
        <h1 {...attributes} className={className}>
          {children}
        </h1>
      );
    case 2:
      return (
        <h2 {...attributes} className={className}>
          {children}
        </h2>
      );
    case 3:
      return (
        <h3 {...attributes} className={className}>
          {children}
        </h3>
      );
  }
};

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  switch (element.type) {
    case "heading":
      return (
        <HeadingComponent
          level={element.level}
          attributes={attributes as React.HTMLAttributes<HTMLHeadingElement>}
          children={children}
        />
      );
    case "image":
      return (
        <div {...attributes} contentEditable={false} className="my-4">
          <div contentEditable={false}>
            <img
              src={element.url}
              alt=""
              className="max-w-full h-auto rounded"
            />
          </div>
          {children}
        </div>
      );
    case "list": {
      const listProps = {
        ...attributes,
        className: "pl-10 my-4",
      };
      return element.format === "numbered" ? (
        <ol
          {...(listProps as React.OlHTMLAttributes<HTMLOListElement>)}
          className={`${listProps.className} list-decimal`}
        >
          {children}
        </ol>
      ) : (
        <ul
          {...(listProps as React.HTMLAttributes<HTMLUListElement>)}
          className={`${listProps.className} list-disc`}
        >
          {children}
        </ul>
      );
    }
    case "list-item": {
      const listItemProps = {
        ...attributes,
      };
      return (
        <li {...(listItemProps as React.LiHTMLAttributes<HTMLLIElement>)}>
          {children}
        </li>
      );
    }
    case "table":
      return (
        <div className="my-4 overflow-x-auto">
          <table
            {...attributes}
            className="w-full border-collapse border border-gray-300"
          >
            <tbody className="bg-white">{children}</tbody>
          </table>
        </div>
      );
    case "table-row":
      return (
        <tr {...attributes} className="border-b border-gray-300">
          {children}
        </tr>
      );
    case "table-cell":
      return element.isHeader ? (
        <th
          {...attributes}
          className="border border-gray-300 bg-gray-100 p-2 text-left font-semibold"
          style={{ position: "relative", minWidth: "120px" }}
        >
          <div
            contentEditable={false}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              pointerEvents: "none",
              backgroundColor: "transparent",
              border: "2px solid transparent",
            }}
          />
          {children}
        </th>
      ) : (
        <td
          {...attributes}
          className="border border-gray-300 p-2"
          style={{ position: "relative", minWidth: "120px" }}
        >
          <div
            contentEditable={false}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              pointerEvents: "none",
              backgroundColor: "transparent",
              border: "2px solid transparent",
            }}
          />
          {children}
        </td>
      );
    case "message-box": {
      const style = getMessageBoxStyle(element.boxType);
      return (
        <div {...attributes} className={style.box}>
          {children}
        </div>
      );
    }
    case "title": {
      const boxType = getParentMessageBox(editor, path);
      const style = getMessageBoxStyle(boxType);
      return (
        <div {...attributes} className={style.header}>
          {children}
        </div>
      );
    }
    case "content": {
      const boxType = getParentMessageBox(editor, path);
      const style = getMessageBoxStyle(boxType);
      return (
        <div {...attributes} className={style.content}>
          {children}
        </div>
      );
    }
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf = ({
  attributes,
  children,
  leaf,
}: {
  attributes: React.HTMLAttributes<HTMLSpanElement>;
  children: React.ReactNode;
  leaf: CustomText;
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};
