import React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  Prompt,
  useLocation,
} from "react-router-dom";

import { AuthContext } from "context/Auth";
import { TrackingContext } from "context/Tracking";
import { useUser } from "pages/Settings/hooks/useUser";
import { hasUnsavedChanges } from "utils/localStorage";

interface ProtectedProps {
  component: () => JSX.Element | null;
  shouldPrompt?: boolean | false;
}

interface PromptRouteProps {
  shouldPrompt?: boolean | false;
}

const PromptRoute = ({ shouldPrompt }: PromptRouteProps) => {
  const { track } = React.useContext(TrackingContext);
  const routeLocation = useLocation();

  React.useEffect(() => {
    if (shouldPrompt) {
      window.onbeforeunload = (e) => {
        const hasChanges = hasUnsavedChanges(routeLocation);

        if (hasChanges) {
          e.preventDefault();
        }
      };
    }

    return function cleanUp() {
      window.onbeforeunload = null;
    };
  });

  return (
    <Prompt
      when={!!shouldPrompt}
      message={(location) => {
        if (
          location.search.match("step=") ||
          location.search.match("saved=true") ||
          !hasUnsavedChanges(routeLocation)
        ) {
          return true;
        }

        track("leave page", { page: location.pathname });

        return "Sie haben ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?";
      }}
    />
  );
};

export const ProtectedRoute = ({
  component: Component,
  shouldPrompt = false,
  ...rest
}: ProtectedProps & RouteProps) => {
  const { isAuthenticated } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return (
            <>
              <PromptRoute shouldPrompt={shouldPrompt} />
              <Component {...props} />
            </>
          );
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export const AdminRoute = ({
  component: Component,
  shouldPrompt = false,
  ...rest
}: ProtectedProps & RouteProps) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const { user, isLoading } = useUser();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) return null;
        if (isAuthenticated && user?.is_admin) {
          return (
            <>
              <PromptRoute shouldPrompt={shouldPrompt} />
              <Component {...props} />
            </>
          );
        }
        return (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};
