import React from "react";
import { useHistory, useParams } from "react-router";
import { useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";
import { AnnexSection, Directive } from "./types";
import { FollowupSelector } from "./FollowupSelector";
import TextInput from "components/inputs/TextInput";
import SelectInput from "components/inputs/SelectInput";
import { useAnnexSections } from "./hooks/useAnnexSections";
import { AnnexSectionSelector } from "./AnnexSectionSelector";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { SecondaryButton } from "components/Button/SecondaryButton";
import {
  QuestionaireItem,
  useQuestionaireItem,
  useQuestionaireItems,
} from "./hooks";
import { BasePage } from "components/BasePage/BasePage";
import { ProductCategorySelector } from "components/common/ProductCategorySelector";
import { WysiwygInput } from "components/inputs/WysiwygInput";
import { useTranslation } from "react-i18next";

const useEditQuestionItem = (questionaireId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: saveAndBack, isLoading } = useMutation(
    async (item: QuestionaireItem) => {
      return await client.put(`/questionaireItems/${questionaireId}`, item);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          "questionaireItem",
          questionaireId,
        ]);
        await queryClient.invalidateQueries(["questionaireItems"]);
        history.goBack();
      },
    }
  );

  const { mutate: save, isLoading: isSaving } = useMutation(
    async (item: QuestionaireItem) => {
      return await client.put(`/questionaireItems/${questionaireId}`, item);
    }
  );

  return { saveAndBack, save, isSaving: isSaving || isLoading };
};

const useDeleteQuestionItem = (questionaireId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    async () => {
      return await client.delete(`/questionaireItems/${questionaireId}`);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          "questionaireItem",
          questionaireId,
        ]);
        await queryClient.invalidateQueries(["questionaireItems"]);
        history.push("/_backoffice/_questionaire");
      },
    }
  );

  return mutate;
};

interface RouteParams {
  questionaireId: string;
}

export const EditQuestion = () => {
  const { t } = useTranslation();
  const questionaireItems = useQuestionaireItems();
  const { annexSections, isLoading } = useAnnexSections();
  const { questionaireId } = useParams<RouteParams>();
  const questionaireItem = useQuestionaireItem(questionaireId);

  const { save, saveAndBack } = useEditQuestionItem(questionaireId);
  const deleteupdateQuestion = useDeleteQuestionItem(questionaireId);

  const [needsSave, setNeedsSave] = React.useState<boolean>(false);
  const [isFirstLevel, setIsFirstLevel] = React.useState<boolean>(true);
  const [isCategoryQuestion, setIsCategoryQuestion] =
    React.useState<boolean>(false);
  const [category, setCategory] = React.useState<string | null>(null);
  const [isExit, setIsExit] = React.useState<boolean>(false);
  const [isDirective, setIsDirective] = React.useState<boolean>(false);
  const [article, setArticle] = React.useState<string>("");
  const [question, setQuestion] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [refNumber, setRefNumber] = React.useState<string>("");
  const [directive, setDirective] = React.useState<Directive>();
  const [original1, setOriginal1] = React.useState<string>("");
  const [original2, setOriginal2] = React.useState<string>("");
  const [questionLang, setQuestionLang] = React.useState<string>("");
  const [questionsByLang, setQuestionsByLang] = React.useState<
    Record<string, string>
  >({});
  const [explanation, setExplanation] = React.useState<string>("");
  const [explanationsByLang, setExplanationsByLang] = React.useState<
    Record<string, string>
  >({});

  const [info, setInfo] = React.useState<string>("");
  const [infosByLang, setInfosByLang] = React.useState<Record<string, string>>(
    {}
  );
  const [org1ByLang, setOrg1ByLang] = React.useState<Record<string, string>>(
    {}
  );

  const [org2ByLang, setOrg2ByLang] = React.useState<Record<string, string>>(
    {}
  );

  const [followups, setFollowUps] = React.useState<QuestionaireItem[]>([]);
  const [selectedAnnexSections, setAnnexSections] = React.useState<
    AnnexSection[]
  >([]);

  const saveChanges = React.useCallback(
    async (goBack: boolean = true) => {
      const payload = {
        ...questionaireItem,
        questions: Object.keys(questionsByLang).map((lang) => {
          return {
            ...questionaireItem?.questions?.find((q) => q.language === lang),
            language: lang,
            content: questionsByLang[lang],
          };
        }),
        explanations: Object.keys(explanationsByLang).map((lang) => {
          return {
            ...questionaireItem?.explanations?.find((q) => q.language === lang),
            language: lang,
            content: explanationsByLang[lang],
          };
        }),
        originalTexts1: Object.keys(org1ByLang).map((lang) => {
          return {
            ...questionaireItem?.originalTexts1?.find(
              (q) => q.language === lang
            ),
            language: lang,
            content: org1ByLang[lang],
          };
        }),
        originalTexts2: Object.keys(org2ByLang).map((lang) => {
          return {
            ...questionaireItem?.originalTexts2?.find(
              (q) => q.language === lang
            ),
            language: lang,
            content: org2ByLang[lang],
          };
        }),
        infos: Object.keys(infosByLang).map((lang) => {
          return {
            ...questionaireItem?.infos?.find((q) => q.language === lang),
            language: lang,
            content: infosByLang[lang],
          };
        }),
        followups:
          followups?.map((f) => f.resource_id!) || questionaireItem!.followups,
        annex_sections: selectedAnnexSections?.map((as) => as.resource_id!),
        is_first_level: isFirstLevel,
        is_exit_question: isExit,
        is_directive_question: isDirective,
        ref_number: refNumber,
        product_category: category,
        comment,
        article,
        directive: directive!,
      };

      if (!goBack) {
        return save(payload);
      }

      saveAndBack(payload);
    },
    [
      followups,
      selectedAnnexSections,
      saveAndBack,
      save,
      article,
      explanationsByLang,
      infosByLang,
      org1ByLang,
      org2ByLang,
      isFirstLevel,
      isDirective,
      questionaireItem,
      questionsByLang,
      isExit,
      refNumber,
      comment,
      category,
      directive,
    ]
  );

  React.useEffect(() => {
    if (!needsSave) return;
    saveChanges(false);
    setNeedsSave(false);
  }, [followups, selectedAnnexSections]);

  React.useEffect(() => {
    if (!questionaireItem?.resource_id) return;

    const existingFollowUps = questionaireItem?.followups
      ?.map(
        (resource_id) =>
          questionaireItems?.find((q) => q.resource_id === resource_id)!
      )
      .filter(Boolean);

    const existingAnnexSections = questionaireItem?.annex_sections
      ?.map((id) => annexSections?.find((as) => as.resource_id === id)!)
      .filter(Boolean);

    const gByLang =
      questionaireItem?.questions?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const exByLang =
      questionaireItem?.explanations?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const infoByLang =
      questionaireItem?.infos?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const orgiginal1ByLang =
      questionaireItem?.originalTexts1?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const orgiginal2ByLang =
      questionaireItem?.originalTexts2?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    setFollowUps(existingFollowUps);
    setAnnexSections(existingAnnexSections);
    setQuestion(
      (questionaireItem?.questions?.length &&
        questionaireItem?.questions.find((e) => e.language === "DE")
          ?.content) ||
        ""
    );
    setInfo(
      (questionaireItem?.infos?.length &&
        questionaireItem?.infos.find((e) => e.language === "DE")?.content) ||
        ""
    );
    setExplanation(
      (questionaireItem?.explanations?.length &&
        questionaireItem?.explanations.find((e) => e.language === "DE")
          ?.content) ||
        ""
    );

    setOriginal1(
      (questionaireItem?.originalTexts1?.length &&
        questionaireItem?.originalTexts1.find((e) => e.language === "DE")
          ?.content) ||
        ""
    );

    setOriginal2(
      (questionaireItem?.originalTexts2?.length &&
        questionaireItem?.originalTexts2.find((e) => e.language === "DE")
          ?.content) ||
        ""
    );

    setIsFirstLevel(questionaireItem?.is_first_level || false);
    setIsDirective(questionaireItem?.is_directive_question || false);
    setIsExit(questionaireItem?.is_exit_question || false);
    setArticle(questionaireItem?.article || "");
    setComment(questionaireItem?.comment || "");
    setDirective(questionaireItem?.directive);
    setRefNumber(questionaireItem?.ref_number || "");
    setQuestionsByLang(gByLang);
    setInfosByLang(infoByLang);
    setExplanationsByLang(exByLang);
    setOrg1ByLang(orgiginal1ByLang);
    setOrg2ByLang(orgiginal2ByLang);
    setQuestionLang("DE");
    setIsCategoryQuestion(!!questionaireItem.product_category);
    setCategory(questionaireItem.product_category);
  }, [questionaireItem, annexSections, questionaireItems]);

  const loading =
    !questionaireItem ||
    !questionaireItems?.length ||
    !annexSections ||
    isLoading;

  return (
    <BasePage
      loading={loading}
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        { label: "Questionaire", location: "/_backoffice/_questionaire" },
        { label: "Frage bearbeiten", location: "" },
      ]}
    >
      <div>
        <h2 className="text-lg">Frage bearbeiten</h2>
        <SecondaryButton onClick={deleteupdateQuestion}>
          Frage Löschen
        </SecondaryButton>
        <TextInput
          id="referenceNumber"
          label="Referenz-Nummer"
          // @ts-ignore
          defaultValue={refNumber}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            setRefNumber(e.target.value);
          }}
        />

        <SelectInput
          id="language"
          label="Sprache"
          options={[
            { value: "EN", label: "EN" },
            { value: "DE", label: "DE" },
          ]}
          // @ts-ignore
          value={questionLang}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            const language = e.target.value;
            setQuestion(questionsByLang[language] || "");
            setInfo(infosByLang[language] || "");
            setExplanation(explanationsByLang[language] || "");
            setOriginal1(org1ByLang[language] || "");
            setOriginal2(org2ByLang[language] || "");
            setQuestionLang(language);
          }}
        />

        <TextInput
          id="question"
          label="Frage"
          // @ts-ignore
          defaultValue={question}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            setQuestion(e.target.value);
            setQuestionsByLang({
              ...questionsByLang,
              [questionLang]: e.target.value,
            });
          }}
        />

        <div className="py-2">
          <input
            type="checkbox"
            id="isDirective"
            name="isDirective"
            checked={isDirective}
            onChange={() => setIsDirective(!isDirective)}
          />
          <label htmlFor="isDirective">
            {" "}
            Richtlinienfrage (wird also gefragt, um anzuwendene Richtlinien zu
            bestimmen)
          </label>
        </div>

        <div className="py-2">
          <input
            type="checkbox"
            id="scales"
            name="scales"
            checked={isFirstLevel}
            onChange={() => setIsFirstLevel(!isFirstLevel)}
          />
          <label htmlFor="scales">
            {" "}
            Ist eine Top-Level-Frage (wird als erstes gefragt z.b. "Ist eine
            Maschine?")
          </label>
        </div>

        <div className="py-2">
          <input
            type="checkbox"
            id="exit"
            name="exit"
            checked={isExit}
            onChange={() => setIsExit(!isExit)}
          />
          <label htmlFor="exit"> Ist eine Ausschlussfrage?</label>
        </div>

        <div className="py-2">
          <SelectInput
            required
            id="directive"
            name="directive"
            label="Welcher Richtlinie wird diese Frage zugeordnet?"
            options={[
              { value: Directive.lvd, label: "Niederspannungsrichtlinie" },
              { value: Directive.mrl, label: "Maschinenrichtlinie" },
              { value: Directive.mvo, label: "Maschinenverordnung" },
              { value: Directive.pressureequip, label: "Druckgeräte" },
              { value: Directive.pressurevessel, label: "Druckbehälter" },
              { value: Directive.rohs, label: "RoHS" },
              { value: Directive.atex, label: "Atex" },
              { value: Directive.outdoor, label: "Outdoor" },
              { value: Directive.emv, label: "EMV" },
            ]}
            defaultValue={directive}
            // @ts-ignore
            onBlur={(e) => {
              // @ts-ignore
              const directive = e.target.value as Directive;
              setDirective(directive);
            }}
          />
        </div>

        <TextInput
          id="article"
          label="Artikel"
          // @ts-ignore
          defaultValue={article}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            setArticle(e.target.value);
          }}
        />

        <WysiwygInput
          id="explanation"
          label="Erläuterung"
          defaultValue={explanation}
          onBlur={(content) => {
            setExplanation(content);
            setExplanationsByLang({
              ...explanationsByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="example"
          label="Beispiel"
          defaultValue={info}
          onBlur={(content) => {
            setInfo(content);
            setInfosByLang({
              ...infosByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="original1"
          label="Originaltexts 1"
          defaultValue={original1}
          onBlur={(content) => {
            setOriginal1(content);
            setOrg1ByLang({
              ...org1ByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="original2"
          label="Hinweis/Empfehlung"
          defaultValue={original2}
          onBlur={(content) => {
            setOriginal2(content);
            setOrg2ByLang({
              ...org2ByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="comments"
          label="Interner Kommentar"
          defaultValue={comment}
          onBlur={(content) => {
            setComment(content);
          }}
        />
        <div>
          <h3 className="text-lg">Produkt-Kategorie</h3>
          <div className="py-2">
            <input
              type="checkbox"
              id="scales"
              name="scales"
              checked={isCategoryQuestion}
              onChange={() => {
                if (isCategoryQuestion) {
                  setCategory(null);
                }

                setIsCategoryQuestion(!isCategoryQuestion);
              }}
            />
            <label htmlFor="productCategory">
              {" "}
              Ist eine "Produkt-Kategorie"-Frage (Schritt 2 der EU
              Rechtsvorschriftenrecherche)?
            </label>

            {isCategoryQuestion && (
              <ProductCategorySelector
                onChange={setCategory}
                defaultSelection={category}
              />
            )}
          </div>
        </div>

        <FollowupSelector
          followups={followups}
          onChange={(f) => {
            setFollowUps(f);
            setNeedsSave(true);
          }}
        />

        <AnnexSectionSelector
          sections={selectedAnnexSections}
          onChange={(as) => {
            setAnnexSections(as);
            setNeedsSave(true);
          }}
        />

        <div className="py-2">
          <PrimaryButton onClick={saveChanges}>
            Änderungen speichern
          </PrimaryButton>
        </div>
      </div>
    </BasePage>
  );
};
