import React from "react";
import { FeedbackLocation } from "../FeedbackPopup";

const FEEDBACK_LS_KEY = "certain_beta_feedback";

type FeedbackStorage = Partial<Record<FeedbackLocation, boolean>>;

export const useFeedbackStorage = () => {
  const markRead = (location: FeedbackLocation) => {
    const lsItem = localStorage.getItem(FEEDBACK_LS_KEY);
    let feedbackStorage: FeedbackStorage;

    try {
      feedbackStorage = lsItem ? JSON.parse(lsItem) : {};
    } catch (_) {
      feedbackStorage = {};
    }

    feedbackStorage[location] = true;
    localStorage.setItem(FEEDBACK_LS_KEY, JSON.stringify(feedbackStorage));
  };

  const getRead = (location: FeedbackLocation) => {
    const lsItem = localStorage.getItem(FEEDBACK_LS_KEY);

    if (!lsItem) {
      return false;
    }

    try {
      const feedbackStorage: FeedbackStorage = JSON.parse(lsItem);

      return feedbackStorage[location];
    } catch (_) {
      return false;
    }
  };

  return { markRead, getRead };
};
