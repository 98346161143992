import React from "react";
import { AxiosInstance } from "axios";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import { useClient } from "utils/client";
import { Project, TranslatedStandard } from "types";
import { useConfirmation } from "context/Confirmation";
import { BasePage } from "components/BasePage/BasePage";
import { StandardSearch } from "components/StandardSearch";
import {
  useAllTranslatedStandards,
  useTranslatedStandards,
} from "hooks/useStandards";
import { DeleteButton } from "components/Button/DeleteButton";
import { useProjectDetails } from "./Details/hooks/useProjectDetails";

interface StandardSearchRouteParams {
  projectId: string;
}

interface StandardSelection {
  isSelected: boolean;
  standardId: string;
  name: string;
}

export interface FetchStandardResponse {
  projectId: string;
  standards: StandardSelection[] | null;
}

const postStandards = (
  client: AxiosInstance,
  projectId: string,
  project: Project,
  selectedStandards: string[]
) => {
  return client.put(`/projects/${projectId}`, {
    payload: {
      ...project,
      projectdata: {
        ...project.projectdata,
        completed_research: true,
      },
      standards: selectedStandards,
    },
  });
};

const Standards = () => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { projectId } = useParams<StandardSearchRouteParams>();
  const { project, fetchInProgress } = useProjectDetails(projectId);
  // TODO: need to figure a way to pass multiple directives too
  // For now, this can only be EITHER mrl OR lvd
  const { translations: translatedStandards } = useAllTranslatedStandards();
  const [selectedStandards, setSelectedStandards] = React.useState<string[]>(
    []
  );
  const { t } = useTranslation();

  React.useEffect(() => {
    const allStd = [...(project?.standards || [])];

    setSelectedStandards(allStd);
  }, [project?.standards]);

  const handleDelete = (name: string) => {
    const idx = selectedStandards.indexOf(name);

    const stds = [...selectedStandards];
    stds.splice(idx, 1);

    setSelectedStandards(stds);
  };

  const onSelect = (standard: TranslatedStandard) => {
    const selectedStandardWithVersion = [standard.name, standard.latest].join(
      ":"
    );

    setSelectedStandards(
      [selectedStandardWithVersion, ...selectedStandards].filter(
        (item, i, arr) => arr.findIndex((std) => std === item) === i
      )
    );
  };

  if (fetchInProgress) return null;

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: `${project?.projectdata.product_name || projectId}`,
          location: `/projects/${projectId}`,
        },
        {
          label: "standards.search.headline",
          location: `/projects/${projectId}/edit/standards`,
        },
      ]}
    >
      <div className="overflow-hidden">
        <StandardSearch
          onSelect={onSelect}
          // TODO: need to figure a way to pass multiple directives too
          // For now, this can only be EITHER mrl OR lvd
          // directive={project?.directives[0].name}
        />
      </div>

      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="flex-1 min-w-0">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
            {t("standards.search.table.title")}
          </h1>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-4">
          <button
            onClick={async () => {
              await postStandards(
                client,
                projectId,
                project!,
                selectedStandards
              );
              await queryClient.invalidateQueries(["projects", projectId]);

              history.push(`/projects/${projectId}?saved=true`);
            }}
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3"
          >
            {t("standards.search.table.save_standards")}
          </button>
        </div>
      </div>
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full">
          <table className="shadow-md">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">
                    {t("standards.search.table.standard")}
                  </span>
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <span className="">
                    {t("standards.search.table.directive")}
                  </span>
                </th>

                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("standards.search.table.type")}
                </th>

                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("standards.search.table.latest_version")}
                </th>

                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t("standards.search.table.standard_text")}
                </th>

                <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {selectedStandards.map((standard) => {
                const translation = translatedStandards?.find(
                  (t) => t.name === standard.split(":")[0]
                );

                return (
                  <StandardRow
                    key={standard}
                    standard={standard}
                    onDelete={handleDelete}
                    additionalData={translation}
                    translation={translation?.content}
                    isNew={!project?.standards?.includes(standard)}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </BasePage>
  );
};

export default Standards;

const StandardRow = (props: {
  isNew: boolean;
  standard: string;
  translation?: string;
  additionalData?: TranslatedStandard;
  onDelete: (id: string) => void;
}) => {
  const { openConfirmation } = useConfirmation();
  const { t } = useTranslation();

  const handleDelete = () => {
    openConfirmation(
      () => {
        props.onDelete(props.standard);
      },
      {
        label: t("form.basic.label"),
        subline: t("standards.search.table.delete_confirmation_subline"),
      }
    );
  };

  return (
    <tr>
      <td className="px-6 py-3  whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center space-x-3 lg:pl-2">
          <div
            className={`flex-shrink-0 w-2.5 h-2.5 rounded-full ${
              props.isNew ? "bg-green-500" : ""
            }`}
            aria-hidden="true"
          ></div>
          <span>{props.standard}</span>{" "}
          {props.additionalData?.retracted && (
            <span className="text-ms text-red-400">Zurückgezogen</span>
          )}
        </div>
      </td>

      <td className="px-6 py-3 text-sm text-gray-500 font-medium">
        {t(`directives.enum.${props.additionalData?.directive}`) || "..."}
      </td>

      <td className="px-6 py-3 text-sm text-gray-500 font-medium">
        {props.additionalData?.type || "..."}
      </td>

      <td className="px-6 py-3 text-sm text-gray-500 font-medium">
        {props.additionalData?.latest || "..."}
      </td>

      <td className="px-6 py-3 text-sm text-gray-500 font-medium">
        {props.translation || "..."}
      </td>

      <td className="pr-6">
        <div className="relative flex justify-end items-center">
          <DeleteButton flat={true} onClick={handleDelete} />
        </div>
      </td>
    </tr>
  );
};
