import React from "react";
import { useParams } from "react-router";

import TextInput from "components/inputs/TextInput";
import { BasePage } from "components/BasePage/BasePage";
import { Editor } from "components/TextEditor/Editor";
import { useEditDocumentation } from "./hooks/useEditDocumentation";
import { useDocumentation } from "./hooks/useDocumentations";

interface RouteParams {
  documentationId: string;
}

export const EditDocumentation = () => {
  const [name, setName] = React.useState<string>("");
  const { documentationId } = useParams<RouteParams>();
  const { documentation } = useDocumentation(documentationId);
  const { editDocumentation } = useEditDocumentation(documentationId);

  React.useEffect(() => {
    setName(documentation?.name || "");
  }, [documentation]);

  return (
    <BasePage
      loading={!documentation}
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Dokumentations Artikel",
          location: "/_backoffice/_docs",
        },
        {
          label: documentationId,
          location: `/_backoffice/_docs/${documentationId}`,
        },
      ]}
    >
      <TextInput
        required
        id="referenceNumber"
        label="Interner Dokumentationsname"
        defaultValue={name}
        // @ts-ignore
        onBlur={(e) => {
          // @ts-ignore
          setName(e.target.value);
        }}
      />
      <Editor
        defaultValue={documentation?.data}
        onSave={(data) => editDocumentation({ data, name })}
      />
    </BasePage>
  );
};
