import { useCallback, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { SparklesIcon } from "@heroicons/react/outline";
import { useClient } from "utils/client";

export enum Plan {
  Free = "free",
  ProMd = "pro-md",
  ProLg = "pro-lg",
  Cancelled = "cancelled",
}

const frequencies: {
  value: "month" | "year";
  label: string;
  priceSuffix: string;
}[] = [
  { value: "month", label: "Monatlich", priceSuffix: "/Monat" },
  { value: "year", label: "Jährlich", priceSuffix: "/Jahr" },
];
const tiers = [
  {
    name: "Einzelkämpfer",
    id: Plan.Free,
    href: "/dashboard",
    price: { month: "€159", year: "€1.908" },
    description: "Die Basis-Version für Einsteiger.",
    features: [
      "1 User",
      "30 Tage Testversion",
      "Monatlich kündbar",
      "Täglich aktualisierte Normen",
      "Richtlinienrecherche",
      "10 Jahre Daten-Speicherung",
    ],
    mostPopular: false,
    continuous: false,
    // cta: "Kostenlos fortfahren",
  },
  {
    name: "Dienstleister",
    id: Plan.ProMd,
    href: "#",
    price: { month: "€318", year: "€3.816" },
    description: "Der beste Preis für Ingenieurbüros.",
    features: [
      "2 User",
      "30 Tage Testversion",
      "Monatlich kündbar",
      "Unbegrenzte Projekte",
      "10 Jahre Daten-Speicherung",
      "48-hour support response time",
    ],
    mostPopular: false,
    continuous: true,
  },
  {
    name: "Mittelständer",
    id: Plan.ProLg,
    href: "#",
    price: { month: "€954", year: "€11.448" },
    description: "Für Ihren Wachstum.",
    features: [
      "5 User",
      "30 Tage Testversion",
      "Täglich aktualisierte Normen",
      "Richtlinienrecherche",
    ],
    mostPopular: true,
    continuous: true,
  },
  {
    name: "Enterprise",
    id: "pro-xl",
    href: "#",
    price: "Auf Anfrage",
    description: "Dedicated support and infrastructure for your company.",
    features: [
      "20 User",
      "30 Tage Testversion",
      "Unbegrenzte Projekte",
      "10 Jahre Daten-Speicherung",
      "Unbegrenzte Kollaborateure",
      "Dedizierte Infrastruktur",
    ],
    featured: true,
    continuous: false,
    cta: "Sales kontaktieren",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const useCheckoutPlan = () => {
  const client = useClient();

  return useCallback(
    async (plan: string, frequency: "month" | "year") => {
      const { data } = await client.post("/payment/checkout", {
        plan,
        frequency,
      });
      window.location.href = data;
    },
    [client]
  );
};

export const PlanSelection = () => {
  const [frequency, setFrequency] = useState(frequencies[0]);

  const checkoutPlan = useCheckoutPlan();

  return (
    <div className="">
      <div className="mx-auto px-4 lg:px-4">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-cyan-600">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for teams of all sizes
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
          quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200 bg-white"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-cyan-600 text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured
                  ? "bg-gray-900 ring-gray-900"
                  : "bg-white ring-gray-200",
                "rounded-3xl p-8 ring-1 xl:p-10"
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? "text-white" : "text-gray-900",
                  "text-lg font-semibold leading-8"
                )}
              >
                {tier.name}
              </h3>
              <p
                className={classNames(
                  tier.featured ? "text-gray-300" : "text-gray-600",
                  "mt-4 text-sm leading-6"
                )}
              >
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? "text-white" : "text-gray-900",
                    "text-4xl font-bold tracking-tight"
                  )}
                >
                  {typeof tier.price === "string"
                    ? tier.price
                    : tier.price[frequency.value]}
                </span>
                {typeof tier.price !== "string" ? (
                  <span
                    className={classNames(
                      tier.featured ? "text-gray-300" : "text-gray-600",
                      "text-sm font-semibold leading-6"
                    )}
                  >
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
              <a
                onClick={() => {
                  checkoutPlan(tier.id, frequency.value);
                }}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
                    : "bg-cyan-600 text-white shadow-sm hover:bg-cyan-500 focus-visible:outline-cyan-600",
                  "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer"
                )}
              >
                {tier.cta || "Lizenz kaufen"}
              </a>
              <ul
                role="list"
                className={classNames(
                  tier.featured ? "text-gray-300" : "text-gray-600",
                  "mt-8 space-y-3 text-sm leading-6 xl:mt-10"
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className={classNames(
                        tier.featured ? "text-white" : "text-cyan-600",
                        "h-6 w-5 flex-none"
                      )}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}

                {tier.continuous && (
                  <li className="flex gap-x-3">
                    <SparklesIcon
                      className={classNames(
                        tier.featured ? "text-white" : "text-cyan-600",
                        "h-6 w-5 flex-none"
                      )}
                      aria-hidden="true"
                    />
                    Stetig neue Funktionen
                  </li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PlanBadgeConfig: Record<Plan, { label: string; color: string }> = {
  [Plan.Free]: {
    label: "Free",
    color: "gray",
  },
  [Plan.ProMd]: {
    label: "Premium M",
    color: "green",
  },
  [Plan.ProLg]: {
    label: "Premium L",
    color: "blue",
  },
  [Plan.Cancelled]: {
    label: "Gekündigt",
    color: "yellow",
  },
};

export const PlanBadge = (props: { plan: Plan }) => {
  const { label, color } = PlanBadgeConfig[props.plan];
  const bgColor = `bg-${color}-50`;
  const textColor = `text-${color}-600`;
  const ringColor = `ring-${color}-500/10`;

  return (
    <span
      className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor} ml-2`}
    >
      {label}
      {/* Do not delete this div. The colors are required during the CSS purge */}
      <div className="hidden ring-blue-500/10 ring-green-500/10 ring-gray-500/10 ring-yellow-500/10 text-blue-600 text-green-600 gray-blue-600 text-yellow-600 bg-blue-50 bg-green-50 bg-yellow-50 bg-gray-50"></div>
    </span>
  );
};
