import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";
import { Modal } from "components/Modal/Modal";
import TextInput from "components/inputs/TextInput";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { ControlContext } from "context/Controls";
import i18n from "i18n";

interface InviteMemberModalProps {
  onClose: () => void;
}

export const InviteMemberModal: React.FunctionComponent<
  InviteMemberModalProps
> = (props) => {
  return (
    <>
      <Modal
        show={true}
        width="1/2"
        onClose={props.onClose}
        content={<InviteMemberForm onClose={props.onClose} />}
      />
    </>
  );
};

const inviteMember = async (client: AxiosInstance, email: string) => {
  try {
    const { data } = await client.post(`/accounts/invitations`, {
      target_email: email,
      language_code: i18n.language,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }
};

const useInviteMember = (closeHandler: () => void) => {
  const client = useClient();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    async (email: string) => await inviteMember(client, email),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["account"]);

        closeHandler();
        displayNotification!(t("notification.saving.invite_success"), "");
      },
    }
  );

  return { invite: mutate, isLoading };
};

interface InviteMemberFormProps {
  onClose: () => void;
}

const InviteMemberForm = (props: InviteMemberFormProps) => {
  const { t } = useTranslation();
  const { invite, isLoading } = useInviteMember(props.onClose);
  const [email, setEmail] = useState<string>("");

  return (
    <div>
      <span className="text-xl block">{t("form.teams.invite.label")}</span>
      <span className="text-xs text-gray-500">
        <Trans i18nKey="form.teams.invite.subline" />
        <br />
        <Trans i18nKey="form.teams.invite.callout" />
      </span>
      <div className="pt-4 pb-4">
        <TextInput
          data-test="invite-member-email"
          id="email"
          required
          uncontrolled
          label={t("form.teams.invite.email")}
          // @ts-ignore
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder={t("form.teams.invite.placeholder")}
          onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
            if (evt.key === "Enter") {
              email && invite(email);
            }
          }}
        />
      </div>

      <PrimaryButton
        float="right"
        disabled={!email || isLoading}
        onClick={() => invite(email)}
      >
        {isLoading
          ? t("form.teams.invite.cta_loading")
          : t("form.teams.invite.cta")}
      </PrimaryButton>
    </div>
  );
};
