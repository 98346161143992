import { Sorting } from "types";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

export const getChevronIcon = (
  column: string,
  sortColumn: string,
  sortOrder: Sorting
) => {
  if (column === sortColumn) {
    return sortOrder === Sorting.desc ? (
      <ChevronUpIcon className="w-5 h-5 ml-2" />
    ) : (
      <ChevronDownIcon className="w-5 h-5 ml-2" />
    );
  }

  return (
    <ChevronDownIcon className="w-5 h-5 ml-2 text-gray-200 group-hover:text-gray-500 transition-all" />
  );
};
