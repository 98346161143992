interface ToggleType {
  leftText?: String;
  rightText?: String;
  checked: Boolean;
  onToggle: () => void;
}

const Toggle = (props: ToggleType) => {
  const { leftText, rightText, checked, onToggle } = props;
  const toggleClass = "transform translate-x-7 bg-cyan-600 ";

  return (
    <div className="flex flex-row gap-1.5 items-center w-fit">
      {leftText && <p className="text-sm">{leftText}</p>}
      <div
        className="w-12 h-5 p-0.5 bg-gray-200 rounded-full cursor-pointer"
        onClick={onToggle}
      >
        <div
          className={
            "h-4 w-4 rounded-full shadow-md transform duration-300 ease-in-out" +
            (checked ? toggleClass : " bg-white")
          }
        ></div>
      </div>
      {rightText ? <p className="text-sm">{rightText}</p> : <></>}
    </div>
  );
};

export default Toggle;
