import { FormFieldType } from "@slootsantos/certain-forms/dist/types";
import { PerformanceLevelScoreFormField } from "components/Risk/PerformanceLevelScore";
import { RemainingRiskScoreFormField } from "components/Risk/RemainingRiskScoreFormField";
import { Ex } from "components/Risk/formdata";
import { RiskGroup } from "pages/Risk/hooks/useRiskGroups";
import { TFunction } from "i18next";

export const buildBackofficeRiskFormData = (
  riskGroups: RiskGroup[],
  translator: TFunction,
  selectedGroup: string
) => {
  const newForm = { ...formData };
  newForm.sections[0].fields[2].options = riskGroups.map((riskGroup, idx) => ({
    value: riskGroup.riskGroup.name,
    label: translator("form.risk.options.group." + riskGroup.riskGroup.name),
  }));

  const builtForm = handleBackofficeRiskGroupChange(
    riskGroups.find((rg) => rg.riskGroup.name === selectedGroup)!,
    translator,
    newForm
  );

  return builtForm;
};

export const handleBackofficeRiskGroupChange = (
  selectedRiskGroup: RiskGroup,
  translator: TFunction,
  _formData?: any
) => {
  const newForm = { ...(_formData || formData) };
  newForm.sections[0].fields[4].options = selectedRiskGroup?.consequences.map(
    (consequence) => ({
      value: consequence.name,
      label: translator("form.risk.options.consequence." + consequence.name),
    })
  );

  newForm.sections[0].fields[3].options = selectedRiskGroup?.origins.map(
    (origin) => ({
      value: origin.name,
      label: translator("form.risk.options.origin." + origin.name),
    })
  );

  return newForm;
};

export const formData: Ex = {
  sections: [
    {
      label: "form.risk.label",
      name: "risk",
      subline: "form.risk.subline",
      fields: [
        {
          name: "blueprint_name",
          label: "form.risk.template.blueprint_name",
          type: FormFieldType.input,
          required: true,
        },
        {
          name: "blueprint_description",
          label: "form.risk.template.blueprint_description",
          type: FormFieldType.area,
          required: true,
        },
        {
          name: "group_id",
          label: "form.risk.group",
          type: FormFieldType.select,
          options: [{ value: "mechanical", label: "Mechanische Gefährdung" }],
        },
        {
          name: "origin_id",
          label: "form.risk.origin",
          type: FormFieldType.select,
          options: [],
        },
        {
          name: "consequence_id",
          label: "form.risk.consequence",
          type: FormFieldType.multiSelect,
          options: [],
        },
        {
          name: "phase_id",
          label: "form.risk.phase",
          type: FormFieldType.multiSelect,
          // TODO: use same magic as for risk-group consequence & origins
          options: [
            { value: "transport", label: "Transport" },
            {
              value: "installation",
              label: "Montage und Installation",
            },
            {
              value: "commissioning",
              label: "Inbetriebnahme",
            },
            {
              value: "setup",
              label: "Einrichten (Anlernen/Programmieren)",
            },
            {
              value: "adapt",
              label: "Rüsten/Umrüsten",
            },
            { value: "operations", label: "Betrieb" },
            { value: "disturbance", label: "Störung" },
            { value: "debugging", label: "Fehlersuche und Beseitigung" },
            { value: "maintenance", label: "Instandhaltung" },
            { value: "cleaning", label: "Reinigung" },
            { value: "decommissioning", label: "Außerbetriebnahme" },
            { value: "deinstallation", label: "Demontage" },
            { value: "disposal", label: "Entsorgung" },
          ],
        },
        {
          name: "description",
          label: "form.risk.description",
          type: FormFieldType.area,
        },
        {
          name: "protection_goal",
          label: "form.risk.protection_goal",
          type: FormFieldType.long,
        },
        {
          name: "personal",
          label: "form.risk.personal",
          type: FormFieldType.long,
        },
        {
          name: "impact",
          label: "form.risk.impact",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Geringfügig" },
            { value: 2, label: "(2) Mittelmäßig" },
            { value: 3, label: "(3) Schwerwiegend" },
            { value: 4, label: "(4) Katastrophal" },
          ],
        },
        {
          name: "people_count",
          label: "form.risk.people_count",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Eine Person" },
            { value: 2, label: "(2) Mehrere Personen" },
            { value: 3, label: "(3) Öffentlichkeit" },
          ],
        },
        {
          name: "duration",
          label: "form.risk.duration",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Seltener als einmal jährlich" },
            { value: 2, label: "(2) Jährlich bis 14-täglich" },
            { value: 3, label: "(3) 14-täglich bis täglich" },
            { value: 4, label: "(4) Täglich bis mehrmals die Stunde" },
          ],
        },
        {
          name: "detectability",
          label: "form.risk.detectability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Wahrscheinlich" },
            { value: 2, label: "(2) Möglich" },
            { value: 3, label: "(3) Unwahrscheinlich" },
          ],
        },
        {
          name: "probability",
          label: "form.risk.probability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Vernachlässigbar" },
            { value: 2, label: "(2) Gering" },
            { value: 3, label: "(3) Möglich" },
            { value: 4, label: "(4) Groß" },
            { value: 5, label: "(5) Sehr groß" },
          ],
        },
        {
          name: "cxccc",
          label: "ccccc",
          type: FormFieldType.informational,
          customRenderOverride: (props: any) => (
            <RemainingRiskScoreFormField isRisk {...props} />
          ),
        },
      ],
    },
    {
      label: "form.mitigations.label",
      name: "mitigations",
      subline: "form.mitigations.subline",
      isList: true,
      count: 1,
      fields: [
        {
          name: "type",
          label: "form.mitigations.type",
          type: FormFieldType.select,
          options: [
            { value: "INH", label: "Inhärent sichere Konstruktion (INH)" },
            {
              value: "EMS",
              label: "Ergänzende mechanische Schutzmaßnahme (EMS)",
            },
            {
              value: "ESS",
              label: "Ergänzende steuerungstechnische Schutzmaßnahme (ESS)",
            },
            { value: "PSA", label: "Persönliche Schutzausrüstung (PSA)" },
            { value: "PIK", label: "Piktogramme/Kennzeichnung (PIK)" },
            { value: "BDA", label: "Bedienungsanleitung/Unterweisung (BDA)" },
          ],
        },
        {
          name: "cxccc",
          label: "form.mitigations.ccccc",
          type: FormFieldType.informational,
          customRenderOverride: (props: any) => (
            <PerformanceLevelScoreFormField {...props} />
          ),
        },
        {
          name: "description",
          label: "form.mitigations.description",
          type: FormFieldType.area,
        },
        {
          name: "pictogram",
          label: "form.mitigations.pictogram",
          type: FormFieldType.picto,
        },
        {
          name: "impact",
          label: "form.mitigations.impact",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Geringfügig" },
            { value: 2, label: "(2) Mittelmäßig" },
            { value: 3, label: "(3) Schwerwiegend" },
            { value: 4, label: "(4) Katastrophal" },
          ],
        },
        {
          name: "people_count",
          label: "form.mitigations.people_count",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Eine Person" },
            { value: 2, label: "(2) Mehrere Personen" },
            { value: 3, label: "(3) Öffentlichkeit" },
          ],
        },
        {
          name: "duration",
          label: "form.mitigations.duration",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Seltener als einmal jährlich" },
            { value: 2, label: "(2) Jährlich bis 14-täglich" },
            { value: 3, label: "(3) 14-täglich bis täglich" },
            { value: 4, label: "(4) Täglich bis mehrmals die Stunde" },
          ],
        },
        {
          name: "detectability",
          label: "form.mitigations.detectability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Wahrscheinlich" },
            { value: 2, label: "(2) Möglich" },
            { value: 3, label: "(3) Unwahrscheinlich" },
          ],
        },
        {
          name: "probability",
          label: "form.mitigations.probability",
          type: FormFieldType.select,
          transform: "int",
          options: [
            { value: 1, label: "(1) Vernachlässigbar" },
            { value: 2, label: "(2) Gering" },
            { value: 3, label: "(3) Möglich" },
            { value: 4, label: "(4) Groß" },
            { value: 5, label: "(5) Sehr groß" },
          ],
        },
        {
          name: "left_risk",
          label: "form.mitigations.left_risk",
          type: FormFieldType.area,
        },
        {
          name: "applied_standards",
          label: "form.mitigations.applied_standards",
          type: FormFieldType.standardsSelect,
        },
        {
          name: "any",
          label: "form.mitigations.any",
          type: FormFieldType.informational,
          customRenderOverride: (props: any) => (
            <RemainingRiskScoreFormField {...props} />
          ),
        },
      ],
    },
  ],
};
