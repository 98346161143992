import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, useFormikContext } from "formik";
import deepEqual from "deep-equal";

import { FormProps } from "./types";
import { FormBody } from "components/form/FormBody";
import { useInitialValues } from "./hooks/useFormData";
import { scrollToTop } from "utils/dom";
import { setUnsavedChanges } from "utils/localStorage";

interface StepperProps {
  steps: {
    id: string;
    name: string;
    complete: boolean;
    current: boolean;
    onClick: () => void;
  }[];
  disabled?: boolean;
}

const Stepper = (props: StepperProps) => {
  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {props.steps.map((step) => {
          const disabled = props.disabled && !step.complete;

          return (
            <li
              key={step.name}
              className={`md:flex-1 ${disabled ? "" : "cursor-pointer"}`}
              onClick={() => !disabled && step.onClick()}
            >
              {step.complete ? (
                <a
                  className={`group pl-4 py-2 flex flex-col border-l-4 border-cyan-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 ${
                    disabled ? "" : "cursor-pointer hover:border-cyan-800"
                  }`}
                >
                  <span
                    className={`text-xs text-cyan-600 font-semibold tracking-wide uppercase ${
                      disabled ? "" : "cursor-pointer group-hover:text-cyan-800"
                    }`}
                  >
                    {step.id}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </a>
              ) : step.current ? (
                <a
                  className={`pl-4 py-2 flex flex-col border-l-4 border-cyan-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 ${
                    disabled ? "" : "cursor-pointer"
                  }`}
                  aria-current="step"
                >
                  <span className="text-xs text-cyan-600 font-semibold tracking-wide uppercase cursor-pointer">
                    {step.id}
                  </span>
                  <span className="text-sm font-medium cursor-pointer">
                    {step.name}
                  </span>
                </a>
              ) : (
                <a
                  className={`group pl-4 py-2 flex flex-col border-l-4 border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 ${
                    disabled ? "" : "cursor-pointer hover:border-gray-400"
                  }`}
                >
                  <span
                    className={`text-xs text-gray-500 font-semibold tracking-wide uppercase ${
                      disabled ? "" : "cursor-pointer group-hover:text-gray-700"
                    }`}
                  >
                    {step.id}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </a>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

const useRouteStep = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const urlQuery = new URLSearchParams(search);
    const stepQuery = urlQuery.get("step");

    return stepQuery ? parseInt(stepQuery) : 0;
  }, [search]);
};

const Form = (props: FormProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { formData, context } = props;
  const { setFieldValue, handleSubmit, values } = useFormikContext();

  const routeStep = useRouteStep();

  const [prevValues, setPrevValues] = useState(values);
  const hasDiff = !deepEqual(prevValues, values);

  useEffect(() => {
    if (!hasDiff || !props.onAutosave) {
      return;
    }

    setUnsavedChanges(history.location, true);

    const timeout = setTimeout(() => {
      props.onAutosave!(values!);
      setPrevValues(values);
      setUnsavedChanges(history.location, false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [values, props.onAutosave, hasDiff, history.location]);

  const [form, setForm] = React.useState(formData);
  const [isFinalStep, setFinalStep] = React.useState(false);
  const [currentSectionIdx, setCurrentSectionIdx] = React.useState(
    routeStep || 0
  );

  React.useEffect(() => {
    if (
      !props.persistStep ||
      history.location.search.match(`step=${currentSectionIdx}`)
    ) {
      return;
    }

    const newSearch = {
      search: "?step=" + currentSectionIdx,
    };

    currentSectionIdx === 0
      ? history.replace(newSearch)
      : history.push(newSearch);
  }, [currentSectionIdx, history, props.persistStep]);

  React.useEffect(() => {
    if (routeStep !== undefined && routeStep !== null) {
      setCurrentSectionIdx(routeStep);
    }
  }, [routeStep]);

  React.useEffect(() => {
    setForm(formData);
    setFinalStep(currentSectionIdx === formData.sections.length - 1);
  }, [formData, values, currentSectionIdx]);

  const changeValue =
    (fieldname: string, transform?: "boolean" | "int") =>
    (e: React.ChangeEvent<any>) => {
      let value = e.target.value;

      if (transform === "boolean") {
        value = Boolean(value);
      }

      if (transform === "int") {
        value = parseInt(value);
      }

      setFieldValue(fieldname, value);

      if (props.onChange) {
        props.onChange(values as any);
      }
    };

  return (
    <>
      {form?.sections?.length > 1 && (
        <div className="pb-20">
          <Stepper
            disabled={props.disableStepper}
            steps={form.sections.map((section, idx) => ({
              id: `${t("form.progress.step")} ${idx + 1}`,
              name: t(section.label),
              complete: currentSectionIdx > idx,
              current: currentSectionIdx === idx,
              onClick: () => setCurrentSectionIdx(idx),
            }))}
          />
        </div>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (props.onStep) {
            const preventStep = props.onStep(
              values as any,
              currentSectionIdx,
              setFieldValue
            );

            if (preventStep) {
              return;
            }
          }

          if (!isFinalStep) {
            scrollToTop();
            setCurrentSectionIdx(currentSectionIdx + 1);

            return;
          }

          handleSubmit(e);
        }}
      >
        {form?.sections?.map(
          (section, idx) =>
            currentSectionIdx === idx && (
              <FormBody
                key={idx}
                context={context}
                section={section}
                onAdd={props.onAdd}
                onChange={changeValue}
                confirmationLabel={props.confirmationLabel}
                confirmationSubline={props.confirmationSubline}
              />
            )
        )}

        <div className="py-9 text-right">
          <a
            data-test="abort-form"
            className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
            onClick={history.goBack}
          >
            {t("form.cta.cancel")}
          </a>
          {props.additionalCta && props.additionalCta(values as any as any)}

          <button
            type="submit"
            data-test="submit-form"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            {isFinalStep
              ? props.submitLabel ||
                (!props.saveText ? t("form.project.save") : t(props.saveText))
              : t("form.cta.next")}
          </button>
        </div>
      </form>
    </>
  );
};

const Wrapped = (props: FormProps) => {
  const initialValues = useInitialValues(props);

  if (!initialValues || props.blocking) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit}>
      <Form {...props} />
    </Formik>
  );
};

export default Wrapped;
