import { useQuery } from "react-query";

import { useClient } from "utils/client";
import { Manufacturer } from "types";
import { ImageUploads } from "pages/Project/hooks/useCreateProject";
import { AxiosInstance } from "axios";

export type ManufacturerWithUploads = Manufacturer & { _uploads: ImageUploads };

const fetchOneManufacturers = async (
  client: AxiosInstance,
  manufacturerId: string
): Promise<ManufacturerWithUploads> => {
  const { data } = await client.get(`/manufacturers/${manufacturerId}`);

  // @ts-ignore
  const _uploads = data?.documents?.reduce((agg, doc) => {
    agg[doc.type] = {
      image: new File([], doc.name),
      location: doc.location,
    };
    return agg;
  }, {} as Record<string, { image: File; location?: string }>);

  return { ...data, _uploads };
};

export const useOneManufacturer = (manufacturerId: string) => {
  const client = useClient();

  const { data: manufacturer, isLoading: loadingQuery } = useQuery(
    ["manufacturers", manufacturerId],
    () => fetchOneManufacturers(client, manufacturerId)
  );

  return {
    manufacturer,
    fetchInProgress: loadingQuery,
  };
};
