import { useTranslation } from "react-i18next";
import { usePasswordless } from "amazon-cognito-passwordless-auth/react";

import { getRelativeTime } from "utils/date";
import { PrimaryButton } from "components/Button/PrimaryButton";

export const PasskeyTab = () => {
  const { t } = useTranslation();
  const { fido2Credentials, toggleShowAuthenticatorManager } =
    usePasswordless();

  return (
    <div className="">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("form.passkey.label")}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {t("form.passkey.subline")}
        </p>
      </div>
      <dl className="">
        {!fido2Credentials?.length && t("authdevices.no_devices")}
        {fido2Credentials &&
          fido2Credentials?.map((cred) => {
            return (
              <div
                key={cred.credentialId}
                className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3"
              >
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {cred.friendlyName}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t("authdevices.created_at")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {cred.createdAt.toLocaleDateString()}
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {t("authdevices.last_login")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {cred.lastSignIn
                      ? getRelativeTime(cred.lastSignIn.getTime())
                      : t("authdevices.never_used")}
                  </dd>
                </div>
              </div>
            );
          })}
      </dl>
      <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-">
        <div className="flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-2">
          <PrimaryButton onClick={() => toggleShowAuthenticatorManager()}>
            {t("form.passkey.edit")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
