import React from "react";
import {
  Certification,
  FormFieldType,
  Form as OutSideForm,
} from "@slootsantos/certain-forms/dist/types";

import Form from "forms/Form";
import { User, useUser } from "./hooks/useUser";
import { useUpdateUser } from "./hooks/useUpdateUser";
import { BasePage } from "components/BasePage/BasePage";
import { useTranslation } from "react-i18next";

const F: OutSideForm = {
  type: Certification.ce,
  documents: [],
  directives: [],
  sections: [
    {
      name: "user",
      label: "form.user.label",
      subline: "form.user.subline",
      fields: [
        {
          name: "company",
          label: "form.engineer.company",
          type: FormFieldType.full,
        },
        {
          name: "name",
          label: "form.engineer.name",
          type: FormFieldType.input,
        },
        {
          name: "email_adress",
          label: "form.engineer.email_adress",
          type: FormFieldType.input,
        },
        {
          name: "street_address",
          label: "form.engineer.street_address",
          type: FormFieldType.input,
        },
        {
          name: "post_code",
          label: "form.engineer.post_code",
          type: FormFieldType.input,
        },
        {
          name: "city",
          label: "form.engineer.city",
          type: FormFieldType.input,
        },
        {
          name: "country",
          label: "form.engineer.country",
          type: FormFieldType.countrySelect,
        },
        {
          name: "state",
          label: "form.engineer.state",
          type: FormFieldType.input,
        },
        {
          name: "phone",
          label: "form.engineer.phone",
          type: FormFieldType.input,
        },
      ],
    },
  ],
};

export const UserEdit = () => {
  const { t } = useTranslation();
  const { user, isLoading } = useUser();
  const { updateUser } = useUpdateUser();

  return (
    <BasePage
      loading={isLoading}
      breadcrumbItems={[
        { label: "sidebar.nav.settings", location: "/dashboard" },
        { label: "form.user.label", location: "/settings" },
        { label: "dashboard.table.edit", location: "/settings/edit" },
      ]}
    >
      <h1 className="text-3xl pb-10">{t("form.user.edit")}</h1>
      <Form
        submitLabel={t("form.user.save")}
        onSubmit={async (v) => updateUser((v as any).user as User)}
        formData={F}
        defaultValues={{ user: user }}
      />
    </BasePage>
  );
};
