import React from "react";
import { TailSpin as Loader } from "react-loader-spinner";

export const Loading = () => {
  return (
    <div>
      <Loader color="#0991b1" height={100} width={100} />
    </div>
  );
};
