import React from "react";
import { useTranslation, TFunction } from "react-i18next";
import { SelectInputProps } from "./types";
import Collapsible from "components/Collapsible/Collapsible";

const RadioOption = (
  props: SelectInputProps & {
    value: string | boolean | number;
    checked: boolean;
    t: TFunction<string>;
  }
) => {
  const { defaultValue, groupName, t, collapsibleItems, ...restProps } = props;

  return (
    <div className="flex items-center">
      <input
        {...restProps}
        type="radio"
        name={props.id}
        onChange={props.onBlur}
        checked={props.checked}
        id={props.id + props.label}
        value={props.value.toString()}
        className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
      />
      <label
        htmlFor={props.id + props.label}
        className="ml-3 block text-sm font-medium text-gray-700"
      >
        {t(props.label)}
      </label>
    </div>
  );
};

const RadioInput: React.FunctionComponent<
  SelectInputProps & {
    islast?: boolean;
    defaultValue?: string | boolean | number | readonly string[];
  }
> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={`col-span-12 pb-2 ${props.islast ? "" : "border-b"}`}>
      <div className="flex justify-between">
        <label className="block text-sm font-medium text-gray-700 mb-4 w-full">
          {props.label}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>

      {props.options?.map((option) => (
        <RadioOption
          key={option.label}
          t={t}
          {...props}
          {...option}
          checked={option.value.toString() === props.defaultValue}
        />
      ))}
      <p className="mt-2 text-sm text-gray-500">{props.info}</p>

      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default RadioInput;
