import React from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";

import { SelectInputProps } from "./types";
import { classNames } from "utils/classNames";
import Collapsible from "components/Collapsible/Collapsible";

const styles = {
  control: (css: any) => ({
    ...css,
    borderColor: "none",
    borderWidth: "none",
    borderRadius: "none",
  }),
  option: (css: any) => ({
    ...css,
    backgroundColor: "none",
    textColor: "none",
  }),
  input: (css: any) => ({
    ...css,
    input: {
      outline: "none",
    },
    "*:focus": {
      boxShadow: "none",
    },
  }),
  multiValueLabel: (css: any) => ({
    ...css,
    color: "white",
    borderRadius: "3px",
  }),
  multiValue: (css: any) => ({
    ...css,
    color: "white",
    borderRadius: "3px",
    backgroundColor: "#0d91b1",
  }),
  multiValueRemove: (css: any) => ({
    ...css,
    border: "1px solid #0d91b1",
    ":hover": {
      color: "#0d91b1",
      backgroundColor: "white",
    },
  }),
};

const MultiSelectInput: React.FunctionComponent<SelectInputProps> = (props) => {
  const { options } = props;
  const { t } = useTranslation();

  const defaultVal = React.useMemo(() => {
    if (
      (typeof props.defaultValue === "string" ||
        props.defaultValue instanceof String) &&
      props.defaultValue !== ""
    ) {
      return (props.defaultValue as string)
        ?.split(";")
        .map((value: string) => ({
          value,
          label: options?.find((o) => o.value === value)?.label || value,
        }));
    }

    return props.defaultValue as any;
  }, [props.defaultValue, options]);

  return (
    <div className="col-span-6">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>
      <Creatable
        isMulti
        placeholder={t(`multiSelect.${props.name}.label`)}
        required={props.required}
        defaultValue={defaultVal}
        onChange={(value) => {
          props.onBlur &&
            props.onBlur({
              //@ts-ignore
              target: { value: value.map((v) => v.value).join(";") },
            });
        }}
        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 sm:text-sm"
        styles={styles}
        classNames={{
          control: ({ isFocused }) =>
            `border border-gray-300 rounded-md ${
              isFocused ? "border-cyan-500" : ""
            }`,
          option: ({ isFocused, isSelected }) =>
            classNames(
              isFocused ? "bg-cyan-500" : "",
              isSelected ? "bg-cyan-400" : ""
            ),
        }}
        options={options?.map((option) => ({
          value: option.value,
          label: option.label,
        }))}
        noOptionsMessage={() => t(`multiSelect.${props.name}.emptyPlaceholder`)}
        formatCreateLabel={(name: string) =>
          t(`multiSelect.${props.name}.createLabel`, { name })
        }
        menuPosition="fixed"
      />
      {props.info && (
        <p className="mt-2 text-sm text-gray-500">{t(props.info)}</p>
      )}

      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default MultiSelectInput;
