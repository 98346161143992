import React from "react";
import { CEForm } from "@slootsantos/certain-forms";

import Form from "forms/Form";
import { useUser } from "pages/Settings/hooks/useUser";
import { BasePage } from "components/BasePage/BasePage";
import { useCreateProject } from "./hooks/useCreateProject";

export const Create = () => {
  const formData = CEForm;
  const { user, isLoading } = useUser();
  const { createProject } = useCreateProject();

  return (
    <BasePage
      loading={isLoading}
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        { label: "Create new", location: "" },
      ]}
    >
      <Form
        formData={formData}
        defaultValues={{
          engineer: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email_adress: user?.email_adress,
            country: user?.country,
            street_address: user?.street_address,
            city: user?.city,
            state: user?.state,
            post_code: user?.post_code,
            phone: user?.phone,
          },
          projectdata: {
            launch_date: new Date().toISOString(),
          },
        }}
        onSubmit={(values: any) => {
          const { _uploads, ...projectPayload } = values;
          createProject({ project: projectPayload, uploads: _uploads });
        }}
      />
    </BasePage>
  );
};
