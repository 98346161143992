import React, { PropsWithChildren } from "react";
import { Notification } from "components/common/Notification";

interface IControlContext {
  displayNotification: (
    title: string,
    message: string,
    type?: "success" | "error",
    clear?: boolean
  ) => void;
}

export const ControlContext = React.createContext<Partial<IControlContext>>({});

export const ControlProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState<{
    title: string;
    message: string;
    type: "success" | "error";
    clear: boolean;
  }>({
    message: "",
    title: "",
    type: "success",
    clear: true,
  });

  const displayNotification = (
    title: string,
    message: string,
    type: "success" | "error" = "success",
    clear: boolean = true
  ) => {
    setShowModal(true);
    setModalData({ message, title, type, clear });

    if (clear) {
      setTimeout(() => setShowModal(false), 5000);
    }
  };

  return (
    <ControlContext.Provider value={{ displayNotification }}>
      {children}

      {showModal && (
        <Notification
          title={modalData.title}
          message={modalData.message}
          type={modalData.type}
          onClose={() => setShowModal(false)}
        />
      )}
    </ControlContext.Provider>
  );
};
