import { useUser } from "./hooks/useUser";
import { BasePage } from "components/BasePage/BasePage";
import { useTranslation } from "react-i18next";
import { SettingTabs } from "./SettingsTab";

export const UserSettings = () => {
  const { t } = useTranslation();
  const { isLoading } = useUser();

  return (
    <BasePage
      loading={isLoading}
      breadcrumbItems={[
        { label: "sidebar.nav.settings", location: "/settings" },
        {
          label: "settings.tab.user",
          location: "/settings",
        },
      ]}
    >
      <div className="">
        <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-">
          <div className="2xl:block min-w-0 flex-1">
            <span className="text-3xl pr-2">{t("sidebar.nav.settings")}</span>
          </div>
        </div>
        <div>
          <SettingTabs />
        </div>
      </div>
    </BasePage>
  );
};
