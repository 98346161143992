import { Risk, RiskAssessment } from "./types";

export const calculateScore = (assessment: RiskAssessment) => {
  const { impact, people_count, duration, probability, detectability } =
    assessment;
  // Risiko (Rv) = S x N x (F + W + P)
  // Risiko (Rv) = impact x people_count x (duration + probabability + detectability)
  return impact * people_count * (duration + probability + detectability);
};

export const calculateScoreAfterMitigation = (
  risk: Risk,
  includeIdx: number
) => {
  return calculateScore(risk.mitigations[includeIdx!]);
};

export const calculateCompoundRisk = (risks: Risk[]) => {
  const compoundRisk = risks.reduce(
    (acc, risk) =>
      (acc += calculateScore(
        (risk.mitigations?.length &&
          risk.mitigations[risk.mitigations.length - 1]) ||
          risk.assessment
      )),
    0
  );

  return compoundRisk;
};

// Performance level calculation
// Schadensausmaß (S):
// impact < 3 => S1
// impact > 2 => S2

// Aufenthaltsdauer (F):
// duration < 3 => F1
// duration > 2 => F2

// Möglichkeit zum Erkennen und Ausweichen (P):
// detectability < 3 => P1
// detectability > 2 => P2

enum PerformanceLevel {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
}

type PerformanceLevelLookupFn = (
  impactLevel: number,
  durationLevel: number,
  detectabilityLevel: number
) => boolean;

const isTop = (level: number) => level < 3;

const performanceLevelMap: Record<PerformanceLevel, PerformanceLevelLookupFn> =
  {
    // S1 => F1 => P1 => A
    [PerformanceLevel.A]: (iL, dL, detL) =>
      isTop(iL) && isTop(dL) && isTop(detL),

    [PerformanceLevel.B]: (iL, dL, detL) =>
      // S1 => F1 => P2 => B
      (isTop(iL) && isTop(dL)) ||
      // S1 => F2 => P1 => B
      (isTop(iL) && !isTop(dL) && isTop(detL)),

    [PerformanceLevel.C]: (iL, dL, detL) =>
      // S1 => F2 => P2 => C
      (isTop(iL) && !isTop(dL) && !isTop(detL)) ||
      // S2 => F1 => P1 => C
      (!isTop(iL) && isTop(dL) && isTop(detL)),

    [PerformanceLevel.D]: (iL, dL, detL) =>
      // S2 => F1 => P2 => D
      (!isTop(iL) && isTop(dL) && !isTop(detL)) ||
      // S2 => F2 => P1 => D
      (!isTop(iL) && !isTop(dL) && isTop(detL)),

    [PerformanceLevel.E]: (iL, dL, detL) =>
      // S2 => F2 => P2 => E
      !isTop(iL) && !isTop(dL) && !isTop(detL),
  };

export const calculatePerformanceLevel = (risk: Risk) => {
  const matchingPerformanceLevel = Object.keys(performanceLevelMap).find(
    (level) => {
      const performanceLevel = level as PerformanceLevel;
      const performanceLevelEvaluator = performanceLevelMap[performanceLevel];

      return performanceLevelEvaluator(
        risk.impact,
        risk.duration,
        risk.detectability
      );
    }
  );

  return matchingPerformanceLevel as PerformanceLevel;
};
