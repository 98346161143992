import React from "react";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";
import { DebounceInput } from "react-debounce-input";
import { log } from "console";

const DummySearch = (props: {
  onChange: (a: any) => void;
  placeholder: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-3 sm:mt-0 mb-4">
      <label htmlFor="search_candidate" className="sr-only">
        Search
      </label>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <DebounceInput
            minLength={3}
            type="text"
            name="search_candidate"
            id="search_candidate"
            className="focus:ring-cyan-500 focus:border-cyan-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
            placeholder={props.placeholder}
            onChange={props.onChange}
            debounceTimeout={300}
          />
        </div>
      </div>
    </div>
  );
};

interface SearchableItem {
  name: string;
  content: string;
}

interface StandardSearchProps<T extends SearchableItem, TranslatedT = any> {
  values: T[];
  placeholder: string;
  translatedValues?: TranslatedT[];
  onSelect?: (s: T) => void;
}

export const ListSearch = <T extends SearchableItem>(
  props: StandardSearchProps<T>
) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [selection, setSelection] = React.useState<T[]>([]);

  const searchmatches = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.toLowerCase();
    const regexVal = val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    let sel = props.translatedValues!.filter((set: any) => {
      return (
        set.content?.toLowerCase().match(regexVal) ||
        set.name.toLowerCase().match(regexVal)
      );
    });

    if (val === "") {
      sel = [];
    }

    setSelection(sel);
    setValue(val);
  };

  const handleStandardClick = (standardId: string) => {
    const selectedStandard = props.values!.find((s) => s.name === standardId);

    if (props.onSelect && selectedStandard) {
      props.onSelect(selectedStandard);
    }
  };

  return (
    <div className="py-6">
      <DummySearch onChange={searchmatches} placeholder={props.placeholder} />
      <div className="rounded-md border border-cyan-600">
        <span className="m-4">
          {selection.length || t("standards.search.noResults")}{" "}
          {t("standards.search.results")}
        </span>
        <ul className="divide-y divide-gray-200 max-h-56 overflow-y-scroll">
          {selection.length > 0 &&
            selection.map((el: any) => (
              <div key={el.name} onClick={() => handleStandardClick(el.name)}>
                <li className="py-4 flex cursor-pointer hover:bg-cyan-300">
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <Highlighter
                        highlightClassName="bg-cyan-600 text-white px-1"
                        searchWords={[value]}
                        autoEscape={true}
                        textToHighlight={el.name}
                      />
                    </p>
                    <p className="text-sm text-gray-500">
                      <Highlighter
                        highlightClassName="bg-cyan-600 text-white px-1"
                        searchWords={[value]}
                        autoEscape={true}
                        textToHighlight={el.content}
                      />
                    </p>
                  </div>
                </li>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
};
