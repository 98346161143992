import { DocType } from "../types";
import { Category } from "components/common/ProductCategorySelector";

const baseDocs = [
  DocType.manual,
  DocType.risk,
  DocType.conformity,
  DocType.plate,
];

export const requiredDocsMapping: Record<Category, DocType[]> = {
  [Category.machine]: baseDocs,
  [Category.sample]: [DocType.risk],
  [Category.partially_completed_machinery]: [
    DocType.manual,
    DocType.risk,
    DocType.installationConformity,
    DocType.plate,
  ],
  [Category.assembly_of_machinery]: baseDocs,
  [Category.safety_Component]: baseDocs,
  [Category.lifting_accessory]: baseDocs,
  [Category.chains_ropes_webbing]: baseDocs,
  [Category.removable_mechanical_transmission_device]: baseDocs,
  [Category.lifting_device_human_power]: baseDocs,
  [Category.interchangeable_equipment]: baseDocs,
  [Category.annex_iv]: baseDocs,
  [Category.exit]: [DocType.exitConformity],
  [Category.none]: [],
  // non MRL categories
  [Category.lvd]: baseDocs,
};
