import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";

import { SelectInputProps } from "./types";
import { classNames } from "utils/classNames";
import { useManufacturers } from "components/Manufacturers/hooks/useManufacturers";
import Collapsible from "components/Collapsible/Collapsible";

const styles = {
  control: (css: any) => ({
    ...css,
    borderColor: "none",
    borderWidth: "none",
    borderRadius: "none",
  }),
  option: (css: any) => ({
    ...css,
    backgroundColor: "none",
    textColor: "none",
  }),
  input: (css: any) => ({
    ...css,
    input: {
      outline: "none",
    },
    "*:focus": {
      boxShadow: "none",
    },
  }),
};

const ManufacturerIsBluePrintInput = () => {
  const { setFieldValue, values } = useFormikContext();
  const defaultIsBluePrint =
    //@ts-ignore
    values && values["manufacturer"] && values["manufacturer"]["is_blueprint"];

  const [isBluePrint, setIsBluePrint] = React.useState(defaultIsBluePrint);

  const handleChange = () => {
    setIsBluePrint(!isBluePrint);
    setFieldValue("manufacturer.is_blueprint", !isBluePrint);
  };

  return (
    <>
      <input
        data-test="manufacturer-is-blueprint"
        type="checkbox"
        checked={isBluePrint}
        onChange={handleChange}
        className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
      />
      <label className="text-sm"> Hersteller als Vorlage speichern</label>
    </>
  );
};

async function createFile(s3Link: string, fileName: string) {
  const response = await fetch(s3Link);
  const data = await response.blob();
  const metadata = {
    type: "image/" + fileName.split(".").slice(-1),
  };

  return new File([data], fileName, metadata);
}

const ManufacturerSelectInput: React.FunctionComponent<SelectInputProps> = (
  props
) => {
  const { t } = useTranslation();
  const { manufacturers } = useManufacturers();
  const { setFieldValue, values } = useFormikContext();

  const defaultShowBluePrint =
    //@ts-ignore
    values && values["_meta"] && values["_meta"]["newManufacturer"];

  const [showBluePrint, setShowBluePrint] =
    React.useState(defaultShowBluePrint);

  const handleManufacturerSelect = async (
    selectedManufacturerCompany: string
  ) => {
    const manufacturer = manufacturers?.find(
      (m) => m.company === selectedManufacturerCompany
    )!;

    Object.keys(manufacturer)
      .filter((k) => k !== "id" && k !== "resource_id" && k !== "is_blueprint")
      .forEach((key) => {
        // @ts-ignore
        setFieldValue(`manufacturer.${key}`, manufacturer[key]);
      });

    const logo = manufacturer.documents?.find(
      // @ts-ignore
      ({ type }) => type === "manufacturer_logo"
    );

    if (logo) {
      const manufacturerLogoFile = await createFile(
        logo?.location!,
        logo?.name!
      );

      setFieldValue(`_uploads.manufacturer_logo`, {
        image: manufacturerLogoFile,
        location: logo?.location,
        touched: true,
      });
    }
  };

  const handleManufacturerCreate = () => {
    setShowBluePrint(true);
    setFieldValue("_meta.newManufacturer", true);
    setFieldValue("manufacturer.is_blueprint", true);
  };

  return (
    <div className="col-span-6" data-test="manufacturer-select-wrapper">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
      </div>

      <Creatable
        data-test="manufacturer-select"
        defaultValue={{ value: props.defaultValue, label: props.defaultValue }}
        onChange={(value, changeType) => {
          if (changeType.action === "select-option") {
            handleManufacturerSelect(value!.value!.toString());
          }

          if (changeType.action === "create-option") {
            // handleManufacturerSelect(value!.value!.toString());
            handleManufacturerCreate();
          }

          setFieldValue(`manufacturer.company`, value?.value);
        }}
        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 sm:text-sm"
        styles={styles}
        classNames={{
          control: ({ isFocused }) =>
            `border border-gray-300 rounded-md ${
              isFocused ? "border-cyan-500" : ""
            }`,
          option: ({ isFocused, isSelected }) =>
            classNames(
              isFocused ? "bg-cyan-500" : "",
              isSelected ? "bg-cyan-400" : ""
            ),
        }}
        options={manufacturers?.map((option) => ({
          value: option.company,
          label: option.company,
        }))}
        noOptionsMessage={() => "Noch keine Hersteller angelegt."}
        formatCreateLabel={(inputValue: string) =>
          t("manufacturerSelect.create", { inputValue })
        }
        menuPosition="fixed"
      />

      <p className="mt-2 text-sm text-gray-500">
        {t("manufacturerSelect.info")}
      </p>

      {showBluePrint && <ManufacturerIsBluePrintInput />}
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default ManufacturerSelectInput;
