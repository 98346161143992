import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import { SelectInputProps } from "./types";
import "react-datepicker/dist/react-datepicker.css";
import Collapsible from "components/Collapsible/Collapsible";

const DateInput = (props: SelectInputProps & { defaultValue?: any }) => {
  const { t } = useTranslation();
  const initialValue = props.defaultValue || new Date().toISOString();

  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>

      <DatePicker
        selected={new Date(initialValue)}
        dateFormat="dd.MM.yyyy"
        onChange={(date) =>
          props.onBlur &&
          //@ts-ignore
          props.onBlur({ target: { value: date!.toISOString() } })
        }
        className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
      <p className="mt-2 text-sm text-gray-500">{props.info}</p>
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default DateInput;
