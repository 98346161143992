import React from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";

interface MappingItem {
  translation: string;
  value: string;
}

interface MappingProps {
  [key: string]: MappingItem[];
}

interface SearchInputProps {
  values: any[];
  placeholder: string;
  fields?: string[];
  mapping?: MappingProps;
  noMatchTranslation: string;
  onChange: (filteredValues: any[]) => void;
}

export const SearchInput = (props: SearchInputProps) => {
  const [noMatch, setNoMatch] = React.useState(false);
  const { t } = useTranslation();

  const searchmatches = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoMatch(false);
    const val = e.target.value.toLowerCase();
    let sel: any[] = [];

    if (val.length === 0) {
      props.onChange(props.values);
      return;
    }

    const keys = props.fields || Object.keys(props.values[0]);

    sel = props.values.filter((item) => {
      return keys.some((key: string) => {
        if (props.mapping && props.mapping[key]) {
          return props.mapping[key].some((mappingItem) => {
            const itemValue = findNestedValue(item, key);
            return (
              itemValue
                .toLowerCase()
                .includes(mappingItem.value.toLowerCase()) &&
              mappingItem.translation.toLowerCase().includes(val)
            );
          });
        }

        return findNestedValue(item, key).toLowerCase().includes(val);
      });
    });

    if (sel.length === 0) {
      setNoMatch(true);
    }

    props.onChange(sel);
  };

  const findNestedValue = (obj: any, keyPath: string) => {
    return (
      keyPath.split(".").reduce((acc, part) => acc && acc[part], obj) || ""
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex rounded-md shadow-sm max-w-md">
        <div className="relative flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <DebounceInput
            minLength={3}
            type="text"
            name="search_candidate"
            id="search_candidate"
            className="focus:ring-cyan-500 focus:border-cyan-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
            placeholder={t(props.placeholder)}
            onChange={searchmatches}
            debounceTimeout={300}
          />
        </div>
      </div>
      <div>
        {noMatch && (
          <p className="ml-2 pb-2 text-sm text-gray-600">
            {t("common.no_match", {
              entity: t(props.noMatchTranslation),
            })}
          </p>
        )}
      </div>
    </div>
  );
};
