import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "components/Button/DeleteButton";
import { PencilIcon, ArchiveIcon, RefreshIcon } from "@heroicons/react/solid";
import { Project } from "types";
import { Status } from "types";
import { useDeleteProject, useUpdateProject } from "hooks/useProjects";
import { useConfirmation } from "context/Confirmation";

interface Option {
  label: string;
  handler: () => void;
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
}

interface OptionsProps {
  project: Project;
  onClose: () => void;
}

const createListener = (handler: OptionsProps["onClose"]) => {
  window.addEventListener("click", handler, { once: true });
};

const OptionItem = (props: Option) => {
  return (
    <a
      onClick={props.handler}
      className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      role="menuitem"
    >
      <props.icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      {props.label}
    </a>
  );
};

export const Options = (props: OptionsProps) => {
  const history = useHistory();
  const { deleteProject } = useDeleteProject();
  const { updateProject } = useUpdateProject(props.project);
  const { t } = useTranslation();
  const { openConfirmation } = useConfirmation();

  React.useEffect(() => {
    setTimeout(() => createListener(props.onClose), 0);
  });

  const changeStatus = () => {
    const { project } = props;

    if (project.projectdata.status === Status.wip) {
      project.projectdata.status = Status.archived;
    } else {
      project.projectdata.status = Status.wip;
    }

    updateProject(project.projectdata.crn);
    history.push(`/Dashboard`);
  };

  const options: Option[] = [
    {
      label: t("dashboard.table.edit"),
      handler: () => history.push(`/projects/${props.project.projectdata.crn}`),
      icon: PencilIcon,
    },
    {
      label: t(
        `dashboard.table.${
          props.project.projectdata.status !== "archived"
            ? "archive"
            : "restore"
        }`
      ),
      handler: () => changeStatus(),
      icon:
        props.project.projectdata.status !== "archived"
          ? ArchiveIcon
          : RefreshIcon,
    },
  ];

  const handleDelete = () => {
    openConfirmation(() => deleteProject(props.project.projectdata.crn), {
      label: t("form.basic.label"),
      subline: t("dashboard.table.delete_confirmation_subline"),
    });
  };

  return (
    <div
      className="mx-3 cursor-pointer  origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="project-options-menu-0"
    >
      <div className="py-1" role="none">
        {options.map((o) => (
          <OptionItem key={o.label} {...o} />
        ))}
      </div>
      <div className="py-1 w-full" role="none">
        <DeleteButton onClick={handleDelete} flat fullWidth>
          {t("dashboard.table.delete")}
        </DeleteButton>
      </div>
    </div>
  );
};
