import React from "react";
import { AxiosInstance } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { boundarySections } from "@slootsantos/certain-forms/dist/forms/ce";

import Form from "forms/Form";
import { Project } from "types";
import { useClient } from "utils/client";
import { TrackingContext } from "context/Tracking";
import { FeedbackContext } from "context/Feedback";
import { BasePage } from "components/BasePage/BasePage";
import { ProjectPayload } from "./Project/hooks/useCreateProject";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";

interface EditRouteParams {
  projectId: string;
}

const updateProject = async (
  client: AxiosInstance,
  projectId: string,
  payload: ProjectPayload,
  additional_boundaries: { description: string; value: string }[]
) => {
  payload.project.boundary.additional_boundaries = additional_boundaries.filter(
    (d) => !!d.description && !!d.value
  );

  await client.put(`/projects/${projectId}`, {
    payload: payload.project,
  });
};

const useDerivedData = (projectId: string) => {
  const client = useClient();
  const { data, isLoading } = useQuery(["projects", projectId], async () => {
    const { data } = await client.get<Project>(`/projects/${projectId}`);

    return data;
  });

  const _uploads = data?.documents?.reduce((agg, doc) => {
    agg[doc.type] = { image: new File([], doc.name.split("/upload/")[1]) };
    return agg;
  }, {} as Record<string, { image: File }>);

  return { data: { ...data, _uploads }, isLoading };
};

export const EditBoundaries = () => {
  const { t } = useTranslation();
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { projectId } = useParams<EditRouteParams>();
  const { displayFeedback } = React.useContext(FeedbackContext);

  const { track } = React.useContext(TrackingContext);

  const { data, isLoading } = useDerivedData(projectId);
  const [formData, setFormData] = React.useState({
    sections: boundarySections,
  });
  const [defaultData, setDefaultData] = React.useState(data);
  const [blocking, setBlocking] = React.useState(true);

  React.useEffect(() => {
    if (!formData?.sections?.length) return;

    const copy = structuredClone(formData);
    const copyData = structuredClone(data);
    const idx = copy.sections.findIndex(
      (s) => s.name === "additional_boundaries"
    );
    copy.sections[idx].count = data.boundary?.additional_boundaries?.length;

    // @ts-ignore
    copyData["additional_boundaries"] = data.boundary?.additional_boundaries;

    if (
      copyData.boundary &&
      (!copyData!.boundary.expected_misuse ||
        copyData.boundary.expected_misuse === "")
    ) {
      copyData!.boundary!.expected_misuse = t("form.boundary.default_expected_misuse");
    }

    setFormData(copy);
    setDefaultData(copyData);
    setBlocking(
      isLoading || !Boolean(data.projectdata) || !Boolean(copy.sections?.length)
    );
  }, [data.projectdata, isLoading]);

  return (
    <BasePage
      loading={blocking}
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: data?.projectdata?.product_name! || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "form.boundary.label",
          location: `/projects/${projectId}/edit/boundaries`,
        },
      ]}
    >
      <Form
        saveText={t("form.boundary.save")}
        formData={formData}
        confirmationSubline={"form.boundary.delete_confirmation_subline"}
        defaultValues={defaultData}
        onSubmit={async (values: any) => {
          const { _uploads, additional_boundaries, ...project } = values;

          await updateProject(
            client,
            projectId,
            { project, uploads: _uploads },
            additional_boundaries
          );

          queryClient.invalidateQueries(["projects", projectId]);

          track("update boundaries");
          displayFeedback!(FeedbackLocation.Boundary);
          history.push("/projects/" + projectId + "?saved=true");
        }}
      />
    </BasePage>
  );
};
