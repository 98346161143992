import React, { PropsWithChildren } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { classNames } from "utils/classNames";
import { DeleteButtonProps } from "types";

export const DeleteButton: React.FunctionComponent<
  PropsWithChildren<DeleteButtonProps>
> = (props) => {
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    props.onClick();
  };

  const buttonNormal =
    "text-xs border border-transparent shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded";
  const buttonFlat = classNames(
    "text-sm text-red-600 hover:bg-gray-100",
    props.fullWidth ? "w-full" : ""
  );

  const iconNormal = "w-4 h-4 mr-1";
  const iconFlat = classNames(
    props.small ? "h-4 w-4" : "h-5 w-5",
    props.children ? "mr-3" : ""
  );

  return (
    <button
      type="button"
      onClick={clickHandler}
      disabled={props.disabled}
      className={classNames(
        "disabled:opacity-50 inline-flex  font-medium ",
        props.flat ? buttonFlat : buttonNormal,
        props.small ? "py-px px-px" : "py-2 px-4"
      )}
    >
      <TrashIcon className={props.flat ? iconFlat : iconNormal} />
      {props.children}
    </button>
  );
};
