import React from "react";
import { useLocation } from "react-router-dom";

interface NavItemProps {
  path: string;
  content: string;
  onClick?: () => void;
  highlighted?: boolean | false;
}

const classNotActive =
  "p-2 lg:px-4 md:mx-2 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300";
const classActive = "bg-cyan-600 p-2 lg:px-4 md:mx-2 rounded text-gray-50";

const NavButton = (props: NavItemProps) => {
  const { path, content, onClick, highlighted } = props;
  const { pathname } = useLocation();

  const isActive = highlighted || pathname === path;

  return (
    <button
      className={isActive ? classActive : classNotActive}
      style={{ border: "none", outline: "none" }}
      onClick={onClick}
    >
      {content}
    </button>
  );
};

export default NavButton;
