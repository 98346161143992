import React from "react";
import { FormFieldType } from "@slootsantos/certain-forms/dist/types";

import { FormProps, FormInitalValues } from "../types";

export const useInitialValues = (props: FormProps) => {
  const [initialValues, setInitialValues] =
    React.useState<FormInitalValues | null>(null);

  React.useEffect(() => {
    if (props.blocking) return;

    const form = props.formData;
    const defaultValues = props.defaultValues;
    const ddd = { ...defaultValues };
    const initVal: FormInitalValues = { ...defaultValues } || {};

    form.sections.forEach((section) => {
      if (section.isList) {
        if (section.count === undefined) {
          section.count = 1;
        }

        initVal[section.name] = [];
        for (let i = 0; i < section.count!; i++) {
          initVal[section.name][i] =
            (ddd[section.name] && ddd[section.name][i]) || {};
          section.fields.forEach((field) => {
            if (section.isList) {
              // @ts-ignore
              initVal[section.name][i][field.name] =
                // @ts-ignore
                // ddd &&
                //   // @ts-ignore
                //   ddd[section.name][field.name]) ||
                (ddd[section.name] &&
                  ddd[section.name][i] &&
                  // @ts-ignore
                  ddd[section.name][i][field.name]) ||
                "";

              return;
            }
          });
        }
      } else {
        section.fields.forEach((field) => {
          if (!initVal[section.name]) {
            initVal[section.name] = {};
          }

          if (field.name.match(/\./)) {
            const [subsection, subfield] = field.name.split(".");

            // @ts-ignore
            if (!initVal[section.name][subsection]) {
              initVal[section.name][subsection] = {};
            }

            initVal[section.name][subsection][subfield] =
              // @ts-ignore
              (ddd &&
                // @ts-ignore
                ddd[section.name][subsection] &&
                // @ts-ignore
                ddd[section.name][subsection][subfield]) ||
              "";
          } else {
            if (field.type === FormFieldType.upload) {
              if (!initVal._uploads) {
                initVal._uploads = {};
              }

              initVal._uploads[field.name] =
                ddd._uploads && ddd._uploads[field.name];
            } else {
              // @ts-ignore
              initVal[section.name][field.name] =
                // @ts-ignore
                (ddd &&
                  // @ts-ignore
                  ddd[section.name] &&
                  // @ts-ignore
                  ddd[section.name][field.name]) ||
                "";
            }
          }
        });
      }
    });

    setInitialValues(initVal);
  }, [props.defaultValues, props.formData, props.blocking]);

  return initialValues;
};
