import { useTranslation } from "react-i18next";

import { calculateScore } from "./utils";
import { Risk, RiskMitigation } from "./types";

interface RemainingRiskScoreFormFieldProps {
  idx: number;
  isRisk?: boolean;
  risk: Risk;
  mitigations: RiskMitigation[];
}

export const RemainingRiskScoreFormField = (
  props: RemainingRiskScoreFormFieldProps
) => {
  const { t } = useTranslation();
  if (!props.risk || !props.mitigations) {
    return null;
  }

  const assessment = props.isRisk ? props.risk : props.mitigations[props.idx];

  if (props.isRisk && isAssessmentEmpty(assessment)) {
    return null;
  }

  const calculatedScore = calculateRiskScore(props);

  const label = props.isRisk
    ? t("form.risk.initial_risk")
    : t("form.risk.remaining_risk");

  return (
    <div key={props.idx} className="col-span-6">
      <div className="flex justify-between">
        <label className="block text-md font-medium text-gray-700">
          {label}
          <b>{isNaN(calculatedScore) ? "-" : calculatedScore}</b>
        </label>
      </div>
    </div>
  );
};

interface Assessment {
  impact: number;
  people_count: number;
  duration: number;
  probability: number;
  detectability: number;
}
const isAssessmentEmpty = (assessment: Assessment) => {
  return (
    !assessment.impact ||
    !assessment.people_count ||
    !assessment.duration ||
    !assessment.probability ||
    !assessment.detectability
  );
};

const calculateRiskScore = (props: RemainingRiskScoreFormFieldProps) => {
  if (props.isRisk) {
    return calculateScore(props.risk);
  }

  const mitigationIdxToCalculateRisk = isAssessmentEmpty(
    props.mitigations[props.idx]
  )
    ? props.idx - 1
    : props.idx;

  if (mitigationIdxToCalculateRisk < 0) {
    return calculateScore(props.risk);
  }

  return calculateScore(props.mitigations[mitigationIdxToCalculateRisk]);
};
