import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface Breadcrumb {
  label: string;
  location: string;
}

interface BreadcrumbsProps {
  items: Breadcrumb[];
}

const BreadcrumbItem = (props: Breadcrumb & { isFirst: boolean }) => {
  const { t } = useTranslation();

  return (
    <li>
      <div className="flex items-center">
        {props.isFirst && (
          <svg
            className="flex-shrink-0 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
        <Link
          to={props.location}
          className={`${
            props.isFirst ? "ml-4" : ""
          } text-sm font-medium text-gray-500 hover:text-cyan-600 hover:underline`}
        >
          {t(props.label)}
        </Link>
      </div>
    </li>
  );
};

export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = (
  props
) => {
  return (
    <div className="mb-6">
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {props.items.map((i, idx) => (
              <BreadcrumbItem key={i.label} isFirst={idx > 0} {...i} />
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};
