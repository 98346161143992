import React from "react";
import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";
import { AnnexSection, Directive } from "./types";
import { QuestionaireItem } from "./hooks";
import TextInput from "components/inputs/TextInput";
import { FollowupSelector } from "./FollowupSelector";
import SelectInput from "components/inputs/SelectInput";
import { BasePage } from "components/BasePage/BasePage";
import { AnnexSectionSelector } from "./AnnexSectionSelector";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { ProductCategorySelector } from "components/common/ProductCategorySelector";
import { WysiwygInput } from "components/inputs/WysiwygInput";

const useNewQuestionItem = () => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (item: QuestionaireItem) => {
      return client.post("/questionaireItems", [item]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["questionaireItems"]);
        history.push("/_backoffice/_questionaire");
      },
    }
  );

  return mutate;
};

export const NewQuestion = () => {
  const createQuestion = useNewQuestionItem();
  const [isFirstLevel, setIsFirstLevel] = React.useState<boolean>(false);
  const [isExit, setIsExit] = React.useState<boolean>(false);
  const [isDirective, setIsDirective] = React.useState<boolean>(false);
  const [directive, setDirective] = React.useState<Directive>(Directive.mrl);
  const [article, setArticle] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [question, setQuestion] = React.useState<string>("");
  const [refNumber, setRefNumber] = React.useState<string>("");
  const [original1, setOriginal1] = React.useState<string>("");
  const [original2, setOriginal2] = React.useState<string>("");
  const [questionLang, setQuestionLang] = React.useState<string>("DE");
  const [questionsByLang, setQuestionsByLang] = React.useState<
    Record<string, string>
  >({});
  const [isCategoryQuestion, setIsCategoryQuestion] =
    React.useState<boolean>(false);
  const [category, setCategory] = React.useState<string | null>(null);

  const [explanation, setExplanation] = React.useState<string>("");
  const [explanationsByLang, setExplanationsByLang] = React.useState<
    Record<string, string>
  >({});

  const [info, setInfo] = React.useState<string>("");
  const [infosByLang, setInfosByLang] = React.useState<Record<string, string>>(
    {}
  );

  const [org1ByLang, setOrg1ByLang] = React.useState<Record<string, string>>(
    {}
  );

  const [org2ByLang, setOrg2ByLang] = React.useState<Record<string, string>>(
    {}
  );

  const [followups, setFollowUps] = React.useState<QuestionaireItem[]>([]);
  const [annexSections, setAnnexSections] = React.useState<AnnexSection[]>([]);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        { label: "Questionaire", location: "/_backoffice/_questionaire" },
        { label: "Neue Frage", location: "" },
      ]}
    >
      <div>
        <h2 className="text-lg">Neue Frage</h2>
        <TextInput
          id="referenceNumber"
          label="Referenz-Nummer"
          // @ts-ignore
          value={refNumber}
          // @ts-ignore
          onBlur={(e) => {
            setRefNumber(e.target.value);
          }}
        />

        <SelectInput
          id="language"
          label="Sprache"
          options={[
            { value: "DE", label: "DE" },
            { value: "EN", label: "EN" },
          ]}
          // @ts-ignore
          value={questionLang}
          onBlur={(e) => {
            // @ts-ignore
            const language = e.target.value;
            setQuestion(questionsByLang[language] || "");
            setInfo(infosByLang[language] || "");
            setExplanation(explanationsByLang[language] || "");
            setOriginal1(org1ByLang[language] || "");
            setOriginal2(org2ByLang[language] || "");
            setQuestionLang(language);
          }}
        />

        <TextInput
          id="question"
          label="Frage"
          // @ts-ignore
          value={question}
          // @ts-ignore
          onBlur={(e) => {
            setQuestion(e.target.value);
            setQuestionsByLang({
              ...questionsByLang,
              [questionLang]: e.target.value,
            });
          }}
        />

        <div className="py-2">
          <input
            type="checkbox"
            id="isDirective"
            name="isDirective"
            checked={isDirective}
            onChange={() => setIsDirective(!isDirective)}
          />
          <label htmlFor="isDirective">
            {" "}
            Richtlinienfrage (wird also gefragt, um anzuwendene Richtlinien zu
            bestimmen)
          </label>
        </div>

        <div className="py-2">
          <input
            type="checkbox"
            id="scales"
            name="scales"
            checked={isFirstLevel}
            onChange={() => setIsFirstLevel(!isFirstLevel)}
          />
          <label htmlFor="scales">
            {" "}
            Ist eine Top-Level-Frage (wird als erstes gefragt z.b. "Ist eine
            Maschine?")
          </label>
        </div>

        <div className="py-2">
          <input
            type="checkbox"
            id="exit"
            name="exit"
            checked={isExit}
            onChange={() => setIsExit(!isExit)}
          />
          <label htmlFor="exit"> Ist eine Ausschlussfrage?</label>
        </div>

        <div className="py-2">
          <SelectInput
            required
            id="directive"
            name="directive"
            label="Welcher Richtlinie wird diese Frage zugeordnet?"
            options={[
              { value: Directive.lvd, label: "Niederspannungsrichtlinie" },
              { value: Directive.mrl, label: "Maschinenrichtlinie" },
              { value: Directive.mvo, label: "Maschinenverordnung" },
              { value: Directive.pressureequip, label: "Druckgeräte" },
              { value: Directive.pressurevessel, label: "Druckbehälter" },
              { value: Directive.rohs, label: "RoHS" },
              { value: Directive.atex, label: "Atex" },
              { value: Directive.outdoor, label: "Outdoor" },
              { value: Directive.emv, label: "EMV" },
            ]}
            defaultValue={directive}
            // @ts-ignore
            onBlur={(e) => {
              // @ts-ignore
              const directive = e.target.value as Directive;
              setDirective(directive);
            }}
          />
        </div>

        <TextInput
          id="article"
          label="Artikel"
          // @ts-ignore
          value={article}
          // @ts-ignore
          onBlur={(e) => {
            setArticle(e.target.value);
          }}
        />

        <WysiwygInput
          id="explanation"
          label="Erläuterung"
          defaultValue={explanation}
          onBlur={(content) => {
            setExplanation(content);
            setExplanationsByLang({
              ...explanationsByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="example"
          label="Beispiel"
          defaultValue={info}
          onBlur={(content) => {
            setInfo(content);
            setInfosByLang({
              ...infosByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="original1"
          label="Originaltext 1"
          defaultValue={original1}
          onBlur={(content) => {
            setOriginal1(content);
            setOrg1ByLang({
              ...org1ByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="original2"
          label="Hinweis/Empfehlung"
          defaultValue={original2}
          onBlur={(content) => {
            setOriginal2(content);
            setOrg2ByLang({
              ...org2ByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="comments"
          label="Interner Kommentar"
          defaultValue={comment}
          onBlur={(content) => {
            setComment(content);
          }}
        />

        <div>
          <h3 className="text-lg">Produkt-Kategorie</h3>
          <div className="py-2">
            <input
              type="checkbox"
              id="scales"
              name="scales"
              checked={isCategoryQuestion}
              onChange={() => {
                if (isCategoryQuestion) {
                  setCategory(null);
                }

                setIsCategoryQuestion(!isCategoryQuestion);
              }}
            />
            <label htmlFor="productCategory">
              {" "}
              Ist eine "Produkt-Kategorie"-Frage (Schritt 2 der EU
              Rechtsvorschriftenrecherche)?
            </label>

            {isCategoryQuestion && (
              <ProductCategorySelector
                onChange={setCategory}
                defaultSelection={category}
              />
            )}
          </div>
        </div>

        <FollowupSelector
          followups={followups}
          onChange={(f) => setFollowUps(f)}
        />
        <AnnexSectionSelector
          sections={annexSections}
          onChange={(as) => setAnnexSections(as)}
        />

        <div className="py-2">
          <PrimaryButton
            onClick={() =>
              createQuestion({
                questions: Object.keys(questionsByLang).map((lang) => {
                  return {
                    language: lang,
                    content: questionsByLang[lang],
                  };
                }),
                infos: Object.keys(infosByLang).map((lang) => {
                  return {
                    language: lang,
                    content: infosByLang[lang],
                  };
                }),
                explanations: Object.keys(explanationsByLang).map((lang) => {
                  return {
                    language: lang,
                    content: explanationsByLang[lang],
                  };
                }),
                originalTexts1: Object.keys(org1ByLang).map((lang) => {
                  return {
                    language: lang,
                    content: org1ByLang[lang],
                  };
                }),
                originalTexts2: Object.keys(org2ByLang).map((lang) => {
                  return {
                    language: lang,
                    content: org2ByLang[lang],
                  };
                }),
                followups: followups.map((f) => f.resource_id!),
                annex_sections: annexSections.map((as) => as.resource_id!),
                is_first_level: isFirstLevel,
                is_exit_question: isExit,
                is_directive_question: isDirective,
                ref_number: refNumber,
                product_category: category,
                directive,
                comment,
                article,
              })
            }
          >
            Frage hinzufügen
          </PrimaryButton>
        </div>
      </div>
    </BasePage>
  );
};
