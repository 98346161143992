import { useQuery } from "react-query";
import { useClient } from "utils/client";

export interface User {
  name: string;
  crn: string;
  first_name: string; // TODO: REMOVE
  last_name: string; // TODO: REMOVE
  email_adress: string;
  city: string;
  company: string;
  state: string;
  street_address: string;
  post_code: string;
  country: string;
  is_admin: boolean;
  phone: string;
  auth0_id: string;
  subscription?: {
    plan: string;
    frequency: string;
    trailEnd: number;
  };
}

export const useUser = () => {
  const axios = useClient();
  const { data, isLoading } = useQuery("user", async () => {
    const { data } = await axios.get<User>("/users");
    return data;
  });

  return { user: data, isLoading };
};
