import * as React from "react";
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import Form from "forms/Form";
import { useClient } from "utils/client";
import { useTranslation } from "react-i18next";
import { ControlContext } from "context/Controls";

import { useRiskGroups } from "pages/Risk/hooks/useRiskGroups";
import { FormCb, defaultVal } from "components/Risk/formdata";
import {
  buildBackofficeRiskFormData,
  handleBackofficeRiskGroupChange,
} from "./formdata";

export const orZero = (val: string | number) => val || 0;

const postNewTemplateRisk = async (
  client: AxiosInstance,
  { risk, mitigations }: FormCb
) => {
  try {
    await client.post(`/backoffice/risks`, {
      ...risk,
      is_blueprint: true,
      assessment: {
        impact: orZero(risk.impact),
        people_count: orZero(risk.people_count),
        duration: orZero(risk.duration),
        probability: orZero(risk.probability),
        detectability: orZero(risk.detectability),
      },
      impact: orZero(risk.impact),
      people_count: orZero(risk.people_count),
      duration: orZero(risk.duration),
      probability: orZero(risk.probability),
      detectability: orZero(risk.detectability),
      mitigations: mitigations.map((mitigation) => ({
        ...mitigation,
        impact: orZero(mitigation.impact),
        people_count: orZero(mitigation.people_count),
        duration: orZero(mitigation.duration),
        probability: orZero(mitigation.probability),
        detectability: orZero(mitigation.detectability),
      })),
    });
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }

  return risk;
};

interface RiskCreateProps {}

export const BackofficeRiskCreate: React.FunctionComponent<
  RiskCreateProps
> = () => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { riskGroups } = useRiskGroups();
  const { displayNotification } = React.useContext(ControlContext);
  const [formData, setFormData] = React.useState<any>();

  const { mutate } = useMutation(
    async (v: FormCb) => {
      return await postNewTemplateRisk(client, v);
    },
    {
      onError: (e) =>
        displayNotification!(
          "Error creating Risk",
          (e as Error).message,
          "error"
        ),
      onSuccess: (newRisk) => {
        queryClient.refetchQueries(["backoffice", "risks"]);
        history.push(`/_backoffice/_risks`);

        displayNotification!(
          "Risiko erzeugt",
          `Risk "${newRisk.description}" was created`
        );
      },
    }
  );

  React.useEffect(() => {
    if (!riskGroups) {
      return;
    }

    const formdata = buildBackofficeRiskFormData(
      riskGroups,
      (key: string) => t(key),
      riskGroups[0].riskGroup.name
    );
    setFormData(formdata);
  }, [riskGroups, t]);

  if (!formData) {
    return null;
  }

  const handleChange = (form: any) => {
    const selectedRiskGroup = riskGroups?.find(
      (rg) => rg.riskGroup.name === form.risk.group_id
    )!;

    const newForm = handleBackofficeRiskGroupChange(
      selectedRiskGroup,
      (key: string) => t(key)
    );
    setFormData(newForm);
  };

  return (
    <div>
      <Form
        formData={formData}
        onChange={handleChange}
        defaultValues={defaultVal}
        saveText={"Gefährdung speichern"}
        onSubmit={async (v) => mutate(v as FormCb)}
      />
    </div>
  );
};
