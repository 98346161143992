import { Descendant } from "slate";
import { CustomElement, HeadingElement } from "./types";

interface SubChapter {
  id: string;
  title: string;
}

interface Chapter {
  id: string;
  title: string;
  subChapters: SubChapter[];
  startIndex: number;
  endIndex: number;
}

type ChapterData = {
  id: string;
  title: string;
  subChapters: SubChapter[];
  startIndex: number;
  endIndex: number;
};

export const isHeading = (node: Descendant, level: 1 | 2): boolean => {
  if (!("type" in node) || node.type !== "heading") return false;
  const headingNode = node as HeadingElement;
  return (
    headingNode.level === level &&
    Array.isArray(headingNode.children) &&
    headingNode.children.length > 0 &&
    "text" in headingNode.children[0]
  );
};

export const generateChaptersFromContent = (
  content: Descendant[]
): Chapter[] => {
  const chapters: Chapter[] = [];

  let currentChapterData: ChapterData | null = null;

  content.forEach((node, index) => {
    if (isHeading(node, 1)) {
      // If we find an H1, end the previous chapter and start a new one
      if (currentChapterData) {
        const completedChapter: Chapter = {
          ...currentChapterData,
          endIndex: index - 1,
        };
        chapters.push(completedChapter);
      }
      const headingNode = node as HeadingElement;
      currentChapterData = {
        id: `${chapters.length + 1}`,
        title: headingNode.children[0].text,
        subChapters: [],
        startIndex: index,
        endIndex: content.length - 1, // Will be updated when next H1 is found or at the end
      };
    } else if (isHeading(node, 2) && currentChapterData) {
      // If we find an H2 and have a current chapter, add it as a subchapter
      const headingNode = node as HeadingElement;
      const subChapter: SubChapter = {
        id: `${currentChapterData.id}.${
          currentChapterData.subChapters.length + 1
        }`,
        title: headingNode.children[0].text,
      };
      currentChapterData.subChapters.push(subChapter);
    }
  });

  // Don't forget to add the last chapter
  if (currentChapterData) {
    const finalChapter: Chapter = {
      ...(currentChapterData as any),
      endIndex: content.length - 1,
    };
    chapters.push(finalChapter);
  }

  return chapters;
};

export const getChapterContent = (
  fullContent: Descendant[],
  chapter: Chapter
): Descendant[] => {
  return fullContent.slice(chapter.startIndex, chapter.endIndex + 1);
};

export const createNewChapterNode = (): CustomElement[] => {
  const newChapterNode: HeadingElement = {
    type: "heading",
    level: 1,
    children: [{ text: "Neues Kapitel" }],
  };
  const paragraphNode: CustomElement = {
    type: "paragraph",
    children: [{ text: "" }],
  };
  return [newChapterNode, paragraphNode];
};
