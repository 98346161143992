import * as React from "react";
import { useParams } from "react-router-dom";

import { useOneProject } from "hooks/useProjects";
import { RiskCreate } from "components/Risk/Create";
import { BasePage } from "components/BasePage/BasePage";

interface RiskCreateRouteParams {
  projectId: string;
}

export const CreateRisk = () => {
  const { projectId } = useParams<RiskCreateRouteParams>();
  const project = useOneProject(projectId);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.risks.label",
          location: `/projects/${projectId}/risks`,
        },
        {
          label: "steps.risks.new",
          location: `/projects/${projectId}/risks/create`,
        },
      ]}
    >
      <div>
        <RiskCreate projectId={projectId} />
      </div>
    </BasePage>
  );
};
