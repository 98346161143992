import { useTranslation } from "react-i18next";
import React, { useLayoutEffect, useRef, useState } from "react";

import { Project } from "types";
import { DocType } from "./types";
import { DocumentRow } from "./DocumentRow";
import { Modal } from "components/Modal/Modal";
import { useEnhancedDocuments } from "./hooks/useEnhancedDocuments";

interface DocumentTableProps {
  project?: Project;
  onSelect: (docs: DocType[]) => void;
}

export const DocumentTable = (props: DocumentTableProps) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [previewLocation, setPreviewLocation] = React.useState("");

  const docs = useEnhancedDocuments(props.project);

  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState<DocType[]>([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedDocs?.length > 0 && selectedDocs?.length < docs.length;
    setChecked(selectedDocs?.length === docs.length);
    setIndeterminate(isIndeterminate);
    // @ts-ignore
    checkbox!.current!.indeterminate = isIndeterminate;
  }, [selectedDocs, docs]);

  const toggleAll = () => {
    const allSelected =
      checked || indeterminate ? [] : docs.map(({ type }) => type);

    setIndeterminate(false);
    props.onSelect(allSelected);
    setSelectedDocs(allSelected);
    setChecked(!checked && !indeterminate);
  };

  const handlePreview = (docLocation: string) => {
    setModalVisible(true);
    setPreviewLocation(docLocation);
  };

  const handleChange =
    (doc: DocType) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSelectedDocs = e.target.checked
        ? [...selectedDocs, doc]
        : selectedDocs.filter((d) => d !== doc);

      setSelectedDocs(newSelectedDocs);
      props.onSelect(newSelectedDocs);
    };

  return (
    <div className="hidden mt-4 pb-16 sm:block">
      <div className="align-middle inline-block min-w-full">
        <table className="shadow-md overflow-y-auto w-full">
          <thead className="w-full">
            <tr>
              <th
                scope="col"
                className="relative px-7 sm:w-12 sm:px-6 bg-gray-50 border-b border-gray-200"
              >
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-600"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <span className="lg:pl-2">{t("documents.table.type")}</span>
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t("documents.table.visibility")}
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t("documents.table.status")}
              </th>
              <th className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {t("documents.table.revision")}
              </th>
              <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100">
            {docs.map((doc) => (
              <DocumentRow
                key={doc.type}
                document={doc}
                onPreviewDoc={handlePreview}
                onChange={handleChange(doc.type)}
                isSelected={selectedDocs.includes(doc.type)}
              />
            ))}
          </tbody>
        </table>
        <>
          <Modal
            onClose={() => setModalVisible(false)}
            show={modalVisible}
            width="1/2"
            content={
              <div style={{ height: "85vh" }}>
                <embed
                  className="h-full w-full"
                  src={previewLocation}
                  type="application/pdf"
                />
              </div>
            }
          />
        </>
      </div>
    </div>
  );
};
