import React from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/outline";
import { FormDocument } from "@slootsantos/certain-forms/dist/types";
import { DeleteButton } from "components/Button/DeleteButton";
import { useConfirmation } from "context/Confirmation";

interface UploadProps extends FormDocument {
  document?: FormDocument;
  file?: File;
  onChange?: (file: File | null, docType: FormDocument) => void;
  preventDeletetion?: boolean;
}

export const DocumentUpload = (props: UploadProps) => {
  const { t } = useTranslation();
  const { openConfirmation } = useConfirmation();
  const [file, setFile] = React.useState<File | undefined>(props.file);

  React.useEffect(() => {
    setFile(props.file);
  }, [props.file]);

  const handleDelete = () => {
    openConfirmation(
      () => {
        setFile(undefined);
        if (props.onChange) {
          props.onChange(null, props.document!);
        }
      },
      {
        label: t("form.basic.label"),
        subline: t("form.project.delete_image_confirmation_subline"),
      }
    );
  };

  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="cover_photo"
        className="block text-sm font-medium text-gray-700"
      >
        {t(props.document?.label || props.label)}
      </label>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          {!file && !props.document?.location && (
            <>
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600 justify-center">
                <label
                  // htmlFor={props.document.name}
                  className="relative cursor-pointer bg-white rounded-md font-medium text-cyan-600 hover:text-cyan-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
                >
                  <span>{t("common.file_upload")}</span>
                  <input
                    id={props.document?.name || props.name}
                    name={props.document?.name || props.name}
                    type="file"
                    accept="image/png, image/jpeg"
                    className="sr-only"
                    onChange={(f) => {
                      if (f.target.files?.length) {
                        if (f.target.files[0].size > 4 * 1024 * 1024) {
                          window.alert(
                            "Datei größer als 5 MB. Bitte wählen Sie eine Datei kleiner als 5 MB."
                          );

                          return;
                        }

                        setFile(f.target.files[0]);

                        if (props.onChange) {
                          props.onChange(f.target.files[0], props.document!);
                        }
                      }
                    }}
                  />
                </label>
                {/* <p className="pl-1">or drag and drop</p> */}
              </div>
              <p className="text-xs text-gray-500">PNG, JPG, SVG bis zu 5 MB</p>
            </>
          )}
          {file &&
            (file?.size > 0 ? (
              <>
                {URL.createObjectURL(file) && (
                  <img width="400" src={URL.createObjectURL(file)} />
                )}
                {!props.preventDeletetion && (
                  <div className="flex justify-center pt-4">
                    {/* <p className="text-xs text-gray-500">{file.name}</p> */}

                    <span onClick={handleDelete}>
                      <TrashIcon className="ml-4 w-4 h-4 text-red-500 hover:text-black cursor-pointer" />
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>
                <img width="400" src={props.location} />
                {!props.preventDeletetion && (
                  <div className="flex justify-center pt-4">
                    {/* <p className="text-sm text-gray-500">{file.name}</p> */}

                    <span onClick={handleDelete}>
                      <TrashIcon className="ml-4 w-4 h-4 text-red-500 hover:text-black cursor-pointer" />
                    </span>
                  </div>
                )}
              </>
            ))}
          {!file && props.document?.location && (
            <>
              <img width="400" src={props.document?.location} />

              {!props.preventDeletetion && (
                <div className="flex justify-center pt-4">
                  <p className="text-xs text-gray-500">
                    {props.document?.name}
                  </p>
                  <span onClick={handleDelete}>
                    <TrashIcon className="ml-4 w-4 h-4 text-red-500 hover:text-black cursor-pointer" />
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
