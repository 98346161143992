import { useHistory } from "react-router-dom";

import { BasePage } from "components/BasePage/BasePage";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { PlusIcon } from "@heroicons/react/solid";
import { useRiskTemplates } from "pages/Risk/hooks/useRisks";
import { BackofficeRisk } from "components/Backoffice/Risk";

export const RisksBackoffice = () => {
  const history = useHistory();
  const { risks, isLoading } = useRiskTemplates();

  const handleNewRisk = () => history.push(`/_backoffice/_risks/new`);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Risiko-Vorlagen",
          location: "/_backoffice/_risks",
        },
      ]}
      loading={isLoading}
    >
      <div>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-3xl leading-6 font-medium text-gray-900">
            Backoffice: Risiko-Vorlagen
          </h3>
        </div>

        <div>
          {(risks?.length &&
            risks
              .sort(
                (a, b) =>
                  new Date(b.last_updated!).getTime() -
                  new Date(a.last_updated!).getTime()
              )
              .map((risk, idx) => (
                <BackofficeRisk key={risk.resource_id} risk={risk} idx={idx} />
              ))) || <EmptyRisks onClick={handleNewRisk} />}
        </div>
      </div>

      <div className="pt-9 text-right">
        <PrimaryButton onClick={handleNewRisk}>
          neue Gefährdungs-Vorlage hinzufügen
        </PrimaryButton>
      </div>
    </BasePage>
  );
};

interface EmptyRiskProps {
  onClick: () => void;
}

const EmptyRisks = ({ onClick }: EmptyRiskProps) => {
  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Keine Gefährdungen
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Lege mit der Risikobeurteilung los.
      </p>
      <div className="mt-6">
        <PrimaryButton onClick={onClick}>
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Gefährdung hinzufügen
        </PrimaryButton>
      </div>
    </div>
  );
};

export default RisksBackoffice;
