import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

import Logo from "logos/Logo_dark.png";
import NavButton from "./NavButton";
import { LanguageSelector } from "./LanguageSelector";
import { useLogout } from "hooks/useLogout";
import { AuthContext } from "context/Auth";

const Header = () => {
  const logout = useLogout();
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { loginWithRedirect /*isAuthenticated*/ } = useAuth0();

  return (
    <>
      <div className="header-2">
        <nav className="bg-white py-2 md:py-4">
          <div className="container px-4 mx-auto md:flex md:items-center">
            <div className="flex justify-between items-center">
              <Link to="/" className="font-bold text-xl text-cyan-600">
                <img src={Logo} alt="" className="h-10" />
              </Link>
            </div>

            <div
              className=" md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0"
              id="navbar-collapse"
            >
              {isAuthenticated ? (
                <>
                  <Link to="/dashboard">
                    <NavButton path="/dashboard" content="Dashboard" />
                  </Link>
                  <Link to="/logout">
                    <NavButton
                      content={t("routes.logout")}
                      path="/logout"
                      onClick={logout}
                    />
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/pricing">
                    <NavButton path="" content="Pricing" />
                  </Link>
                  <Link to="/login">
                    <NavButton
                      highlighted
                      path="/login"
                      content={t("routes.login")}
                      onClick={() =>
                        loginWithRedirect({
                          redirectUri:
                            process.env.REACT_APP_APP_HOST + "/dashboard",
                        })
                      }
                    />
                  </Link>
                </>
              )}
              <LanguageSelector />
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
