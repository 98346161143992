import React from "react";

import { QuestionaireItem, useQuestionaireItems } from "./hooks";
import SelectInput from "components/inputs/SelectInput";
import { TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { ListSearch } from "components/ListSearch/ListSearch";

interface FollowupSelectorProps {
  onChange: (ids: QuestionaireItem[]) => void;
  followups?: QuestionaireItem[];
}

export const FollowupSelector = (props: FollowupSelectorProps) => {
  const { followups = [] } = props;
  const questionaireItems = useQuestionaireItems();

  if (!questionaireItems?.length) {
    return <span>Loading</span>;
  }

  const selectOptions = questionaireItems!
    .map((q) => {
      return {
        value: (q.questions?.length && q.questions[0].content) || "",
        label: (q.questions?.length && q.questions[0].content) || "",
      };
    })
    .filter(Boolean);

  return (
    <div className="py-4">
      <h3 className="text-lg">Gewählte Folgefragen</h3>

      <ListSearch
        placeholder="Folgefragen durchsuchen. z.B.: '1.1.7' oder 'Folgefrage'"
        values={questionaireItems.map((q) => ({
          name: q.article!,
          content: (q.questions?.length && q.questions[0].content) || "",
        }))}
        translatedValues={questionaireItems.map((q) => ({
          name: q.article,
          content: (q.questions?.length && q.questions[0].content) || "",
        }))}
        onSelect={(selectedAnnex) => {
          const newFollowups = [
            ...followups,
            questionaireItems.find(
              (q) =>
                q.questions?.length &&
                q.questions[0].content === selectedAnnex.content
            )!,
          ];

          props.onChange(newFollowups);
        }}
      />
      <div className="grid grid-cols-5 gap-4">
        {followups.map((f) => (
          <div
            className="relative rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm flex items-center justify-center space-x-3"
            key={f.resource_id}
          >
            <Link
              className="hover:text-cyan-700"
              to={{
                pathname: `/_backoffice/_questionaire/${f.resource_id}/edit`,
              }}
            >
              [{f.article}] {f.questions[0].content}
            </Link>
            <TrashIcon
              className="flex-shrink-0 h-4 text-red-500 hover:text-black cursor-pointer"
              onClick={() => {
                const filteredFollowups = followups.filter(
                  (a) => a.resource_id !== f.resource_id
                );

                props.onChange(filteredFollowups);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
