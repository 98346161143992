import React from "react";
import { useTranslation } from "react-i18next";

import { SelectInputProps } from "./types";
import Collapsible from "components/Collapsible/Collapsible";

const SelectInput: React.FunctionComponent<SelectInputProps> = (props) => {
  const { t } = useTranslation();
  const { options, collapsibleItems, uncontrolled, ...rest } = props;

  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>

      <select
        {...rest}
        id={props.id}
        name={props.id}
        onChange={props.onBlur}
        autoComplete={props.autoComplete}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
      >
        <option value="" disabled selected={props.defaultValue === ""}>
          {t("common.selector.default")}
        </option>
        {options &&
          options.map((option) => (
            <option
              key={option.label}
              value={option.value}
              selected={props.defaultValue === option.value}
            >
              {t(option.label)}
            </option>
          ))}
      </select>
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectInput;
