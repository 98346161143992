import * as React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: "417b3db6d4487414c113f347d2ec1ece",
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV || "local",
  });
}

export const ErrorBoundary: React.FunctionComponent<React.PropsWithChildren> = (
  props
) => {
  const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

  if (!ErrorBoundary) return <>{props.children}</>;

  return <ErrorBoundary>{props.children}</ErrorBoundary>;
};
