import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { InviteMemberModal } from "./InviteMember";
import { useRemoveMember } from "./useRemoveMember";
import { useConfirmation } from "context/Confirmation";
import { useUser } from "pages/Settings/hooks/useUser";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { Member, useAccount } from "pages/Settings/hooks/useAccount";

export const TeamTab = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { account } = useAccount();
  const [members, setMembers] = useState<Member[]>();

  const { remove } = useRemoveMember();
  const { openConfirmation } = useConfirmation();

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    // Set current user as admin as backwards compatible solution
    if (account && user && !account.members?.length) {
      setMembers([{ email: user.email_adress, role: "admin" }]);
    } else {
      setMembers(account?.members);
    }
  }, [account, user]);

  const handleDelete = (member: Member) => {
    openConfirmation(() => remove(member), {
      label: t("form.teams.remove.label"),
      subline: t("form.teams.remove.subline", { email: member.email }),
    });
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("form.teams.label")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {t("form.teams.subline")}
          </p>
          <span className="text-sm text-gray-500">
            <Trans
              i18nKey="form.teams.available_members"
              values={{ count: account?.subscription?.freeSeats }}
            />
          </span>
        </div>
      </div>
      <div className="mt-8 flow-root w-2/3">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("form.teams.member.email")}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("form.teams.member.role")}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {members?.map((member) => (
                  <tr key={member.email}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {member.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {t(`form.teams.roles.${member.role}`) || member.role}
                    </td>
                    {member.role !== "admin" && (
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-5 w-5 text-red-500 hover:text-gray-900 cursor-pointer"
                          onClick={() => {
                            handleDelete(member);
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                          />
                        </svg>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {(account?.subscription?.freeSeats || 0) > 0 && (
        <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-">
          <div className="flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-2">
            <PrimaryButton onClick={() => setModalVisible(true)}>
              {t("form.teams.add")}
            </PrimaryButton>
          </div>
        </div>
      )}

      {modalVisible && (
        <InviteMemberModal onClose={() => setModalVisible(false)} />
      )}
    </>
  );
};
