import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import pdfToB64 from "pdf-to-base64";

import { useClient } from "utils/client";
import { DocumentData } from "types";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import {
  FullFileBrowser,
  setChonkyDefaults,
  FileHelper,
  FileArray,
  ChonkyFileActionData,
  ChonkyActions,
  ChonkyIconName,
  I18nConfig,
} from "chonky";
import { AxiosInstance } from "axios";

const germanI18n: I18nConfig = {
  locale: "de-DE",
  messages: {
    // Chonky UI translation strings. String IDs hardcoded into Chonky's source code.
    "chonky.toolbar.searchPlaceholder": "Suche",
    "chonky.toolbar.visibleFileCount": `{fileCount, plural,
          one {# Datei}
          few {# Dateien}
          many {# Dateien}
      }`,
    "chonky.toolbar.selectedFileCount": `{fileCount, plural,
          =0 {}
          one {# Datei}
          other {# Dateien}
      }`,
    "chonky.fileList.nothingToShow": "Nichts zu sehen!",
    "chonky.contextMenu.browserMenuShortcut": "Menü: {shortcut}",

    // File action translation strings. These depend on which actions you have
    // enabled in Chonky.
    [`chonky.actionGroups.Actions`]: "Aktionen",
    [`chonky.actionGroups.Options`]: "Optionen",
    [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: "open",
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: "create",
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]:
      "create",
    [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: "delete",
    [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]:
      "Auswahl öffnen",
    [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]:
      "Alle auswählen",
    [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]:
      "Auswahl zurücksetzen",
    [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]:
      "Listenansicht",
    [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]:
      "Gitteransicht",
    [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]:
      "Nach Namen sortieren",
    [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]:
      "Nach Größe sortieren",
    [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]:
      "Nach Datum sortieren",
    [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]:
      "Verstecke Dateien anzeigen",
    [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]:
      "Ordner zuerst",
  },
};

export const useFiles = (currentFolderId: string, fileMap: any): FileArray => {
  return React.useMemo(() => {
    const currentFolder = fileMap[currentFolderId];
    const files = currentFolder.childrenIds
      ? currentFolder.childrenIds.map(
          (fileId: string) => fileMap[fileId] ?? null
        )
      : [];
    return files;
  }, [currentFolderId, fileMap]);
};

export const useFolderChain = (
  currentFolderId: string,
  fileMap: any
): FileArray => {
  return React.useMemo(() => {
    const currentFolder = fileMap[currentFolderId];

    const folderChain = [currentFolder];

    let parentId = currentFolder.parentId;
    while (parentId) {
      const parentFile = fileMap[parentId];
      if (parentFile) {
        folderChain.unshift(parentFile);
        parentId = parentFile.parentId;
      } else {
        parentId = null;
      }
    }

    return folderChain;
  }, [currentFolderId, fileMap]);
};

const openPdf = async (
  client: AxiosInstance,
  documentName: string,
  projectId: string
) => {
  const { data } = await client.get(
    `/documents/signing/${projectId}/${projectId}${documentName}`
  );

  window.open(data, "_blank");
};

export const useFileActionHandler = (
  setCurrentFolderId: (folderId: string) => void,
  documents: DocumentData[],
  projectId: string,
  projectName: string
) => {
  const client = useClient();

  return React.useCallback(
    (data: ChonkyFileActionData) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];
        if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
          setCurrentFolderId(fileToOpen.id);
          return;
        }

        if (fileToOpen && !FileHelper.isDirectory(fileToOpen)) {
          openPdf(client, data.payload?.targetFile?.fullName, projectId);
          return;
        }
      }

      if ((data.id as string) === "download_all_files") {
        zipDownload(documents, projectId, projectName);
      }

      // showActionNotification(data);
    },
    [setCurrentFolderId, documents, projectId, projectName, client]
  );
};

const buildFileMap = (docs: DocumentData[]) => {
  return (docs || []).reduce(
    (agg, doc) => {
      const [, ...dirs] = doc.name.split("/");
      let parentId = "Dokumente";

      dirs.forEach((d, idx) => {
        const isDir = idx < dirs.length - 1;
        if (!agg[d]) {
          agg[d] = {
            name: d,
            id: d,
            childrenIds: [],
            parentId,
            isDir,
            fullName: !isDir ? doc.name : null,
            thumbnailUrl: !isDir ? doc.location : null,
          };
        }

        if (parentId) {
          agg[parentId].childrenIds.push(d);
        }

        parentId = d;
      });

      return agg;
    },
    {
      Dokumente: {
        name: "Dokumente",
        id: "Dokumente",
        isDir: true,
        childrenIds: [],
      },
    } as Record<string, any>
  );
};

export const MyFileBrowser = (props: {
  documents: DocumentData[];
  projectId: string;
  projectName: string;
}) => {
  const fileMap = buildFileMap(props.documents);
  const [currentFolderId, setCurrentFolderId] = React.useState("Dokumente");
  const files = useFiles(currentFolderId, fileMap);
  const folderChain = useFolderChain(currentFolderId, fileMap);
  const handleFileAction = useFileActionHandler(
    setCurrentFolderId,
    props.documents,
    props.projectId,
    props.projectName
  );

  const myFileActions = [
    {
      id: "download_all_files",
      requiresSelection: false,
      button: {
        name: "Alle Dateien herunterladen",
        toolbar: true,
        contextMenu: true,
        group: "Actions",
        icon: ChonkyIconName.download,
      },
    },
  ];
  return (
    <div style={{ height: 300 }}>
      <FullFileBrowser
        fileActions={files.length ? myFileActions : []}
        files={files}
        folderChain={folderChain}
        onFileAction={handleFileAction}
      />
    </div>
  );
};

setChonkyDefaults({
  iconComponent: ChonkyIconFA,
  i18n: germanI18n,
});

interface FileExplorerProps {
  projectId: string;
  projectName: string;
  documents: DocumentData[];
}

// const getFileData = async (documentName: string, projectId: string) => {
//   const { data } = await axios.get(
//     `/documents/signing/${projectId}/${projectId}${documentName}`
//   );

//   const base64Str = await pdfToB64(data);
//   return base64Str;
// };

const inlineTermTranslationMap: Record<string, string> = {
  external: "Extern",
  internal: "Intern",
  "conformity.pdf": "Konformitätserklärung.pdf",
  "installationConformity.pdf": "Einbauerklärung.pdf",
  "risk.pdf": "Risikobeurteilung.pdf",
  "plate.pdf": "Typenschild.pdf",
  "preview_manual.pdf": "Betriebsanleitung_Vorschau.pdf",
  "exitConformity.pdf": "Ausschluss.pdf",
};

const translateFilePath = (path: string) => {
  return path
    .split("/")
    ?.map((sub) => inlineTermTranslationMap[sub] || sub)
    .join("/");
};

export const zipDownload = async (
  documents: DocumentData[],
  projectId: string,
  projectName: string
) => {
  const base64Promises = documents.map(async (d) => {
    const base64Pdf = await pdfToB64(d.location);

    return {
      content: base64Pdf,
      name: translateFilePath(d.name),
    };
  });

  const contents = await Promise.all(base64Promises);

  const zip = new JSZip();
  const allZip = zip.folder(`Technische Dokumentation ${projectName}`);

  contents.forEach((content) => {
    allZip!.file(content.name, content.content, {
      base64: true,
    });
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `technische_dokumentation_${projectName}.zip`);
  });
};

export const FileExplorer = ({
  documents,
  projectId,
  projectName,
}: FileExplorerProps) => {
  return (
    <>
      <h1 className="text-lg">Dateien</h1>
      <MyFileBrowser
        documents={documents}
        projectId={projectId}
        projectName={projectName}
      />
    </>
  );
};
