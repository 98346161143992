import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import axios from "utils/client";

export interface DocumentationArticle {
  name: string;
  data: string;
}

const createDocumentation = async (documentation: DocumentationArticle) => {
  const { data } = await axios.post("/docs", documentation);

  return data;
};

export const useCreateDocumentation = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    async (documentation: DocumentationArticle) =>
      await createDocumentation(documentation),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["documentations"]);
        history.goBack();
      },
    }
  );

  return { createDocumentation: mutate };
};
