import { CheckCircleIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

export const MagicLinkSent = () => {
  const { t } = useTranslation();

  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex items-center text-center">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-md font-medium text-green-800">
            {t("account.sign_in.look_in_your_email")}
          </p>
          <p className="text-xs text-green-800">
            {t("account.sign_in.magic_link")}
          </p>
        </div>
      </div>
    </div>
  );
};
