import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosInstance } from "axios";

import { useClient } from "utils/client";
import { Risk } from "components/Risk/types";
import { FormCb } from "components/Risk/formdata";
import { useHistory } from "react-router";
import { useContext } from "react";
import { ControlContext } from "context/Controls";
import { orZero } from "components/Backoffice/Risk/Create";

const fetchRisks = async (client: AxiosInstance, projectId: string) => {
  const { data } = await client.get(`/projects/${projectId}/risks`);

  return data;
};

const fetchRiskTemplates = async (client: AxiosInstance) => {
  const { data } = await client.get(`/backoffice/risks`);

  return data;
};

export const useRisks = (projectId: string) => {
  const client = useClient();

  const { data: risks, isLoading } = useQuery<Risk[]>(
    ["risks", projectId],
    () => fetchRisks(client, projectId)
  );

  return { risks, isLoading };
};

export const useRiskTemplates = () => {
  const client = useClient();

  const { data: risks, isLoading } = useQuery<Risk[]>(
    ["backoffice", "risks"],
    () => fetchRiskTemplates(client)
  );

  return { risks, isLoading };
};

export const useOneRiskTemplate = (riskTemplateId: string) => {
  const { risks, isLoading } = useRiskTemplates();

  const risk = risks?.find((risk) => risk.resource_id === riskTemplateId);

  return { risk, isLoading };
};

const updateRisk = async (
  client: AxiosInstance,
  { risk, mitigations }: FormCb,
  riskId: string
) => {
  const updatedRisk = {
    ...risk,
    resource_id: riskId,
    impact: orZero(risk.impact),
    people_count: orZero(risk.people_count),
    duration: orZero(risk.duration),
    probability: orZero(risk.probability),
    detectability: orZero(risk.detectability),
    mitigations: mitigations.map((mitigation) => ({
      ...mitigation,
      impact: orZero(mitigation.impact),
      people_count: orZero(mitigation.people_count),
      duration: orZero(mitigation.duration),
      probability: orZero(mitigation.probability),
      detectability: orZero(mitigation.detectability),
    })),
    assessment: {
      impact: orZero(risk.impact),
      people_count: orZero(risk.people_count),
      duration: orZero(risk.duration),
      probability: orZero(risk.probability),
      detectability: orZero(risk.detectability),
    },
  };

  client.put(`/backoffice/risks/${riskId}`, updatedRisk);

  return risk;
};

export const useUpdateRiskTemplate = (riskTemplateId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate } = useMutation(
    async (v: FormCb) => await updateRisk(client, v, riskTemplateId),
    {
      onError: (e) =>
        displayNotification!(
          "Error creating Risk",
          (e as Error).message,
          "error"
        ),
      onSuccess: (newRisk) => {
        queryClient.refetchQueries(["backoffice", "risks"]);

        history.push(`/_backoffice/_risks`);

        displayNotification!(
          "Risiko-Vorlage geupdated!",
          `Risiko "${newRisk.description}" wurde bearbeitet`
        );
      },
    }
  );

  return mutate;
};

export const useDeleteRiskTemplate = (riskTemplateId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate } = useMutation(
    async () => {
      await client.delete(`/backoffice/risks/${riskTemplateId}`);
    },
    {
      onError: (e) =>
        displayNotification!(
          "Error deleting Risk",
          (e as Error).message,
          "error"
        ),
      onSuccess: () => {
        queryClient.refetchQueries(["backoffice", "risks"]);

        history.push(`/_backoffice/_risks`);

        displayNotification!("Risiko-Vorlage gelöscht!", "");
      },
    }
  );

  return mutate;
};
