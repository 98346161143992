import React, { useState } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import { PrimaryButton } from "components/Button/PrimaryButton";

const defaultInput = `
<h1>Neuer Doku-Artikel</h1>
<h2>Wie man einen Dokuartikel schreibt...</h2>
<p><br></p><p>Hier könnt ihr die einfachsten Artikel für die Dokumentation schreiben.</p>
<p>Mit:</p><ul><li>Ungeordneten Listen</li></ul><p>oder</p><ol><li>Geordneten Listen</li><li>etc</li></ol><p><br></p><p><strong>Wichtig</strong> ist, dass ihr so viel schreiben könnt wie ihr wollt.</p>
`;

interface EditorProps {
  onSave: (content: string) => void;
  defaultValue?: string;
}

export const Editor = (props: EditorProps) => {
  const [value, setValue] = useState(props.defaultValue || defaultInput);

  return (
    <div>
      <div className="bg-white">
        <ReactQuill theme="snow" value={value} onChange={setValue} />
      </div>

      <div className="pt-4">
        <PrimaryButton float="right" onClick={() => props.onSave(value)}>
          Artikel speichern
        </PrimaryButton>
      </div>
    </div>
  );
};
