import { useAuth0 } from "@auth0/auth0-react";
import { AuthContext } from "context/Auth";
import React, { useContext } from "react";
import { useQueryClient } from "react-query";

export const useLogout = () => {
  const { logout } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.clear();
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  }, [logout, queryClient]);
};
