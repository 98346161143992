import React from "react";

import TextInput from "components/inputs/TextInput";
import { BasePage } from "components/BasePage/BasePage";
import { Editor } from "components/TextEditor/Editor";
import { useCreateDocumentation } from "./hooks/useCreateDocumentation";

export const NewDocumentation = () => {
  const { createDocumentation } = useCreateDocumentation();
  const [name, setName] = React.useState<string>("");

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Dokumentations Artikel",
          location: "/_backoffice/_docs",
        },
      ]}
    >
      <TextInput
        required
        id="referenceNumber"
        label="Interner Dokumentationsname"
        // @ts-ignore
        onBlur={(e) => {
          // @ts-ignore
          setName(e.target.value);
        }}
      />
      <Editor onSave={(data) => createDocumentation({ data, name })} />
    </BasePage>
  );
};
