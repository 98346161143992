import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { XCircleIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useState } from "react";
import { usePasswordless } from "amazon-cognito-passwordless-auth/react";

import Logo from "logos/Logo_dark.png";
import { MagicLinkSent } from "./MagicLinkSent";
import { Registration } from "pages/Onboarding/Registration";
import { LoadingButtonSpinner } from "components/Loading/LoadingButton";

import { useTranslation, Trans } from "react-i18next";
import { Feature, useFeatureFlags } from "context/FeatureFlag";
import { LanguageSelector } from "components/common/LanguageSelector";

const useMagicOrFido = () => {
  const { requestSignInLink, authenticateWithFido2 } = usePasswordless();
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const login = useCallback(
    (username: string) => {
      setError(false);
      setLoading(true);
      requestSignInLink({
        username,
        redirectUri: window.location.origin + "/dashboard",
      }).signInLinkRequested.catch((err) => {
        if (
          err instanceof Error &&
          err.message.toLowerCase().includes("you must sign-in with fido2")
        ) {
          return authenticateWithFido2({
            username,
          }).catch(() => undefined);
        }

        setError(true);
        setLoading(false);
      });
    },
    [requestSignInLink, authenticateWithFido2]
  );

  return { login, hasError, isLoading, resetError: () => setError(false) };
};

export const Signin = () => {
  const { pathname, search } = useLocation();

  const [email, setEmail] = useState("");
  const { signingInStatus } = usePasswordless();
  const { login, isLoading, hasError, resetError } = useMagicOrFido();
  const { isFeatureEnabled } = useFeatureFlags();
  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    setEmail(searchParams.get("login") || "");
  }, [search, setEmail]);

  const linkIsRequested = signingInStatus === "SIGNIN_LINK_REQUESTED";

  useEffect(() => {
    resetError();
  }, [pathname]);

  if (pathname === "/signup") {
    return <Registration />;
  }

  return (
    <div className="h-screen relative">
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-6 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-4">
          <div>
            <img
              className="mx-auto h-10 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {t("account.sign_in.title")}
            </h2>
            <p className="text-xs text-gray-500 text-center">
              <Trans i18nKey="account.sign_in.subline">
                <a
                  href="https://fidoalliance.org/passkeys/"
                  className="text-cyan-600 hover:text-cyan-800"
                />
              </Trans>
            </p>
          </div>

          {linkIsRequested && <MagicLinkSent />}

          {!linkIsRequested && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                login(email);
              }}
              className="space-y-4"
            >
              <div className="relative -space-y-px rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    {t("account.sign_in.email")}
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    required={true}
                    className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                    placeholder={t("account.sign_in.email")}
                  />
                </div>
              </div>

              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className={`flex w-full items-center gap-x-1.5 justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600 ${
                    isLoading ? "opacity-25" : "opacity-100 hover:bg-cyan-500"
                  }`}
                >
                  {isLoading ? (
                    <>
                      <LoadingButtonSpinner /> {t("account.sign_in.loading")}
                    </>
                  ) : (
                    t("account.sign_in.login")
                  )}
                </button>
              </div>
            </form>
          )}
          {hasError && <PasswordError />}

          <p className="text-center text-sm leading-6 text-gray-500">
            <Trans i18nKey="account.sign_in.not_a_member">
              <Link
                to="/signup"
                className="font-semibold text-cyan-600 hover:text-cyan-500"
              />
            </Trans>
          </p>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 m-4 p-4">
        {isFeatureEnabled(Feature.MultiLanguage) && <LanguageSelector />}
      </div>
    </div>
  );
};

const PasswordError = () => {
  const { t } = useTranslation();

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {t("account.sign_in.error_title")}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <p>{t("account.sign_in.error_message")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
