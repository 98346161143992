import { useParams } from "react-router";

import { BasePage } from "components/BasePage/BasePage";
import { useProjectDetails } from "./Details/hooks/useProjectDetails";
import { TopLevelDirectiveResearch } from "components/DirectiveSearch/TopLevelDirectiveResearch";
import { Feature, useFeatureFlags } from "context/FeatureFlag";
import BaseInformation from "./tabs/BaseInformation";

interface TopLevelDirectiveSearchRouteParams {
  projectId: string;
}

const TopLevelDirectiveSearch = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { projectId } = useParams<TopLevelDirectiveSearchRouteParams>();
  const { project } = useProjectDetails(projectId);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata?.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.questionaire.label",
          location: `/projects/${projectId}/questionaire`,
        },
      ]}
    >
      {isFeatureEnabled(Feature.MultiDirectiveSearch) ? (
        <TopLevelDirectiveResearch type={"ce"} />
      ) : (
        <BaseInformation type={"ce"} />
      )}
    </BasePage>
  );
};

export default TopLevelDirectiveSearch;
