import i18n from "i18n";

const HOUR_MILLISECONDS = 1000 * 60 * 60;
const DAY_MILLISECONDS = HOUR_MILLISECONDS * 24;

export function getRelativeTime(
  timestamp: number,
  langFormat: string = i18n.language
) {
  const rtf = new Intl.RelativeTimeFormat(langFormat, {
    numeric: "auto",
  });

  const daysDifference = Math.round(
    (timestamp - new Date().getTime()) / DAY_MILLISECONDS
  );

  if (daysDifference < -7) {
    return "am " + new Date(timestamp).toLocaleDateString(langFormat);
  }

  if (daysDifference < 0) {
    return rtf.format(daysDifference, "days");
  }

  const hoursDifference = Math.round(
    (timestamp - new Date().getTime()) / HOUR_MILLISECONDS
  );

  return rtf.format(hoursDifference, "hours");
}

export function getRelativeTimeFuture(
  timestamp: number,
  langFormat: string = i18n.language
) {
  const rtf = new Intl.RelativeTimeFormat(langFormat, {
    numeric: "auto",
  });

  const daysDifference = Math.round(
    (timestamp - new Date().getTime()) / DAY_MILLISECONDS
  );

  if (daysDifference > 0) {
    return rtf.format(daysDifference, "days");
  }

  const hoursDifference = Math.round(
    (timestamp - new Date().getTime()) / HOUR_MILLISECONDS
  );

  return rtf.format(hoursDifference, "hours");
}
