import React from "react";
import { useHistory } from "react-router";

import TextInput from "components/inputs/TextInput";
import { useAnnexSections } from "./hooks/useAnnexSections";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { AnnexSection } from "./types";

export const AnnexQuestionsTab = () => {
  const history = useHistory();
  const { annexSections } = useAnnexSections();

  const [annexFilter, setAnnexFilter] = React.useState(
    new URLSearchParams(history.location.search).get("query") || ""
  );
  const [selectedAnnexSections, setSelectedAnnexSections] =
    React.useState<AnnexSection[]>();

  React.useEffect(() => {
    if (!annexSections?.length) return;
    const regexVal = annexFilter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    const filteredItems = annexSections?.filter((item) => {
      return (
        item.directive?.toLowerCase().match(regexVal) ||
        item.name?.toLowerCase().match(regexVal) ||
        item.ref_number.toLowerCase().match(regexVal)
      );
    });

    setSelectedAnnexSections(filteredItems);
  }, [annexFilter, annexSections, setSelectedAnnexSections]);

  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterQuery = event.target.value;
    const urlQuery = new URLSearchParams(history.location.search);
    urlQuery.set("query", filterQuery);

    const newSearch = {
      search: urlQuery.toString(),
    };

    history.push(newSearch);
    setAnnexFilter(filterQuery);
  };

  return (
    <>
      <h1 className="text-xl mt-10">
        Anhang 1 Fragen ({annexSections?.length || "0"})
      </h1>
      <div className="py-2">
        <PrimaryButton onClick={() => history.push(`/_backoffice/_annex/new`)}>
          Hinzufügen
        </PrimaryButton>
      </div>
      <TextInput
        defaultValue={annexFilter}
        // @ts-ignore
        onBlur={setFilter}
        placeholder="Filtern nach Referenznummer oder Anhang-Artikel, z.B. '1.3.4'"
      />

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {selectedAnnexSections
          ?.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true })
          )
          .map((section) => {
            return (
              <div
                key={section.resource_id}
                onClick={() =>
                  history.push(
                    `/_backoffice/_annex/${section.resource_id}/edit`
                  )
                }
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
              >
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    #{section.id}) {section.name}
                    <br />
                    Referenz: {section.ref_number}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Richtlinie: </b>
                    {section.directive || "Nicht zugeordnet"}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Anhang Frage: </b>
                    {section.questions?.length &&
                      section.questions?.find(
                        ({ language }) => language === "DE"
                      )?.content}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Vorgeschlagene Normen: </b>
                    {section.suggested_standards?.join(",") || "Keine"}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Übersetzungen: </b>
                    {section.questions?.map((q) => q.language).join(",") ||
                      "Keine"}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
