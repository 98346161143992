import React from "react";

import { BasePage } from "components/BasePage/BasePage";

import { useDocumentations } from "./hooks/useDocumentations";
import { useHistory } from "react-router";
import { PrimaryButton } from "components/Button/PrimaryButton";

export const DokumenationBackOffice = () => {
  const history = useHistory();
  const { documentations } = useDocumentations();

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Dokumentations Artikel",
          location: "/_backoffice/_docs",
        },
      ]}
    >
      <h1 className="text-xl">Dokumentationen</h1>
      <div className="py-2 text-right">
        <PrimaryButton onClick={() => history.push("/_backoffice/_docs/new")}>
          Hinzufügen
        </PrimaryButton>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {documentations
          ?.sort((a, b) => a.name!.localeCompare(b.name!))
          .map((doc) => {
            return (
              <div
                key={doc.resource_id}
                onClick={() =>
                  history.push(`/_backoffice/_docs/${doc.resource_id}/edit`)
                }
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500 cursor-pointer"
              >
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-m font-medium text-gray-900">{doc.name}</p>
                </div>
              </div>
            );
          })}
      </div>
    </BasePage>
  );
};
