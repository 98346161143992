import React from "react";
import { useHistory } from "react-router";

export const Login = () => {
  // Login is handled via the AuthContext.
  // This page is an escape hatch to handle expired token direct-loops
  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => {
      history.push("/dashboard");
    }, 1000);
  });

  return null;
};
