import { QuestionaireContent } from "pages/Backoffice/Questionaire/hooks";

export interface BaseQuestionaire {
  [key: string]: Question;
}

export interface Question {
  question: string;
  followups?: string[];
  annexSections?: string[];
}

export interface Annex {
  [key: string]: AnnexSection;
}

export interface AnnexSectionContent {
  id?: number;
  content: string;
  language: string;
}

export type AnnexFullText = AnnexSectionContent;

export interface AnnexSection {
  id?: number;
  resource_id?: string;
  ref_number: string;
  directive: Directive;
  is_informational: boolean;
  questions: AnnexSectionContent[];
  infos: AnnexSectionContent[];
  explanations: AnnexSectionContent[];
  fullTexts: AnnexFullText[];
  originalTexts1?: QuestionaireContent[];
  originalTexts2?: QuestionaireContent[];
  name: string;
  suggested_standards: string[];
}

export interface AnnexSectionSelectionResponse {
  projectId: string;
  annexSections: {
    annexId: number;
    isSelected: boolean;
  }[];
}

export enum Directive {
  _none = "",
  lvd = "lvd",
  emv = "emv",
  mrl = "mrl",
  mvo = "mvo",
  rohs = "rohs",
  atex = "atex",
  outdoor = "outdoor",
  pressureequip = "pressureequip",
  pressurevessel = "pressurevessel",
}
