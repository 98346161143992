import i18next from "i18next";
import { useQuery } from "react-query";

import axios from "utils/client";
import { TranslatedStandard } from "types";
import { Directive } from "pages/Backoffice/Questionaire/types";

const fetchStandardTranslations = async (
  locale: string = "de",
  directive: Directive
) => {
  const langCodeQueryParam = locale.split("-")[0].toUpperCase();

  const { data } = await axios.get<{ standards: TranslatedStandard[] }>(
    // TODO replace with env param once infrastructure for crawler & DDB is set up
    `${process.env.REACT_APP_DATA_URL}/standards?lang=${langCodeQueryParam}&dir=${directive}`
  );

  return data.standards;
};

export const useTranslatedStandards = (
  directive: Directive = Directive.mrl
) => {
  const { language } = i18next;
  const locale = language?.split("-")[0];

  const { data: translations } = useQuery(
    ["standards", locale, directive],
    () => fetchStandardTranslations(locale, directive)
  );

  return { standards: translations, translations };
};

export const useAllTranslatedStandards = () => {
  const { language } = i18next;
  const locale = language?.split("-")[0];

  const { data: translationsMrl = [] } = useQuery(
    ["standards", locale, Directive.mrl],
    () => fetchStandardTranslations(locale, Directive.mrl)
  );

  const { data: translationsLvd = [] } = useQuery(
    ["standards", locale, Directive.lvd],
    () => fetchStandardTranslations(locale, Directive.lvd)
  );

  const translations = [...translationsMrl, ...translationsLvd];

  return { standards: translations, translations };
};
