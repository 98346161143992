import React, { PropsWithChildren } from "react";
import { SecondaryButtonProps } from "types"

export const SecondaryButton: React.FunctionComponent<
  PropsWithChildren<SecondaryButtonProps>
> = (props) => {
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    props.onClick();
  };

  return (
    <button
      type="button"
      onClick={clickHandler}
      disabled={props.disabled}
      className="disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
    >
      {props.children}
    </button>
  );
};
