import * as React from "react";
import { SortableTableProps, Sorting } from "types";
import { getChevronIcon } from "components/SortableProjectsTable/Icon";

export const SortableTable = <T extends unknown>(
  props: SortableTableProps<T>
) => {
  const { data, columns, renderRow, sort } = props;
  const [sortColumn, setSortColumn] = React.useState<string | null>(null);
  const [sortOrder, setSortOrder] = React.useState<Sorting>(Sorting.asc);

  const toggleSortOrder = () => {
    if (sortOrder === Sorting.desc) {
      setSortOrder(Sorting.asc);
      setSortColumn(null);
      return;
    }
    setSortOrder(Sorting.desc);
  };

  const handleClick = (name: string) => {
    if (sortColumn !== null) {
      toggleSortOrder();
    }

    setSortColumn(
      sortColumn === name && sortOrder === Sorting.desc ? null : name
    );
    sort(sortOrder, sortColumn || "");
  };

  const getBody = () => {
    const sortedData = sort(sortOrder, sortColumn ? sortColumn : "").map(
      (row, index) => <>{renderRow(row, index)}</>
    );

    if (!sortedData || sortedData.length === 0) {
      return data.map((row, index) => <>{renderRow(row, index)}</>);
    }

    return sortedData;
  };

  return (
    <div className="hidden mt-4 pb-16 sm:block">
      <div className="align-middle inline-block min-w-full">
        <table className="shadow-md overflow-y-auto w-full table-auto">
          <thead className="w-full">
            <tr>
              {columns.map((column, index) => (
                <th
                  onClick={() => handleClick(column.name)}
                  key={column.name}
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider group cursor-pointer"
                >
                  <span
                    className={`flex items-center ${
                      column.start ? "lg:pl-2" : ""
                    }`}
                  >
                    {column.label}
                    {getChevronIcon(column.name, sortColumn || "", sortOrder)}
                  </span>
                </th>
              ))}
              <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100">
            {getBody()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
