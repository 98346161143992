import React, { PropsWithChildren, useContext } from "react";

export enum Feature {
  MultiDirectiveSearch,
  InstructionManual,
  RiskFullyMigated,
  MultiLanguage,
  MultiUser,
}

interface IFeatureFlagContext {
  isFeatureEnabled: (feature: Feature, accountId?: string) => boolean;
}

export const FeatureFlagContext = React.createContext<
  Partial<IFeatureFlagContext>
>({});

const isDev = process.env.REACT_APP_APP_HOST !== "https://app.certain-cloud.de";
const isLocal = process.env.REACT_APP_APP_HOST === "http://localhost:3000";

const staticFeatureFlags: Record<Feature, boolean> = {
  [Feature.MultiDirectiveSearch]: true,
  [Feature.InstructionManual]: isDev,
  [Feature.RiskFullyMigated]: true,
  [Feature.MultiLanguage]: true,
  [Feature.MultiUser]: true,
};

const accountFeatureFlags: Record<Feature, string[]> = {
  [Feature.MultiDirectiveSearch]: [],
  [Feature.InstructionManual]: [],
  [Feature.RiskFullyMigated]: [],
  [Feature.MultiLanguage]: [],
  [Feature.MultiUser]: [],
};

export const FeatureFlagProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const isFeatureEnabled = (feature: Feature, accountId?: string) => {
    if (accountId) {
      return (
        !!staticFeatureFlags[feature] ||
        accountFeatureFlags[feature].includes(accountId)
      );
    }

    return !!staticFeatureFlags[feature];
  };

  return (
    <FeatureFlagContext.Provider value={{ isFeatureEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider"
    );
  }

  return context! as IFeatureFlagContext;
};
