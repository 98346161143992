import React from "react";
import { AxiosInstance } from "axios";
import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";
import { ControlContext } from "context/Controls";

const deleteManufacturer = async (
  client: AxiosInstance,
  manufacturerId: string
) => {
  await client.delete(`/manufacturers/${manufacturerId}`);

  return true;
};

export const useDeleteManufacturer = (manufacturerId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate } = useMutation(
    async () => await deleteManufacturer(client, manufacturerId),
    {
      onError: (e) =>
        displayNotification!(
          "Fehler beim Löschen des Herstellers",
          (e as Error).message,
          "error"
        ),
      onSuccess: () => {
        queryClient.invalidateQueries(["manufacturers"]);
        history.push(`/manufacturers`);
        displayNotification!("Hersteller erfolgreich gelöscht", "");
      },
    }
  );

  return { deleteManufacturer: mutate };
};
