import i18n from "i18n";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Status } from "types";
import { Project } from "types";
import { Options } from "./RowOptions";
import { getRelativeTime } from "utils/date";

export const ProjectRow: React.FunctionComponent<Project> = (props) => {
  const history = useHistory();
  const { projectdata, manufacturer } = props;
  const [showOptions, setShowOptions] = React.useState(false);
  const { t } = useTranslation();

  return (
    <tr
      className={
        projectdata.status === Status.archived ? "bg-gray-100" : "bg-white"
      }
    >
      <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center space-x-3 lg:pl-2">
          <div
            className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-cyan-600"
            aria-hidden="true"
          ></div>
          <span
            className={
              "truncate cursor-pointer " +
              (projectdata.status === Status.archived
                ? "text-gray-600 hover:text-gray-900"
                : "hover:text-gray-600")
            }
            onClick={() => history.push(`/projects/${projectdata.crn}`)}
          >
            <span>{projectdata.product_name || projectdata.id}</span>
          </span>
        </div>
      </td>
      <td className="px-6 py-3 text-sm text-gray-500">
        {manufacturer.company}
      </td>
      <td className="px-6 py-3 text-sm text-gray-500">
        {!projectdata.category
          ? ""
          : t(`common.selector.product_category.${projectdata.category}`)}
      </td>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500">
        {projectdata.version !== 0
          ? t("dashboard.table.status.done")
          : t("dashboard.table.status.wip")}
      </td>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500">
        {getRelativeTime(new Date(props.lastUpdated).getTime())}
      </td>
      <td className="pr-6">
        <div className="relative flex justify-end items-center">
          <button
            type="button"
            className="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            id="project-options-menu-0"
            aria-expanded="false"
            aria-haspopup="true"
            onClick={() => setShowOptions(!showOptions)}
          >
            <span className="sr-only">Open options</span>
            {/* <!-- Heroicon name: solid/dots-vertical --> */}
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>

          {/* <!-- */}
          {/* Dropdown menu, show/hide based on menu state.

                      Entering: "transition ease-out duration-100"
                        From: "transform opacity-0 scale-95"
                        To: "transform opacity-100 scale-100"
                      Leaving: "transition ease-in duration-75"
                        From: "transform opacity-100 scale-100"
                        To: "transform opacity-0 scale-95"
                    --> */}
          {showOptions && (
            <Options onClose={() => setShowOptions(false)} project={props} />
          )}
        </div>
      </td>
    </tr>
  );
};
