import { Location } from "history";

export const setUnsavedChanges = (location: Location, hasChanges: boolean) => {
  localStorage.setItem(
    `certain.unsavedChanges.${location.pathname.replaceAll("/", ".")}`,
    hasChanges.toString()
  );
};

export const hasUnsavedChanges = (location: Location) => {
  const lsKey = localStorage.getItem(
    `certain.unsavedChanges.${location.pathname.replaceAll("/", ".")}`
  );

  //   default to true to indicate that there might be unsaved changes
  if (!lsKey) {
    return true;
  }

  try {
    return JSON.parse(lsKey);
  } catch (error) {
    return true;
  }
};
