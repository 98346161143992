import { BasePage } from "components/BasePage/BasePage";
import { PlanSelection } from "pages/Backoffice/TEST/PlanSelection";

export const Checkout = () => {
  return (
    <BasePage
      breadcrumbItems={[
        { label: "Einstellungen", location: "/settings" },
        {
          label: "Abo-Auswahl",
          location: "/checkout",
        },
      ]}
    >
      <PlanSelection />
    </BasePage>
  );
};

export default Checkout;
