import { FormFieldType } from "@slootsantos/certain-forms/dist/types";

import TextInput from "components/inputs/TextInput";
import DateInput from "components/inputs/DateInput";
import RadioInput from "components/inputs/RadioInput";
import CheckInput from "components/inputs/CheckInput";
import TextArea from "components/inputs/TextAreaInput";
import SelectInput from "components/inputs/SelectInput";
import LongTextInput from "components/inputs/LongTextInput";
import FullTextInput from "components/inputs/FullTextInput";
import { Piktograms } from "components/Piktograms/Piktograms";
import ShortTextInput from "components/inputs/ShortTextInput";
import MultiSelectInput from "components/inputs/MultiSelectInput";
import CountrySelectInput from "components/inputs/CountrySelectInput";
import StandardsSelectInput from "components/inputs/StandardsSelectInput";
import ManufacturerSelectInput from "components/inputs/ManufacturerSelectInput";

export const formFieldMap = {
  [FormFieldType.area]: TextArea,
  [FormFieldType.date]: DateInput,
  [FormFieldType.input]: TextInput,
  [FormFieldType.radio]: RadioInput,
  [FormFieldType.picto]: Piktograms,
  [FormFieldType.check]: CheckInput,
  [FormFieldType.long]: LongTextInput,
  [FormFieldType.full]: FullTextInput,
  [FormFieldType.select]: SelectInput,
  [FormFieldType.short]: ShortTextInput,
  [FormFieldType.upload]: ShortTextInput,
  [FormFieldType.multiSelect]: MultiSelectInput,
  [FormFieldType.countrySelect]: CountrySelectInput,
  [FormFieldType.standardsSelect]: StandardsSelectInput,
  [FormFieldType.manufacturerSelect]: ManufacturerSelectInput,
  [FormFieldType.informational]: () => null,
};
