import React, { useState, useRef, useEffect, ReactNode } from "react";
import { Editor, Element as SlateElement, Transforms, Node, Path } from "slate";
import { useSlate } from "slate-react";
import {
  CustomEditor,
  FormatMark,
  MessageBoxType,
  HeadingLevel,
  CustomElement,
  TableElement,
  TableRowElement,
  TableCellElement,
  ListType,
  ImageElement,
} from "components/ManualBuilder/types";

export const Toolbar = () => {
  return (
    <div className="border-b border-gray-200 p-2 flex items-center gap-1">
      <div className="flex items-center gap-1 border-r border-gray-200 pr-2 mr-2">
        <MarkButton format="bold" icon="B" />
        <MarkButton format="italic" icon="I" />
        <MarkButton format="underline" icon="U" />
      </div>
      <div className="flex items-center gap-1 border-r border-gray-200 pr-2 mr-2">
        <HeadingButton level={1} icon="H1" />
        <HeadingButton level={2} icon="H2" />
        <HeadingButton level={3} icon="H3" />
      </div>
      <div className="flex items-center gap-1 border-r border-gray-200 pr-2 mr-2">
        <ListButton format="bulleted" icon="bullet" />
        <ListButton format="numbered" icon="list" />
      </div>
      <div className="flex items-center gap-1 border-r border-gray-200 pr-2 mr-2">
        <TableControls />
      </div>
      <div className="flex items-center gap-1 border-r border-gray-200 pr-2 mr-2">
        <ImageButton />
      </div>
      <div className="flex items-center gap-1">
        <MessageBoxButton format="warning" />
        <MessageBoxButton format="info" />
        <MessageBoxButton format="danger" />
      </div>
    </div>
  );
};

const ImageButton = () => {
  const editor = useSlate();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target?.result as string;
        insertImage(editor, url);
      };
      reader.readAsDataURL(file);
    }
  };

  const insertImage = (editor: CustomEditor, url: string) => {
    const image: ImageElement = {
      type: "image",
      url,
      children: [{ text: "" }],
    };
    Transforms.insertNodes(editor, image);
  };

  return (
    <>
      <button
        type="button"
        className="p-2 text-gray-600 hover:bg-gray-100 rounded"
        onMouseDown={(event) => {
          event.preventDefault();
          inputRef.current?.click();
        }}
        title="Insert Image"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
          <path
            fillRule="evenodd"
            d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <input
        type="file"
        ref={inputRef}
        onChange={handleImageUpload}
        accept="image/*"
        className="hidden"
      />
    </>
  );
};

const ListButton = ({
  format,
  icon,
}: {
  format: ListType;
  icon: "bullet" | "list";
}) => {
  const editor = useSlate();

  const isActiveList = () => {
    const [match] = Editor.nodes(editor, {
      match: (n) =>
        SlateElement.isElement(n) && n.type === "list" && n.format === format,
    });
    return !!match;
  };

  const toggleList = () => {
    const isActive = isActiveList();

    if (isActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) && n.type === "list" && n.format === format,
        split: true,
      });
      Transforms.setNodes(
        editor,
        { type: "paragraph" } as Partial<CustomElement>,
        {
          match: (n) => SlateElement.isElement(n) && n.type === "list-item",
        }
      );
    } else {
      // Unwrap any existing lists first
      Transforms.unwrapNodes(editor, {
        match: (n) => SlateElement.isElement(n) && n.type === "list",
        split: true,
      });

      // Convert current nodes to list items
      Transforms.setNodes(
        editor,
        { type: "list-item" } as Partial<CustomElement>,
        {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        }
      );

      // Wrap in list
      Transforms.wrapNodes(editor, {
        type: "list",
        format,
        children: [],
      } as CustomElement);
    }
  };

  return (
    <button
      type="button"
      className={`p-2 ${
        isActiveList() ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleList();
      }}
    >
      {icon === "bullet" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            fillRule="evenodd"
            d="M2.625 6.75a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0A.75.75 0 0 1 8.25 6h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.625 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 12Zm-4.875 5.25a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            fillRule="evenodd"
            d="M7.491 5.992a.75.75 0 0 1 .75-.75h12a.75.75 0 1 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM7.49 11.995a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM7.491 17.994a.75.75 0 0 1 .75-.75h12a.75.75 0 1 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.24 3.745a.75.75 0 0 1 .75-.75h1.125a.75.75 0 0 1 .75.75v3h.375a.75.75 0 0 1 0 1.5H2.99a.75.75 0 0 1 0-1.5h.375v-2.25H2.99a.75.75 0 0 1-.75-.75ZM2.79 10.602a.75.75 0 0 1 0-1.06 1.875 1.875 0 1 1 2.652 2.651l-.55.55h.35a.75.75 0 0 1 0 1.5h-2.16a.75.75 0 0 1-.53-1.281l1.83-1.83a.375.375 0 0 0-.53-.53.75.75 0 0 1-1.062 0ZM2.24 15.745a.75.75 0 0 1 .75-.75h1.125a1.875 1.875 0 0 1 1.501 2.999 1.875 1.875 0 0 1-1.501 3H2.99a.75.75 0 0 1 0-1.501h1.125a.375.375 0 0 0 .036-.748H3.74a.75.75 0 0 1-.75-.75v-.002a.75.75 0 0 1 .75-.75h.411a.375.375 0 0 0-.036-.748H2.99a.75.75 0 0 1-.75-.75Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </button>
  );
};

const TableControls = () => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const editor = useSlate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        event.target instanceof HTMLElement &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const isInTable = () => {
    const [match] = Editor.nodes(editor, {
      match: (n) =>
        SlateElement.isElement(n) && "type" in n && n.type === "table",
    });
    return !!match;
  };

  const insertTable = () => {
    const table: TableElement = {
      type: "table",
      children: [
        {
          type: "table-row",
          children: [
            {
              type: "table-cell",
              isHeader: true,
              children: [{ text: "Header 1" }],
            },
            {
              type: "table-cell",
              isHeader: true,
              children: [{ text: "Header 2" }],
            },
            {
              type: "table-cell",
              isHeader: true,
              children: [{ text: "Header 3" }],
            },
          ],
        },
        {
          type: "table-row",
          children: [
            { type: "table-cell", children: [{ text: "Cell 1" }] },
            { type: "table-cell", children: [{ text: "Cell 2" }] },
            { type: "table-cell", children: [{ text: "Cell 3" }] },
          ],
        },
      ],
    };

    Transforms.insertNodes(editor, table);
  };

  const getCurrentTable = () => {
    for (const [node, path] of Editor.nodes(editor, {
      match: (n): n is TableElement =>
        SlateElement.isElement(n) && n.type === "table",
    })) {
      return [node, path] as [TableElement, Path];
    }
    return undefined;
  };

  const insertRow = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      const rowCount = table.children.length;
      const columnCount = (table.children[0] as TableRowElement).children
        .length;

      const newRow: TableRowElement = {
        type: "table-row",
        children: Array(columnCount)
          .fill(0)
          .map(() => ({
            type: "table-cell",
            children: [{ text: "" }],
          })),
      };

      Transforms.insertNodes(editor, newRow, {
        at: [...tablePath, rowCount],
      });
    }
  };

  const removeRow = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table] = tableEntry;
      if (table.children.length > 1) {
        const [, currentPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === "table-row",
          }) || [];

        if (currentPath) {
          Transforms.removeNodes(editor, { at: currentPath });
        }
      }
    }
  };

  const insertColumn = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      table.children.forEach((row, rowIndex) => {
        const isHeader = rowIndex === 0;
        const newCell: TableCellElement = {
          type: "table-cell",
          isHeader,
          children: [{ text: "" }],
        };

        Transforms.insertNodes(editor, newCell, {
          at: [
            ...tablePath,
            rowIndex,
            (row as TableRowElement).children.length,
          ],
        });
      });
    }
  };

  const removeColumn = () => {
    const tableEntry = getCurrentTable();
    if (tableEntry) {
      const [table, tablePath] = tableEntry;
      const firstRow = table.children[0] as TableRowElement;
      if (firstRow.children.length > 1) {
        const [, cellPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n) && n.type === "table-cell",
          }) || [];

        if (cellPath) {
          const columnIndex = cellPath[cellPath.length - 1];
          table.children.forEach((_, rowIndex) => {
            Transforms.removeNodes(editor, {
              at: [...tablePath, rowIndex, columnIndex],
            });
          });
        }
      }
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        type="button"
        className={`p-2 ${
          isInTable() ? "text-blue-500" : "text-gray-600"
        } hover:bg-gray-100 rounded`}
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          if (!isInTable()) {
            insertTable();
          } else {
            setShowMenu(!showMenu);
          }
        }}
        title={isInTable() ? "Table Options" : "Insert Table"}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path
            fillRule="evenodd"
            d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 18.375V5.625ZM21 9.375A.375.375 0 0 0 20.625 9h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5ZM10.875 18.75a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5ZM3.375 15h7.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375Zm0-3.75h7.5a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 10.875 9h-7.5A.375.375 0 0 0 3 9.375v1.5c0 .207.168.375.375.375Z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {showMenu && isInTable() && (
        <div className="absolute left-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1" role="menu">
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                insertRow();
                setShowMenu(false);
              }}
            >
              Add Row
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                removeRow();
                setShowMenu(false);
              }}
            >
              Remove Row
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                insertColumn();
                setShowMenu(false);
              }}
            >
              Add Column
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onMouseDown={(e) => {
                e.preventDefault();
                removeColumn();
                setShowMenu(false);
              }}
            >
              Remove Column
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const HeadingButton = ({
  level,
  icon,
}: {
  level: HeadingLevel;
  icon: string;
}) => {
  const editor = useSlate();

  const isActiveHeading = () => {
    const matches = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) &&
          "type" in n &&
          n.type === "heading" &&
          "level" in n &&
          n.level === level,
      })
    );
    return matches.length > 0;
  };

  return (
    <button
      type="button"
      className={`p-2 ${
        isActiveHeading() ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        const isActive = isActiveHeading();

        Transforms.setNodes(
          editor,
          {
            type: isActive ? "paragraph" : "heading",
            level: isActive ? undefined : level,
          } as Partial<CustomElement>,
          {
            match: (n) =>
              SlateElement.isElement(n) && Editor.isBlock(editor, n),
          }
        );
      }}
    >
      {icon}
    </button>
  );
};

const MessageBoxButton = ({ format }: { format: MessageBoxType }) => {
  const editor = useSlate();

  const isActiveMessageBox = () => {
    const matches = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          SlateElement.isElement(n) &&
          "type" in n &&
          n.type === "message-box" &&
          "boxType" in n &&
          n.boxType === format,
      })
    );
    return matches.length > 0;
  };

  const icons: Record<MessageBoxType, ReactNode> = {
    warning: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-yellow-600"
      >
        <path
          fillRule="evenodd"
          d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    info: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-blue-600"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    danger: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5 text-red-600"
      >
        <path
          fillRule="evenodd"
          d="M11.484 2.17a.75.75 0 0 1 1.032 0 11.209 11.209 0 0 0 7.877 3.08.75.75 0 0 1 .722.515 12.74 12.74 0 0 1 .635 3.985c0 5.942-4.064 10.933-9.563 12.348a.749.749 0 0 1-.374 0C6.314 20.683 2.25 15.692 2.25 9.75c0-1.39.223-2.73.635-3.985a.75.75 0 0 1 .722-.516l.143.001c2.996 0 5.718-1.17 7.734-3.08ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 15a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75H12Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  };

  return (
    <button
      type="button"
      className={`p-2 ${
        isActiveMessageBox() ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        const isActive = isActiveMessageBox();

        if (isActive) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              SlateElement.isElement(n) &&
              "type" in n &&
              n.type === "message-box",
            split: true,
          });
        } else {
          const messageBox = {
            type: "message-box",
            boxType: format,
            children: [
              {
                type: "title",
                children: [{ text: "Title" }],
              },
              {
                type: "content",
                children: [{ text: "Content" }],
              },
            ],
          } as CustomElement;

          Transforms.insertNodes(editor, messageBox);
        }
      }}
    >
      {icons[format]}
    </button>
  );
};

const MarkButton = ({ format, icon }: { format: FormatMark; icon: string }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);

  return (
    <button
      type="button"
      className={`p-2 ${
        isActive ? "text-blue-500" : "text-gray-600"
      } hover:bg-gray-100 rounded`}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </button>
  );
};

export const isMarkActive = (editor: CustomEditor, format: FormatMark) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: CustomEditor, format: FormatMark) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
