import { ReactElement, useState } from "react";
import { classNames } from "utils/classNames";
import { QuestionaireItemTab } from "./QuestionaireItemTab";
import { AnnexQuestionsTab } from "./AnnexQuestionsTab";
import { useHistory, useLocation } from "react-router";

enum TabName {
  QuestionaireItems = "questionaire",
  AnnexQuestions = "annex",
}

interface Tab {
  name: string;
  tabName: TabName;
  content: () => ReactElement;
}

const tabs: Record<TabName, Tab> = {
  [TabName.QuestionaireItems]: {
    name: "Questionaire-Items",
    tabName: TabName.QuestionaireItems,
    content: () => <QuestionaireItemTab />,
  },
  [TabName.AnnexQuestions]: {
    name: "Anhang-Fragen",
    tabName: TabName.AnnexQuestions,
    content: () => <AnnexQuestionsTab />,
  },
};
const QuestionaireTabsHeader = (props: {
  currentTab: Tab;
  tabs: Tab[];
  onChange: (tab: Tab) => void;
}) => {
  const changeHandler = (tab: Tab) => {
    props.onChange(tab);
  };

  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200 mb-10">
        <div className="mx-auto">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <span
                onClick={() => changeHandler(tab)}
                key={tab.name}
                className={classNames(
                  props.currentTab?.name === tab.name
                    ? "border-cyan-600 text-gray-900"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface TabProps {}

export const QuestionaireTabs = (props: TabProps) => {
  const history = useHistory();
  const routeLocation = useLocation();

  const urlQuery = new URLSearchParams(routeLocation.search);
  const tabName = (urlQuery.get("tab") || TabName.QuestionaireItems) as TabName;

  const [currentTab, setCurrentTab] = useState<Tab>(tabs[tabName]);

  const updateTab = (tab: Tab) => {
    urlQuery.set("tab", tab.tabName);

    const newSearch = {
      search: urlQuery.toString(),
    };

    history.push(newSearch);

    setCurrentTab(tab);
  };

  return (
    <div>
      <QuestionaireTabsHeader
        tabs={Object.values(tabs)}
        currentTab={currentTab}
        onChange={updateTab}
      />
      {currentTab.content()}
    </div>
  );
};
