import i18n from "i18n";
import parse from "html-react-parser";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { TFunction, Trans, getI18n, useTranslation } from "react-i18next";

import {
  QuestionaireItem,
  useQuestionaireItems,
  useQuestionaireItemsForProject,
} from "pages/Backoffice/Questionaire/hooks";
import {
  Form as FormType,
  FormFieldType,
  Certification as FormCertfication,
  FormField,
} from "@slootsantos/certain-forms/dist/types";
import {
  useAnnexSections,
  useAnnexSectionsForProject,
} from "pages/Backoffice/Questionaire/hooks/useAnnexSections";

import Form from "forms/Form";
import { useClient } from "utils/client";
import { Modal } from "components/Modal/Modal";
import { FeedbackContext } from "context/Feedback";
import { TrackingContext } from "context/Tracking";
import { Project, TranslatedStandard } from "types";
import { Loading } from "components/Loading/Loading";
import { useTranslatedStandards } from "hooks/useStandards";
import Collapsible from "components/Collapsible/Collapsible";
import { CollapsibleProps } from "components/Collapsible/types";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { AnnexSection, Directive } from "pages/Backoffice/Questionaire/types";
import { Category } from "components/common/ProductCategorySelector";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import { ControlContext } from "context/Controls";
import { LvdResearch } from "components/DirectiveSearch/LvdResearch";
import { uniqueArrayByProperty } from "components/DirectiveSearch/util/baseHelpers";

interface BaseInformationProps {
  type: "ce" | "ukCa";
}
interface FormFieldWithCollapsible extends FormField {
  collapsibleItems?: CollapsibleProps[];
}

interface AnnexSectionWithCollapsible extends AnnexSection {
  collapsibleItems?: CollapsibleProps[];
}

const getExitQuestions = (
  questionaireItems: QuestionaireItem[],
  directive: Directive = Directive._none
) => {
  return questionaireItems
    .filter(
      (q) =>
        q.is_exit_question &&
        // TEMP: Join MRL & non-classified
        (q.directive === Directive._none ||
          q.directive === Directive.mrl ||
          q.directive === directive)
    )
    .sort((a, b) => a.ref_number.localeCompare(b.ref_number));
};

const getFirstLevelQuestions = (
  questionaireItems: QuestionaireItem[],
  directive: Directive = Directive._none
) => {
  return questionaireItems
    .filter(
      (q) =>
        q.is_first_level &&
        // TEMP: Join MRL & non-classified
        (q.directive === Directive._none ||
          q.directive === Directive.mrl ||
          q.directive === directive)
    )
    .sort((a, b) => a.ref_number.localeCompare(b.ref_number));
};

const getQuestionName = (questionaireItem: QuestionaireItem | AnnexSection) =>
  questionaireItem.resource_id!.toString() + "field";

const getQuestionLabel = (
  questionaireItem: QuestionaireItem,
  language = "DE"
) =>
  questionaireItem.questions?.find((question) => question.language === language)
    ?.content || "";

const getAnnexLabel = (questionaireItem: AnnexSection, language = "DE") =>
  `${questionaireItem.name}) ` +
    questionaireItem.questions?.find(
      (question) => question.language === language
    )?.content || "";

const getQuestionExplanation = (
  questionaireItem: QuestionaireItem,
  language = "DE"
) => {
  const explanation =
    questionaireItem.explanations?.find(
      (explanations) => explanations.language === language
    )?.content || "";

  return explanation === "<p><br></p>" ? "" : explanation;
};

const getAnnexExplanation = (annexItem: AnnexSection, language: string) => {
  const explanation =
    annexItem.explanations?.find(
      (explanation) => explanation.language === language
    )?.content || "";

  return explanation === "<p><br></p>" ? "" : explanation;
};

const getAnnexInfo = (annexItem: AnnexSection, language: string) =>
  annexItem.infos?.find((explanation) => explanation.language === language)
    ?.content || "";

const getOriginalText1 = (item: QuestionaireItem, language: string) => {
  const content =
    item.originalTexts1?.find((org) => org.language === language)?.content ||
    "";

  return content === "<p><br></p>" ? "" : content;
};

const getOriginalText2 = (item: QuestionaireItem, language: string) => {
  const content =
    item.originalTexts2?.find((org) => org.language === language)?.content ||
    "";

  return content === "<p><br></p>" ? "" : content;
};

const getAnnexOriginalText = (annex: AnnexSection, language: string) => {
  const content =
    annex.fullTexts?.find((text) => text.language === language)?.content || "";

  return content === "<p><br></p>" ? "" : content;
};

const getTranslation = (text: string) => {
  return getI18n().t(text);
};

const getCollabsibleItems = (
  explanation: string,
  originalTexts1: string,
  originalTexts2?: string
): CollapsibleProps[] => {
  let arr: CollapsibleProps[] = [];
  if (explanation.length > 0) {
    arr.push({
      label: getTranslation("steps.questionaire.explanation"),
      child: <div className="whitespace-pre-line">{parse(explanation)}</div>,
    });
  }

  if (originalTexts1.length > 0) {
    arr.push({
      label: getTranslation("steps.questionaire.original_text"),
      child: (
        <div className="whitespace-pre-line">
          <>{parse(originalTexts1)}</>
          {originalTexts2 !== undefined && originalTexts2.length > 0 ? (
            <>
              <br />
              <>{parse(originalTexts2)}</>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    });
  }

  return arr;
};

const buildQuestionFields = (
  questionaireItems: QuestionaireItem[],
  language = "DE"
): FormFieldWithCollapsible[] => {
  return questionaireItems.map((q, idx) => ({
    name: getQuestionName(q),
    label: getQuestionLabel(q, language),
    explanation: getQuestionExplanation(q, language),
    type: FormFieldType.radio,
    collapsibleItems: getCollabsibleItems(
      getQuestionExplanation(q, language),
      getOriginalText1(q, language),
      getOriginalText2(q, language)
    ),
    groupName: (idx % 5).toString(),
    required: true,
    options: [
      { label: "Ja", value: "1" },
      { label: "Nein", value: "0" },
    ],
  }));
};

interface AnnexSectionWithCollapsible extends AnnexSection {
  collapsibleItems?: CollapsibleProps[];
}

const buildAnnexFields = (
  annexSections: AnnexSectionWithCollapsible[],
  language = "DE",
  groups: AnnexSection[],
  translator: TFunction
): FormFieldWithCollapsible[] => {
  const revGroups = groups.reverse();

  return annexSections.map((annex) => {
    return {
      name: getQuestionName(annex),
      label: getAnnexLabel(annex, language),
      explanation: getAnnexInfo(annex, language),
      type: annex.is_informational
        ? FormFieldType.informational
        : FormFieldType.check,
      customRenderOverride: () => {
        const annexExplanation = getAnnexExplanation(annex, language);
        const annexOriginalText = getAnnexOriginalText(annex, language);

        return (
          <div className="col-span-12">
            <span className="text-lg">
              {annex.name}{" "}
              {annex.questions.find((q) => q.language === language)?.content}
            </span>
            {annexExplanation && (
              <Collapsible
                label={translator("steps.questionaire.explanation")}
                child={<p>{parse(annexExplanation)}</p>}
              />
            )}
            {annexOriginalText && (
              <Collapsible
                label={translator("steps.questionaire.original_text")}
                child={<p>{parse(annexOriginalText)}</p>}
              />
            )}
          </div>
        );
      },
      collapsibleItems: getCollabsibleItems(
        getAnnexExplanation(annex, language),
        getAnnexOriginalText(annex, language)
      ),
      groupName: revGroups.find((g) => annex.name.match(`^${g.name}`))?.name,
    };
  });
};

interface GroupTranslatedStandard {
  standards: TranslatedStandard[];
  relatedSection: AnnexSection;
}

const buildStandardFields = (standardsGroupping: GroupTranslatedStandard[]) => {
  return standardsGroupping.flatMap((group) => {
    const flatStandards =
      group.standards?.map((st) => {
        const standardWithVersion = [st.name, st.latest].join(":");

        return {
          name: st.name,
          label: `[${standardWithVersion}] ${st.content}`,
          type: FormFieldType.check,
          groupName: group.relatedSection.name,
        };
      }) || [];

    return flatStandards;
  });
};
interface DirectiveSearchRouteParams {
  projectId: string;
}

const directiveToResearch: Partial<Record<Directive, () => JSX.Element>> = {
  [Directive.lvd]: LvdResearch,
  [Directive._none]: LvdResearch,
};

const BaseInformation = (props: BaseInformationProps) => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const langCode = i18n.language.split("-")[0].toUpperCase();
  const { directive = Directive.mrl } = useParams<{ directive: Directive }>();

  const { track } = React.useContext(TrackingContext);
  const { displayFeedback } = React.useContext(FeedbackContext);
  const { displayNotification } = React.useContext(ControlContext);

  const { projectId } = useParams<DirectiveSearchRouteParams>();
  const { project, fetchInProgress } = useProjectDetails(projectId);

  const { annexSections } = useAnnexSections();
  const [fd, setFd] = React.useState<any>(null);
  const [preloaded, setPreloaded] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState<any>({});
  const questionaireItemsUnsorted = useQuestionaireItems();
  const [confirmedAnnexIV, setConfirmedAnnexIV] = React.useState(false);

  const {
    annexSections: annexSectionsProject,
    isLoading: annexSectionLoading,
  } = useAnnexSectionsForProject(projectId);

  const {
    questionaireItems: questionaireItemsProject,
    isLoading: questionaireLoading,
  } = useQuestionaireItemsForProject(projectId);

  const questionaireItems = questionaireItemsUnsorted?.sort((a, b) =>
    a.ref_number?.localeCompare(b.ref_number)
  );
  const standardsProject = project?.standards;
  const { translations } = useTranslatedStandards();

  const [isExitModalVisible, setIsExitModalVisible] = React.useState(false);
  const [exitModalContent, setExitModalContent] =
    React.useState<QuestionaireItem>();

  const [isCategoryModalVisible, setIsCategoryModalVisible] =
    React.useState(false);
  const [categoryModalContent, setCategoryModalContent] =
    React.useState<QuestionaireItem[]>();

  React.useEffect(() => {
    if (!questionaireItems?.length) return;

    // TEMP: Fallback to MRL right now until all projects have a directive
    const translationDirective =
      directive === undefined || directive === Directive._none
        ? "mrl"
        : directive;
    const exitQuestions = getExitQuestions(questionaireItems, directive);
    const formD: FormType = {
      type: FormCertfication.ce,
      documents: [],
      directives: [],
      sections: [
        {
          label: t(`form.${translationDirective}.exitQuestions.label`),
          name: "exitQuestions",
          info: t(`form.${translationDirective}.exitQuestions.info`),
          subline: t(`form.${translationDirective}.exitQuestions.subline`),
          fields: buildQuestionFields(exitQuestions, langCode),
          isGroup: false,
        },
        {
          label: t(`form.${translationDirective}.category.label`),
          name: "category",
          info: t(`form.${translationDirective}.category.info`),
          subline: t(`form.${translationDirective}.category.subline`),
          // isExclusive: true,
          fields: [],
        },
        {
          label: t(`form.${translationDirective}.Anhang1.label`),
          name: "Anhang1",
          subline: t(""),
          info: t(`form.${translationDirective}.Anhang1.info`),
          fields: [],
        },
        {
          label: t(`form.${translationDirective}.Anhang1Relevante.label`),
          name: "Anhang1Relevante",
          subline: t(""),
          info: t(`form.${translationDirective}.Anhang1Relevante.info`),
          fields: [],
        },

        {
          label: t(`form.${translationDirective}.Normenauswahl.label`),
          name: "Normenauswahl",
          info: t(`form.${translationDirective}.Normenauswahl.info`),
          subline: t(`form.${translationDirective}.Normenauswahl.subline`),
          fields: [],
        },
      ],
    };

    setFd(formD);
  }, [questionaireItems, langCode, t, directive]);

  const handleSave = React.useCallback(
    async (form: any, isManualSubmit?: boolean) => {
      const annexIvCategoryQuestionaireId = questionaireItems.find(
        ({ product_category }) => product_category === "annex_iv"
      )?.resource_id;

      const categoryResponses = form.category
        ? Object.keys(form.category)
            .filter((categoryKey) => form?.category[categoryKey] !== "")
            .map((categoryKey) => {
              const id = categoryKey.split("field")[0];

              return {
                id,
                isSelected: Boolean(parseInt(form.category[categoryKey])),
              };
            })
        : [];

      const allSelectedCategories = categoryResponses.filter(
        ({ isSelected }) => isSelected
      );

      const categoryResponse = allSelectedCategories
        .filter(
          ({ id }) =>
            id !== annexIvCategoryQuestionaireId ||
            allSelectedCategories.length === 1
        )
        .find(Boolean);

      const selectedCategory =
        questionaireItems.find(
          ({ resource_id }) => resource_id === categoryResponse?.id
        )?.product_category || Category.none;

      const s = form.Normenauswahl
        ? Object.keys(form.Normenauswahl)
            .filter((norm) => Boolean(parseInt(form.Normenauswahl[norm])))
            .map((n) => {
              const completeStandards = translations?.find(
                (tr) => tr.name === n
              );

              return [n, completeStandards?.latest].join(":");
            })
        : [];

      await client.put(`/projects/${projectId}`, {
        payload: {
          ...project,
          standards: s,
          directives: project!.directives.map((d) => {
            if (d.name === directive) {
              return {
                ...d,
                completed_research: true,
              };
            }

            return d;
          }),
          projectdata: {
            ...project!.projectdata,
            category: selectedCategory,
            ...(isManualSubmit ? { completed_toplevel_research: true } : {}),
          },
        } as Project,
      });

      const exitResponses = form?.exitQuestions
        ? Object.keys(form.exitQuestions)
            .filter((exitKey) => form?.exitQuestions[exitKey] !== "")
            .map((exitKey) => {
              const id = exitKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(form.exitQuestions[exitKey])),
              };
            })
        : [];

      const anhangResponses = form.Anhang1
        ? Object.keys(form.Anhang1)
            .filter((anhangKey) => form?.Anhang1[anhangKey] !== "")
            .map((anhangKey) => {
              const id = anhangKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(form.Anhang1[anhangKey])),
              };
            })
        : [];

      const questionaireIds = [
        // Keep the order of the questions based on their order in the form!!
        ...exitResponses,
        ...categoryResponses,
        ...anhangResponses,
      ].map(({ id, isSelected, ...r }) => ({
        questionaireId: id,
        isSelected: isSelected,
      }));

      const annexIds = form.Anhang1Relevante
        ? Object.keys(form.Anhang1Relevante)
            .map((anhangKey) => {
              const id = anhangKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(form.Anhang1Relevante[anhangKey])),
              };
            })
            .map(({ id, isSelected }) => ({ annexId: id, isSelected }))
        : [];

      const uniqueQuestionaires = uniqueArrayByProperty(
        [
          ...questionaireIds,
          ...(questionaireItemsProject?.questionaires || []),
        ],
        "questionaireId"
      );

      await client.post(
        `/projects/${projectId}/questionaireItems`,
        uniqueQuestionaires
      );
      await client.post(`/projects/${projectId}/annexSections`, annexIds);

      queryClient.refetchQueries(["projects", projectId]);
      queryClient.refetchQueries(["questionaireItems", projectId]);
      queryClient.refetchQueries(["annexSections", projectId]);

      displayNotification!(
        "Erfolgreich gespeichert!",
        "Ihre Recherchefortschritt wurde gespeichert"
      );
    },
    [
      client,
      project,
      projectId,
      queryClient,
      translations,
      questionaireItems,
      displayNotification,
      questionaireItemsProject,
    ]
  );

  const handleSubmit = useCallback(
    async (form: any) => {
      await handleSave(form, true);

      track("finish questionaire");

      displayFeedback!(FeedbackLocation.Questionaire);
      history.push(`/projects/${projectId}?saved=true`);
    },
    [displayFeedback, history, projectId, track, handleSave]
  );

  const handleStepCallback = React.useCallback(
    (v: any, currentStep: number, _: any, isPreload?: boolean) => {
      if (!fd?.sections?.length || !translations?.length || !v.exitQuestions)
        return;
      const shouldExit = Object.values(v.exitQuestions).some(
        (value) => value === "1"
      );

      const exitQuestion = Object.keys(v.exitQuestions)
        ?.filter((key) => v.exitQuestions[key] === "1")
        ?.map((key) => key.split("field")[0])
        ?.map((keyId) =>
          questionaireItems.find(({ resource_id }) => resource_id === keyId)
        );

      if (shouldExit) {
        const preventNextStepFromCallback = true;

        setIsExitModalVisible(true);
        setExitModalContent(exitQuestion[0]);

        return preventNextStepFromCallback;
      }

      fd.sections[1].fields = [
        ...buildQuestionFields(
          getFirstLevelQuestions(questionaireItems, directive),
          langCode
        ),
      ];

      !isPreload &&
        currentStep === 0 &&
        displayFeedback!(FeedbackLocation.Questionaire1);

      if (!v.category) {
        return;
      }

      const appliedIds = Object.keys(v.category).filter((qId) =>
        parseInt(v.category[qId])
      );

      const appliedQuestions = questionaireItems.filter((q) =>
        appliedIds.includes(q.resource_id!.toString() + "field")
      );
      function onlyUnique(
        value: number | string,
        index: number | string,
        self: (number | string)[]
      ) {
        return self.indexOf(value) === index;
      }

      const followupIds = appliedQuestions.flatMap((aq) => aq.followups);
      const followups = followupIds
        .filter(onlyUnique)
        .map((id) => questionaireItems.find((qi) => qi.resource_id === id)!)
        .filter(Boolean);

      fd.sections[2].fields = [...buildQuestionFields(followups, langCode)];

      const selectedExactlyOne = appliedQuestions.length === 1;
      const isAnnexIVSelected = appliedQuestions.find(
        ({ product_category }) => product_category === "annex_iv"
      );

      const shouldPopUpForCategory = !selectedExactlyOne || isAnnexIVSelected;

      if (
        !isPreload &&
        currentStep === 1 &&
        shouldPopUpForCategory &&
        !confirmedAnnexIV
      ) {
        const preventNextStepFromCallback = true;

        setIsCategoryModalVisible(true);
        setCategoryModalContent(appliedQuestions);

        return preventNextStepFromCallback;
      }

      !isPreload &&
        currentStep === 1 &&
        displayFeedback!(FeedbackLocation.Questionaire2);
      if (!v.Anhang1) {
        return;
      }

      const appliedIdsForAnnex = Object.keys(v.Anhang1).filter((qId) =>
        parseInt(v.Anhang1[qId])
      );

      const appliedAnnex = questionaireItems.filter((q) =>
        appliedIdsForAnnex.includes(q.resource_id!.toString() + "field")
      );

      const annexIds = appliedAnnex.flatMap((aq) => aq.annex_sections);
      const annexes = annexIds
        .filter(onlyUnique)
        .map((id) => annexSections.find((qi) => qi.resource_id === id)!)
        .filter(Boolean);

      const requiredAnnex = annexSections.filter(
        (qi) =>
          (qi.directive === directive ||
            (directive === Directive.mrl &&
              qi.directive === Directive._none)) &&
          (qi.name.match(/^1\./) || qi.name.match(/^0\./))
      )!;

      const allSortedAnnexes = [...requiredAnnex, ...annexes].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      )!;

      const _groups = allSortedAnnexes.filter(
        (annex) =>
          (annex.directive === directive ||
            (directive === Directive.mrl &&
              annex.directive === Directive._none)) &&
          annex.is_informational &&
          annex.name.length < 3 // matches "1." or "2."
      );

      const _noGroup = allSortedAnnexes.filter(
        (annex) =>
          (annex.directive === directive ||
            (directive === Directive.mrl &&
              annex.directive === Directive._none)) &&
          (!annex.is_informational ||
            (annex.is_informational && annex.name.length >= 3)) // matches "1.1." or "2.1."
      );

      fd.sections[3] = {
        ...fd.sections[3],
        fields: [...buildAnnexFields(_noGroup, langCode, _groups, t)],
        isGroup: true,
        groups: _groups.reverse().map((group) => {
          return {
            name: group.name,
            label: `${group.name} ${
              group.questions.find((q) => q.language === langCode)?.content
            }`,
          };
        }),
      };

      !isPreload &&
        currentStep === 2 &&
        displayFeedback!(FeedbackLocation.Questionaire3);

      if (!v.Anhang1Relevante) {
        return;
      }

      const appliedStandardIds = Object.keys(v.Anhang1Relevante).filter((qId) =>
        parseInt(v.Anhang1Relevante[qId])
      );

      const appliedStandards = annexSections
        .filter((q) =>
          appliedStandardIds.includes(q.resource_id!.toString() + "field")
        )
        .sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { numeric: true })
        );

      const alreadyUsedStandards: string[] = [];
      const standardGroups = appliedStandards.flatMap((aq) => {
        const filteredStandards =
          aq.suggested_standards
            ?.filter(onlyUnique)
            ?.filter((std) => !alreadyUsedStandards.includes(std))
            ?.map(
              (name) =>
                translations!.find((s) => s.name === name.split(":")[0])!
            )
            .filter(Boolean) || [];

        alreadyUsedStandards.push(...filteredStandards.map((std) => std.name));

        return {
          standards: filteredStandards,
          relatedSection: aq,
        };
      });

      !isPreload &&
        currentStep === 3 &&
        displayFeedback!(FeedbackLocation.Questionaire4);

      fd.sections[4].fields = [...buildStandardFields(standardGroups)];
      fd.sections[4].isGroup = true;
      fd.sections[4].groups = standardGroups
        .filter((sg) => sg.standards.length)
        .map(({ relatedSection }) => ({
          name: relatedSection.name,
          label: relatedSection.questions.find((q) => q.language === langCode)!
            .content,
        }));

      const n = { ...fd };
      setFd(n);
    },
    [
      t,
      fd,
      langCode,
      directive,
      translations,
      annexSections,
      displayFeedback,
      confirmedAnnexIV,
      questionaireItems,
    ]
  );

  React.useEffect(() => {
    if (
      !questionaireItems?.length ||
      !questionaireItemsProject ||
      !annexSectionsProject ||
      fetchInProgress
    ) {
      return;
    }

    const rawExitQuestions = getExitQuestions(questionaireItems, directive);
    const exitQuestionIds = rawExitQuestions.map((q) => q.resource_id!);

    const rawFirstQuestions = getFirstLevelQuestions(
      questionaireItems,
      directive
    );
    const firstQuestionIds = rawFirstQuestions.map((q) => q.resource_id!);

    const exitQuestions = questionaireItemsProject?.questionaires
      ?.filter((q) => exitQuestionIds.includes(q.questionaireId))
      .reduce((acc, key) => {
        acc[key.questionaireId.toString() + "field"] = key.isSelected
          ? "1"
          : "0";

        return acc;
      }, {} as Record<string, string>);

    const category = questionaireItemsProject?.questionaires
      ?.filter((q) => firstQuestionIds.includes(q.questionaireId))
      .reduce((acc, key) => {
        acc[key.questionaireId.toString() + "field"] = key.isSelected
          ? "1"
          : "0";

        return acc;
      }, {} as Record<string, string>);

    const anhang1 = questionaireItemsProject?.questionaires
      ?.filter((q) => !firstQuestionIds.includes(q.questionaireId))
      .reduce((acc, key) => {
        acc[key.questionaireId.toString() + "field"] = key.isSelected
          ? "1"
          : "0";

        return acc;
      }, {} as Record<string, string>);

    const annex = annexSectionsProject?.annexSections?.reduce((acc, key) => {
      acc[key.annexId.toString() + "field"] = key.isSelected ? "1" : "0";

      return acc;
    }, {} as Record<string, string>);

    const norm = standardsProject?.reduce((acc, key) => {
      const stdWithoutVersion = key.split(":")[0];
      acc[stdWithoutVersion] = "1";

      return acc;
    }, {} as Record<string, string>);

    const defaultData = {
      exitQuestions,
      category,
      Anhang1: anhang1,
      Anhang1Relevante: annex,
      Normenauswahl: norm,
    };

    setDefaultData(defaultData);

    if (
      defaultData &&
      !preloaded &&
      translations?.length &&
      fd?.sections.length
    ) {
      handleStepCallback(defaultData, 0, null, true);
      setPreloaded(true);
    }
  }, [
    fd,
    preloaded,
    directive,
    translations,
    fetchInProgress,
    standardsProject,
    questionaireItems,
    handleStepCallback,
    annexSectionsProject,
    questionaireItemsProject,
  ]);

  if (
    !fd?.sections ||
    questionaireLoading ||
    annexSectionLoading ||
    (questionaireItemsProject?.questionaires && !defaultData.exitQuestions) ||
    (annexSectionsProject?.annexSections &&
      (!defaultData.Anhang1Relevante ||
        !Object.keys(defaultData.Anhang1Relevante).length))
  ) {
    return <Loading />;
  }

  const DirectiveRes = directiveToResearch[directive]!;
  if (DirectiveRes) {
    return <DirectiveRes />;
  }

  return (
    <div className="questionaire">
      <Form
        persistStep
        formData={fd}
        onSubmit={handleSubmit}
        onAutosave={handleSave}
        defaultValues={defaultData}
        onStep={handleStepCallback}
        disableStepper={!defaultData.exitQuestions || !defaultData.category}
      />

      <Modal
        onClose={() => setIsExitModalVisible(false)}
        show={isExitModalVisible}
        width="1/2"
        content={
          <div>
            <span className="text-xl">
              {t(`questionaire.${directive}.exit_modal.headline`)}
            </span>
            <div className="pt-4"></div>
            <div>
              <p>{t(`questionaire.${directive}.exit_modal.subline`)}</p>
              <Collapsible
                label={`${t(
                  `questionaire.${directive}.exit_modal.collapsible_label`
                )} ${exitModalContent?.article}`}
                child={
                  <>
                    <p>
                      <b>
                        Frage: "
                        {
                          exitModalContent?.questions?.find(
                            (t) => t.language === langCode
                          )?.content
                        }
                        "
                      </b>
                    </p>
                    {parse(
                      exitModalContent?.originalTexts1?.find(
                        (t) => t.language === langCode
                      )?.content || ""
                    )}
                  </>
                }
              />
              <p className="pt-4 pb-4">
                {t(`questionaire.${directive}.exit_modal.explanation`)}
              </p>

              <div className="pt-9 text-right">
                <a
                  className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() => setIsExitModalVisible(false)}
                >
                  {t(`questionaire.${directive}.exit_modal.cancel`)}
                </a>
                <PrimaryButton
                  onClick={async () => {
                    await client.put(`/projects/${projectId}`, {
                      payload: {
                        ...project,
                        projectdata: {
                          ...project!.projectdata,
                          category: Category.exit,
                        },
                      } as Project,
                    });

                    await client.post(
                      `/projects/${projectId}/questionaireItems`,
                      [
                        ...(questionaireItemsProject?.questionaires || []),
                        {
                          questionaireId: exitModalContent?.resource_id,
                          isSelected: true,
                        },
                      ]
                    );

                    await queryClient.refetchQueries(["projects", projectId]);
                    await queryClient.invalidateQueries([
                      "questionaireItems",
                      projectId,
                    ]);

                    history.push(`/projects/${projectId}?saved=true`);
                  }}
                >
                  {t(`questionaire.${directive}.exit_modal.cta`)}
                </PrimaryButton>
              </div>
            </div>
          </div>
        }
      />
      {/* -------------------------------------------------------------- */}

      {isCategoryModalVisible &&
        (!categoryModalContent?.length || categoryModalContent?.length < 1) && (
          <NoCategoryModal
            t={t}
            isCategoryModalVisible={isCategoryModalVisible}
            setIsCategoryModalVisible={setIsCategoryModalVisible}
          />
        )}

      {isCategoryModalVisible &&
        categoryModalContent?.length! >= 2 &&
        (!categoryModalContent?.find(
          (item) => item.product_category === "annex_iv"
        ) ||
          categoryModalContent?.length! > 2) && (
          <TooManyCategoryModal
            t={t}
            langCode={langCode}
            directive={directive}
            isCategoryModalVisible={isCategoryModalVisible}
            categoryModalContent={categoryModalContent || []}
            setIsCategoryModalVisible={setIsCategoryModalVisible}
          />
        )}

      {isCategoryModalVisible &&
        categoryModalContent?.length &&
        categoryModalContent.length <= 2 &&
        categoryModalContent?.find(
          (item) => item.product_category === "annex_iv"
        ) && (
          <CategoryAnnexIVModal
            onConfirm={() => {
              setIsCategoryModalVisible(false);
              setConfirmedAnnexIV(true);
            }}
            t={t}
            directive={directive}
            isCategoryModalVisible={isCategoryModalVisible}
            setIsCategoryModalVisible={setIsCategoryModalVisible}
          />
        )}
    </div>
  );
};

interface TooManyCategoryModalProps {
  directive: Directive;
  isCategoryModalVisible: boolean;
  setIsCategoryModalVisible: (isVisible: boolean) => void;
  categoryModalContent: QuestionaireItem[];
  langCode: string;
  t: TFunction;
}

const TooManyCategoryModal = ({
  setIsCategoryModalVisible,
  isCategoryModalVisible,
  directive,
  categoryModalContent,
  langCode,
  t,
}: TooManyCategoryModalProps) => {
  return (
    <Modal
      onClose={() => setIsCategoryModalVisible(false)}
      show={isCategoryModalVisible}
      width="1/2"
      content={
        <div>
          <span className="text-xl">
            {t(`questionaire.${directive}.category_modal.headline`)}
          </span>
          <div className="pt-4"></div>
          <div>
            <p>
              <Trans
                i18nKey={`questionaire.${directive}.category_modal.subline`}
              />
            </p>

            <p>
              <Trans
                i18nKey={`questionaire.${directive}.category_modal.explanation`}
                count={categoryModalContent?.length}
              />
            </p>

            {categoryModalContent?.map((category) => {
              return (
                <Collapsible
                  key={category.article}
                  label={t(
                    `common.selector.product_category.${category?.product_category}`
                  )}
                  child={
                    <>
                      <p>
                        <b>
                          {t("common.question")}: "
                          {parse(
                            category?.questions?.find(
                              (t) => t.language === langCode
                            )?.content || ""
                          )}
                          "
                        </b>
                      </p>
                      {parse(
                        category?.explanations?.find(
                          (t) => t.language === langCode
                        )?.content || ""
                      )}
                    </>
                  }
                />
              );
            })}

            <div className="pt-9 text-right">
              <PrimaryButton onClick={() => setIsCategoryModalVisible(false)}>
                {t(`questionaire.${directive}.category_modal.cta`)}
              </PrimaryButton>
            </div>
          </div>
        </div>
      }
    />
  );
};

interface CategoryAnnexIVModalProps {
  directive: Directive;
  isCategoryModalVisible: boolean;
  setIsCategoryModalVisible: (isVisible: boolean) => void;
  onConfirm: () => void;
  t: TFunction;
}

const CategoryAnnexIVModal = ({
  setIsCategoryModalVisible,
  isCategoryModalVisible,
  directive,
  onConfirm,
  t,
}: CategoryAnnexIVModalProps) => {
  return (
    <Modal
      onClose={() => setIsCategoryModalVisible(false)}
      show={isCategoryModalVisible}
      width="1/2"
      content={
        <div>
          <span className="text-xl">
            {t(`questionaire.${directive}.annex_iv_modal.headline`)}
          </span>
          <div className="pt-4"></div>
          <div>
            <p>
              <Trans
                i18nKey={`questionaire.${directive}.annex_iv_modal.subline`}
              >
                <a
                  target="_blank"
                  className="text-cyan-600 cursor-pointer hover:text-cyan-800"
                  href="https://webgate.ec.europa.eu/single-market-compliance-space/#/notified-bodies/by-country"
                ></a>
              </Trans>
            </p>

            <div className="pt-9 text-right">
              <PrimaryButton onClick={onConfirm}>
                {t(`questionaire.${directive}.annex_iv_modal.cta`)}
              </PrimaryButton>
            </div>
          </div>
        </div>
      }
    />
  );
};

interface NoCategoryModalProps {
  isCategoryModalVisible: boolean;
  setIsCategoryModalVisible: (isVisible: boolean) => void;
  t: TFunction;
}

const NoCategoryModal = ({
  setIsCategoryModalVisible,
  isCategoryModalVisible,
  t,
}: NoCategoryModalProps) => {
  return (
    <Modal
      onClose={() => setIsCategoryModalVisible(false)}
      show={isCategoryModalVisible}
      width="1/2"
      content={
        <div>
          <span className="text-xl">
            {t(`questionaire.no_category_modal.headline`)}
          </span>
          <div className="pt-4"></div>
          <div>
            <p>
              <Trans i18nKey={`questionaire.no_category_modal.subline`}>
                <a
                  target="_blank"
                  className="text-cyan-600 cursor-pointer hover:text-cyan-800"
                  href="https://webgate.ec.europa.eu/single-market-compliance-space/#/notified-bodies/by-country"
                ></a>
              </Trans>
            </p>

            <div className="pt-9 text-right">
              <PrimaryButton onClick={() => setIsCategoryModalVisible(false)}>
                {t(`questionaire.no_category_modal.cta`)}
              </PrimaryButton>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default BaseInformation;
