import React, { ChangeEvent } from "react";
import { FieldArray, useFormikContext } from "formik";
import {
  FormSection as FormSectionType,
  FormField as FormFieldType,
  FormFieldType as FormFieldTypeType,
} from "@slootsantos/certain-forms/dist/types";
import { useTranslation } from "react-i18next";

import FormField from "forms/FormField";
import FormSection from "components/form/FormSection";
import { DocumentUpload } from "components/DocumentUpload/Uploader";

interface FormBodyProps {
  context?: string;
  withDivider?: boolean;
  section: FormSectionType;
  confirmationLabel?: string;
  confirmationSubline?: string;
  onAdd?: (values: any) => Record<string, string>;
  onChange: (
    fieldName: string,
    transform: FormFieldType["transform"]
  ) => (e: React.ChangeEvent<any>) => void;
}

export const FormBody = (props: FormBodyProps) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { section, withDivider = false, onChange } = props;

  if (props.section.isGroup) {
    return (
      <>
        {props.section.groups?.map((group, idx) => {
          const isFirst = idx === 0;

          return (
            <FormSection
              key={group.name + idx}
              withDivider={withDivider}
              sectionName={isFirst ? section.name : ""}
              title={isFirst ? section.label : ""}
              infoText={isFirst ? section.info : ""}
              subline={isFirst ? section.subline : ""}
              confirmationLabel={props.confirmationLabel}
              confirmationSubline={props.confirmationSubline}
            >
              <h3 className="text-lg col-span-6">{t(group.label || "")}</h3>
              {section.fields
                .filter((field) => field.groupName === group.name)
                .map((field, idx) => {
                  const fieldName = `${section.name}.${field.name}`;

                  if (field.type === FormFieldTypeType.informational) {
                    return field.customRenderOverride!({
                      ...(values as object),
                      idx: 0,
                    });
                  }

                  if (field.type === FormFieldTypeType.upload) {
                    return (
                      <DocumentUpload
                        onChange={(f: File | null) => {
                          onChange(
                            "_uploads." + field.name,
                            undefined
                          )({
                            target: {
                              value: { image: f, key: f?.name, touched: true },
                            },
                          } as ChangeEvent<any>);
                        }}
                        file={
                          (values as any)?._uploads &&
                          (values as any)?._uploads[field.name]?.image
                        }
                        location={
                          (values as any)?._uploads &&
                          (values as any)?._uploads[field.name]?.location
                        }
                        type={"type"}
                        key={field.name}
                        name={field.name}
                        subline={field.info!}
                        label={field.label}
                      />
                    );
                  }

                  return (
                    <FormField
                      {...field}
                      data-test={fieldName}
                      key={fieldName + idx}
                      name={fieldName}
                      onBlur={onChange(fieldName, field.transform)}
                      defaultValue={
                        values &&
                        (values as any)[section.name] &&
                        (values as any)[section.name][field.name]
                      }
                    />
                  );
                })}
            </FormSection>
          );
        })}
      </>
    );
  }

  if (props.section.isList) {
    return (
      <FieldArray
        name={section.name}
        render={(arrayHelpers) => (
          <div>
            {/* @ts-ignore */}
            {values[section.name] &&
              // @ts-ignore
              values[section.name].map((_, index: number) => {
                const isFirst = index === 0;

                return (
                  <div key={index}>
                    <FormSection
                      key={section.name + index}
                      withDivider={withDivider}
                      title={isFirst ? section.label : ""}
                      infoText={isFirst ? section.info : ""}
                      subline={isFirst ? section.subline : ""}
                      sectionName={isFirst ? section.name : ""}
                      withDeletion
                      deletionHandler={() => arrayHelpers.remove(index)}
                      confirmationLabel={props.confirmationLabel}
                      confirmationSubline={props.confirmationSubline}
                    >
                      <h4 className="pt-4 text-lg col-span-6">
                        {t(`counter.lists.${section.label}`)} #{index + 1}
                      </h4>
                      {section.fields.map((field) => {
                        if (field.type === FormFieldTypeType.informational) {
                          return field.customRenderOverride!({
                            ...(values as object),
                            idx: index,
                          });
                        }

                        const fieldName = `${section.name}[${index}].${field.name}`;
                        const fieldValue =
                          values &&
                          (values as any)[section.name] &&
                          (values as any)[section.name][index] &&
                          (values as any)[section.name][index][field.name];
                        return (
                          <FormField
                            {...field}
                            data-test={fieldName}
                            uncontrolled
                            name={fieldName}
                            defaultValue={fieldValue}
                            key={fieldName + index + fieldValue}
                            onBlur={onChange(fieldName, field.transform)}
                          />
                        );
                      })}
                    </FormSection>
                  </div>
                );
              })}
            <div className="text-right col-span-6 pt-4">
              <button
                data-test="add-list-item-button"
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                onClick={() => {
                  props.onAdd
                    ? arrayHelpers.push(props.onAdd(values) || {})
                    : arrayHelpers.push({});
                }}
              >
                {t("counter.lists.add", {
                  entity: t(`counter.lists.${section.label}`),
                })}
              </button>
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <FormSection
      key={section.name}
      sectionName={section.name}
      title={section.label}
      subline={section.subline}
      withDivider={withDivider}
      infoText={section.info}
      confirmationLabel={props.confirmationLabel}
      confirmationSubline={props.confirmationSubline}
    >
      {section.fields.map((field) => {
        const fieldName = `${section.name}.${field.name}`;

        if (field.type === FormFieldTypeType.informational) {
          return field.customRenderOverride!({
            ...(values as object),
            idx: 0,
          });
        }

        if (field.type === FormFieldTypeType.upload) {
          return (
            <DocumentUpload
              onChange={(f: File | null) => {
                onChange(
                  "_uploads." + field.name,
                  undefined
                )({
                  target: {
                    value: { image: f, key: f?.name, touched: true },
                  },
                } as ChangeEvent<any>);
              }}
              file={
                (values as any)?._uploads &&
                (values as any)?._uploads[field.name]?.image
              }
              location={
                (values as any)?._uploads &&
                (values as any)?._uploads[field.name]?.location
              }
              type={"type"}
              key={field.name}
              name={field.name}
              subline={field.info!}
              label={field.label}
            />
          );
        }

        return (
          <FormField
            {...field}
            data-test={fieldName}
            key={props.context + fieldName}
            name={fieldName}
            onBlur={onChange(fieldName, field.transform)}
            defaultValue={
              values &&
              (values as any)[section.name] &&
              (values as any)[section.name][field.name]
            }
          />
        );
      })}
    </FormSection>
  );
};
