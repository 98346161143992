import React from "react";
import { useTranslation } from "react-i18next";

import SelectInput from "components/inputs/SelectInput";

export enum Category {
  none = "none",
  sample = "sample",
  machine = "machine",
  partially_completed_machinery = "partially_completed_machinery",
  assembly_of_machinery = "assembly_of_machinery",
  safety_Component = "safety_Component",
  lifting_accessory = "lifting_accessory",
  chains_ropes_webbing = "chains_ropes_webbing",
  removable_mechanical_transmission_device = "removable_mechanical_transmission_device",
  lifting_device_human_power = "lifting_device_human_power",
  interchangeable_equipment = "interchangeable_equipment",
  annex_iv = "annex_iv",
  exit = "exit",
  // non MRL categories
  lvd = "lvd",
}

interface ProductCategorySelectorProps {
  onChange: (category: string) => void;
  defaultSelection?: string | null;
}

export const ProductCategorySelector = (
  props: ProductCategorySelectorProps
) => {
  const { t } = useTranslation();

  return (
    <SelectInput
      id="product_category"
      label={t("common.selector.product_category.label")}
      options={Object.values(Category).map((c) => ({
        label: t(`common.selector.product_category.${c}`),
        value: c,
      }))}
      // @ts-ignore
      value={props.defaultSelection}
      // @ts-ignore
      onBlur={(e) => {
        // @ts-ignore
        const category = e.target.value;
        props.onChange(category);
      }}
    />
  );
};
