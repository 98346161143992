import React, { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { Risk } from "./types";
import { useClient } from "utils/client";
import { Modal } from "components/Modal/Modal";
import RadioInput from "components/inputs/RadioInput";
import SelectInput from "components/inputs/SelectInput";
import { useRiskTemplates } from "pages/Risk/hooks/useRisks";
import { PrimaryButton } from "components/Button/PrimaryButton";
import i18n from "i18n";

interface CreateProjectOnDashboardProps {
  projectCRN: string;
  button: React.FunctionComponent<React.ButtonHTMLAttributes<any>>;
}

export const CreateRiskOnDashboard: React.FunctionComponent<
  CreateProjectOnDashboardProps
> = (props) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <>
      <div>
        <props.button onClick={() => setModalVisible(true)} />
      </div>

      <>
        <Modal
          onClose={() => setModalVisible(false)}
          show={modalVisible}
          width="1/2"
          content={<CreateRiskForm projectCRN={props.projectCRN} />}
        />
      </>
    </>
  );
};

enum CreateOption {
  template = "template",
  scratch = "scratch",
}

const createRisk = async (
  client: AxiosInstance,
  { risk, mitigations }: { risk?: Risk; mitigations: any },
  projectId: string
) => {
  try {
    const riskPayload = risk
      ? {
          ...risk,
          assessment: {
            impact: risk.impact,
            people_count: risk.people_count,
            duration: risk.duration,
            probability: risk.probability,
            detectability: risk.detectability,
          },
          mitigations: mitigations,
        }
      : { mitigations: [{}] };

    const { data } = await client.post<Risk>(
      `/projects/${projectId}/risks`,
      riskPayload
    );

    return data;
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }
};

const useCreateRisk = (projectCRN: string) => {
  const client = useClient();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutate, isLoading } = useMutation(
    async (selectedTemplate?: Risk) =>
      await createRisk(
        client,
        {
          risk: selectedTemplate,
          // @ts-ignore
          mitigations: selectedTemplate?.mitigations || [],
        },
        projectCRN
      ),
    {
      onSuccess: async (risk) => {
        await queryClient.refetchQueries(["projects", projectCRN]);
        history.push(`/projects/${projectCRN}/risks/${risk.resource_id}/edit`);
      },
    }
  );

  return { createRisk: mutate, isLoading };
};

interface CreateRiskFormProps {
  projectCRN: string;
}

const CreateRiskForm = (props: CreateRiskFormProps) => {
  const { t } = useTranslation();
  const { risks: templates } = useRiskTemplates();
  const language = i18n.language.split("-")[0].toUpperCase();
  const { createRisk, isLoading } = useCreateRisk(props.projectCRN);
  const [selectedOption, setSelectedOption] = useState<CreateOption>(
    CreateOption.scratch
  );

  const [selectedTemplate, setSelectedTemplate] = useState<Risk>();

  const handleCreateRisk = () => {
    createRisk(selectedTemplate);
  };

  const langRegex = new RegExp(`\\[${language}\\]`, "g");

  return (
    <div>
      <span className="text-xl">{t("risks.create.headline")}</span>
      <div className="pt-4 pb-4">
        <RadioInput
          islast
          required
          id="useTemplate"
          name="useTemplate"
          label={t("risks.create.create_choice")}
          defaultValue={selectedOption}
          onBlur={(option) =>
            setSelectedOption(option.target.value as CreateOption.scratch)
          }
          options={[
            {
              label: t(`risks.create.options.${CreateOption.scratch}`),
              value: CreateOption.scratch,
            },
            {
              label: t(`risks.create.options.${CreateOption.template}`),
              value: CreateOption.template,
            },
          ]}
        />

        {selectedOption === CreateOption.template && (
          <SelectInput
            required
            id="template"
            name="template"
            defaultValue={""}
            label={t("risks.create.template")}
            onBlur={(option) =>
              setSelectedTemplate(
                templates!.find((t) => t.resource_id === option.target.value)!
              )
            }
            options={
              templates
                ?.filter((template) =>
                  template.blueprint_name?.match(langRegex)
                )
                ?.map((template) => ({
                  value: template.resource_id,
                  label: template.blueprint_name!.replace(langRegex, ""),
                })) || []
            }
          />
        )}
      </div>

      <PrimaryButton
        float="right"
        disabled={
          (selectedOption === CreateOption.template && !selectedTemplate) ||
          isLoading
        }
        onClick={handleCreateRisk}
      >
        {isLoading ? t("risks.create.cta_loading") : t("risks.create.cta")}
      </PrimaryButton>
    </div>
  );
};
