import { AxiosInstance } from "axios";
import { ControlContext } from "context/Controls";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Descendant } from "slate";
import { useClient } from "utils/client";

export const useManual = (projectCrn: string) => {
  const client = useClient();

  const { data: manual, isLoading } = useQuery<any[]>(
    ["manuals", projectCrn],
    () => fetchManual(client, projectCrn)
  );

  return { manual, isLoading };
};

const fetchManual = async (client: AxiosInstance, crn: string) => {
  const { data } = await client.get(`/projects/${crn}/manual`);
  return data;
};

const storeManual = async (
  client: AxiosInstance,
  crn: string,
  manual: Descendant[]
) => {
  const { data } = await client.put(`/projects/${crn}/manual`, { manual });
  return data;
};

export const useManualSave = (crn: string) => {
  const client = useClient();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    async (manual: any) => await storeManual(client, crn, manual),
    {
      onError: (e) =>
        displayNotification!(
          "Error storing manual: ",
          (e as Error).message,
          "error"
        ),
      onSuccess: () => {
        queryClient.refetchQueries(["manuals", crn]);

        displayNotification!(
          t("notification.saving.generic_success"),
          t("notification.saving.research_success")
        );
      },
    }
  );

  return { storeManual: mutate, isLoading };
};
