import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import { useClient } from "utils/client";
import { AnnexSection, AnnexSectionSelectionResponse } from "../types";
import { AxiosInstance } from "axios";

const fetchAnnexSections = async (client: AxiosInstance) => {
  const { data } = await client.get<AnnexSection[]>("/annexSections");

  return data;
};

const fetchAnnexSectionsForProject = async (
  client: AxiosInstance,
  projectId: string
) => {
  const { data } = await client.get<AnnexSectionSelectionResponse>(
    `projects/${projectId}/annexSections`
  );

  return data;
};

const fetchOneAnnexSection = async (client: AxiosInstance, annexId: string) => {
  const { data } = await client.get<AnnexSection>(`/annexSections/${annexId}`);

  return data;
};

export const useAnnexSections = () => {
  const client = useClient();
  const { data: annexSections = [], isLoading } = useQuery(
    ["annexSections"],
    () => fetchAnnexSections(client)
  );

  return { annexSections, isLoading };
};

export const useAnnexSection = (annexId: string) => {
  const client = useClient();

  const { data: annexSection } = useQuery(["annexSection", annexId], () =>
    fetchOneAnnexSection(client, annexId)
  );

  return annexSection;
};

export const useAnnexSectionsForProject = (projectId: string) => {
  const client = useClient();

  const { data, isLoading } = useQuery(["annexSections", projectId], () =>
    fetchAnnexSectionsForProject(client, projectId)
  );

  return { annexSections: data, isLoading };
};

export const useEditAnnex = (annexSection: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    async (item: AnnexSection) => {
      return await client.put(`/annexSections/${annexSection}`, item);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["annexSection", annexSection]);
        await queryClient.invalidateQueries(["annexSections"]);
        history.goBack();
      },
    }
  );

  return mutate;
};

export const useDeleteAnnex = (annexId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    async () => {
      return await client.delete(`/annexSections/${annexId}`);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["annexSections", annexId]);

        history.push("/_backoffice/_questionaire");
      },
    }
  );

  return mutate;
};
