import { useQuery } from "react-query";

import axios from "utils/client";

export interface DocumentationArticle {
  resource_id?: string;
  name: string;
  data: string;
  created_at?: string;
}

const fetchDocs = async () => {
  const { data } = await axios.get("/docs");

  return data;
};

const fetchOneDoc = async (documentationId: string) => {
  const { data } = await axios.get(`/docs/${documentationId}`);

  return data;
};

export const useDocumentations = () => {
  const { data: documentations } = useQuery<DocumentationArticle[]>(
    ["documentations"],
    () => fetchDocs()
  );

  return { documentations };
};

export const useDocumentation = (documentationId: string) => {
  const { data: documentation } = useQuery<DocumentationArticle>(
    ["documentations", documentationId],
    () => fetchOneDoc(documentationId)
  );

  return { documentation };
};
