import React from "react";
import { Risk, RiskMitigation } from "./types";
import { calculatePerformanceLevel } from "./utils";

interface PerformanceLevelScoreFormFieldProps {
  idx: number;
  risk: Risk;
  mitigations: RiskMitigation[];
}

export const PerformanceLevelScoreFormField = (
  props: PerformanceLevelScoreFormFieldProps
) => {
  if (!props.risk || !props.mitigations) {
    return null;
  }
  const currentMitigation = props.mitigations[props.idx];

  if (currentMitigation.type !== "ESS") {
    return null;
  }

  return (
    <div className="col-span-6">
      <div className="flex justify-between">
        <label className="block text-md font-medium text-gray-700">
          Performance Level Required <i>(PLR)</i> der Maßnahme:{" "}
          <b>{calculatePerformanceLevel(props.risk)}</b>
        </label>
      </div>
    </div>
  );
};
