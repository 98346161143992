import { BasePage } from "components/BasePage/BasePage";
import { BackofficeRiskCreate } from "components/Backoffice/Risk/Create";

export const CreateRiskBackoffice = () => {
  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Risiko-Vorlagen",
          location: "/_backoffice/_risks",
        },
        {
          label: "neue Vorlage erstellen",
          location: "/_backoffice/_risks/new",
        },
      ]}
    >
      <div>
        <BackofficeRiskCreate />
      </div>
    </BasePage>
  );
};
