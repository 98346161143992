import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import axios from "utils/client";
import { Translations, Country, Languages } from "types";

export const fetchCountries = async (language: string) => {
  const { data } = await axios.get<Country[]>(
    "https://restcountries.com/v3.1/all?fields=name,translations"
  );

  if (language === Languages.english) {
    data.sort((a, b) => a.name.common.localeCompare(b.name.common));

    return data;
  }

  const translationLang = mapTranslationLangToUILang(language);

  data.sort((a, b) =>
    a.translations[translationLang].common.localeCompare(
      b.translations[translationLang].common
    )
  );

  return data;
};

export const getCountryByLanguage = (
  country: Country,
  language: string
): string => {
  if (language === Languages.english) {
    return country.name.common;
  }

  const translationLang = mapTranslationLangToUILang(language);

  return country.translations[translationLang].common;
};

export const useCountries = () => {
  const { i18n } = useTranslation();
  const { data: countries, isLoading } = useQuery(["countries"], () =>
    fetchCountries(i18n.language)
  );

  return { countries, isLoading, getCountryByLanguage };
};

const mapTranslationLangToUILang = (lang: string) => {
  const translationLang = lang.match(Languages.german)
    ? Translations.german
    : Translations.portuguese;

  return translationLang;
};
