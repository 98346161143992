import { useTranslation } from "react-i18next";
import { PDFStatus } from "./types";

const statusMap: Record<PDFStatus, { colorClass: string }> = {
  [PDFStatus.ready]: {
    colorClass: "green",
  },
  [PDFStatus.pending]: {
    colorClass: "gray",
  },
  [PDFStatus.loading]: {
    colorClass: "blue",
  },
  [PDFStatus.missingData]: {
    colorClass: "red",
  },
};

export const StatusIndictor = (props: { status: PDFStatus }) => {
  const { t } = useTranslation();
  const status = statusMap[props.status];

  return (
    <span
      className={`inline-flex items-center rounded-md bg-${status.colorClass}-50 px-2 py-1 text-xs font-medium text-${status.colorClass}-700`}
    >
      {t(`documents.download.state.${props.status}`)}
    </span>
  );
};
