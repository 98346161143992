import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { SelectInputProps } from "./types";
import Collapsible from "components/Collapsible/Collapsible";

const CheckInput: React.FunctionComponent<
  SelectInputProps & {
    defaultValue?: string | boolean | number | readonly string[];
  }
> = (props) => {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = {
      target: { value: null },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    event.target.value = e.target.checked ? "1" : "0";

    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  return (
    <div className="col-span-12 border-b pb-2">
      <div className="flex justify-between">
        <label className="block text-sm font-medium text-gray-700 mb-4">
          {props.label}
          {props.explanation && (
            <div className="mt-2 text-xs text-gray-500">
              {parse(props.explanation)}
            </div>
          )}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>

      <input
        type="checkbox"
        checked={props.defaultValue === "on" || props.defaultValue === "1"}
        onChange={handleChange}
        className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 border-gray-300"
      />
      <label htmlFor={props.id} className="text-sm">
        {" "}
        {t("Ja")}
      </label>
      <p className="mt-2 text-sm text-gray-500">{props.info}</p>
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default CheckInput;
