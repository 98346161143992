import * as React from "react";
import { useParams } from "react-router-dom";

import { BasePage } from "components/BasePage/BasePage";
import { ManufacturerEdit } from "components/Manufacturers/Edit";
import { useOneManufacturer } from "components/Manufacturers/hooks/useOneManufacturer";

interface ManufacturerEditRouteParams {
  manufacturerId: string;
}

export const EditManufacturer = () => {
  const { manufacturerId } = useParams<ManufacturerEditRouteParams>();
  const { manufacturer, fetchInProgress } = useOneManufacturer(manufacturerId);

  return (
    <BasePage
      loading={fetchInProgress}
      breadcrumbItems={[
        { label: "Dashboard", location: "/dashboard" },
        { label: "form.manufacturer.list", location: "/manufacturers" },
        {
          label: manufacturer?.company || manufacturerId,
          location: `/manufacturers/${manufacturerId}`,
        },
        {
          label: "form.manufacturer.edit",
          location: `/manufacturers/${manufacturerId}`,
        },
      ]}
    >
      <div>
        <ManufacturerEdit
          manufacturer={manufacturer!}
          manufacturerId={manufacturerId}
        />
      </div>
    </BasePage>
  );
};
