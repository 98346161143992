import { Breadcrumb, Breadcrumbs } from "components/common/Breadcrumbs";
import { Loading } from "components/Loading/Loading";
import React, { PropsWithChildren } from "react";

interface BreadcrumbProps {
  breadcrumbItems: Breadcrumb[];
  loading?: boolean;
  overLay?: boolean;
}

const BasePageLoader = () => (
  <div className="h-80 flex justify-center items-center">
    <Loading />
  </div>
);

const OverlayPageLoader = () => (
  <div className="h-full w-full flex justify-center items-center z-50 top-0 left-0 absolute bg-white opacity-60">
    <Loading />
  </div>
);

export const BasePage: React.FunctionComponent<
  PropsWithChildren<BreadcrumbProps>
> = (props) => {
  return (
    <div className="flex flex-col w-0 flex-1 overflow-y-scroll" id="basepage">
      <div className="p-10 mb-10">
        <Breadcrumbs items={props.breadcrumbItems} />
        {props.loading ? (
          props.overLay ? (
            <>
              <OverlayPageLoader /> {props.children}
            </>
          ) : (
            <BasePageLoader />
          )
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};
