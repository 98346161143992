import React, { useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import {
  createEditor,
  Descendant,
  Element as SlateElement,
  Editor,
  Transforms,
  Node,
} from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import {
  RenderElementProps,
  HOTKEYS,
  ManualEditorProps,
  CustomElement,
} from "components/ManualBuilder/types";
import {
  toggleMark,
  Toolbar,
} from "components/ManualBuilder/components/toolbar";
import { Element, Leaf } from "components/ManualBuilder/components/elements";

const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "Start typing here..." }],
  },
];

const withImages = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
};

const withTables = (editor: Editor) => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = (unit) => {
    const { selection } = editor;

    if (selection && SlateElement.isElement(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        return;
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    const { selection } = editor;

    if (selection && SlateElement.isElement(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        return;
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table",
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

const withLists = (editor: Editor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && selection.anchor.offset === 0) {
      const [match] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "list-item",
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        if (
          selection.anchor.offset === 0 &&
          selection.anchor.path[0] === start.path[0]
        ) {
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === "list",
            split: true,
          });
          Transforms.setNodes(editor, { type: "paragraph" });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

export const ManualEditor = (props: ManualEditorProps) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );

  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

  const editor = useMemo(
    () =>
      withImages(withLists(withTables(withHistory(withReact(createEditor()))))),
    []
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // Handle hotkeys
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      }

      // Handle Enter key
      if (event.key === "Enter" && !event.shiftKey) {
        const { selection } = editor;

        if (selection) {
          // Check if we're in a list item
          const [listItem] = Editor.nodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === "list-item",
          });

          if (listItem) {
            event.preventDefault();
            const [node] = listItem;

            // Check if the current list item is empty
            const isEmptyListItem = Node.string(node).length === 0;

            if (isEmptyListItem) {
              // Convert to normal paragraph
              Transforms.unwrapNodes(editor, {
                match: (n) =>
                  !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  n.type === "list",
                split: true,
              });
              Transforms.setNodes(editor, { type: "paragraph" });
            } else {
              // Insert a new list item
              editor.insertBreak();
            }
            return;
          }

          // Default behavior for non-list blocks: reset to normal paragraph
          event.preventDefault();
          editor.insertBreak();
          Transforms.setNodes(
            editor,
            { type: "paragraph" },
            {
              match: (n) =>
                SlateElement.isElement(n) && Editor.isBlock(editor, n),
            }
          );
        }
      }

      // Handle tab key for table navigation
      if (event.key === "Tab") {
        event.preventDefault();
        const { selection } = editor;

        if (selection) {
          const [table] = Editor.nodes(editor, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === "table",
          });

          if (table) {
            const [cell] = Editor.nodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === "table-cell",
            });

            if (cell) {
              const [, cellPath] = cell;
              const nextPath = [
                ...cellPath.slice(0, -1),
                cellPath[cellPath.length - 1] + 1,
              ];

              try {
                const nextCell = Editor.node(editor, nextPath);
                if (nextCell) {
                  const point = Editor.start(editor, nextPath);
                  Transforms.select(editor, point);
                }
              } catch (error) {
                // If next cell doesn't exist, try moving to the next row
                try {
                  const nextRowPath = [
                    ...cellPath.slice(0, -2),
                    cellPath[cellPath.length - 2] + 1,
                    0,
                  ];
                  const nextRowCell = Editor.node(editor, nextRowPath);
                  if (nextRowCell) {
                    const point = Editor.start(editor, nextRowPath);
                    Transforms.select(editor, point);
                  }
                } catch (error) {
                  // Do nothing if we're at the last cell
                }
              }
            }
          }
        }
      }
    },
    [editor]
  );

  if (!props.defaultValue?.length) {
    return <>Wähle zunächst ein Kapitel aus der Liste</>;
  }

  return (
    <div>
      <div
        className="bg-white rounded-md shadow relative overflow-hidden"
        style={{ height: "75vh" }}
      >
        <Slate
          editor={editor}
          onChange={(content) => props.onBlur?.(content)}
          initialValue={props.defaultValue || initialValue}
        >
          <div className="sticky top-0 z-10 bg-white border-b">
            <Toolbar />
          </div>
          <div className="p-4 overflow-y-auto h-[calc(100%-48px)]">
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Start typing..."
              className="min-h-[200px] focus:outline-none"
              onKeyDown={onKeyDown}
            />
          </div>
        </Slate>
      </div>
    </div>
  );
};
