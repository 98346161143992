import React from "react";
import { useHistory } from "react-router";
import { TrashIcon } from "@heroicons/react/outline";
import { useMutation, useQueryClient } from "react-query";

import { AnnexSection, Directive } from "./types";
import { useClient } from "utils/client";
import TextInput from "components/inputs/TextInput";
import TextArea from "components/inputs/TextAreaInput";
import SelectInput from "components/inputs/SelectInput";
import { StandardSearch } from "components/StandardSearch";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { BasePage } from "components/BasePage/BasePage";
import { WysiwygInput } from "components/inputs/WysiwygInput";

const useNewAnnex = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate } = useMutation(
    (item: AnnexSection) => {
      return client.post("/annexSections", [item]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["questionaireItems"]);
        history.push("/_backoffice/_questionaire");
      },
    }
  );

  return mutate;
};

export const NewAnnex = () => {
  const createQuestion = useNewAnnex();
  const [section, setSection] = React.useState<string>("");
  const [fullText, setFullText] = React.useState<string>("");
  const [fullTextByLang, setFullTextByLang] = React.useState<
    Record<string, string>
  >({});

  const [refNumber, setRefNumber] = React.useState<string>("");
  const [directive, setDirective] = React.useState<Directive>(Directive.mrl);
  const [question, setQuestion] = React.useState<string>("");
  const [questionLang, setQuestionLang] = React.useState<string>("DE");
  const [isInformational, setIsInformational] = React.useState<boolean>(false);
  const [questionsByLang, setQuestionsByLang] = React.useState<
    Record<string, string>
  >({});
  const [suggestedStandards, setSuggestedStandards] = React.useState<string[]>(
    []
  );

  const [explanation, setExplanation] = React.useState<string>("");
  const [explanationsByLang, setExplanationsByLang] = React.useState<
    Record<string, string>
  >({});

  const [info, setInfo] = React.useState<string>("");
  const [infosByLang, setInfosByLang] = React.useState<Record<string, string>>(
    {}
  );

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        { label: "Questionaire", location: "/_backoffice/_questionaire" },
        { label: "Neue Anhangfrage", location: "" },
      ]}
    >
      <div>
        <h2 className="text-lg">Neue Anhangfrage</h2>
        <TextInput
          id="referenceNumber"
          label="Referenz-Nummer"
          // @ts-ignore
          value={refNumber}
          // @ts-ignore
          onBlur={(e) => {
            setRefNumber(e.target.value);
          }}
        />
        <SelectInput
          id="language"
          label="Sprache"
          options={[
            { value: "EN", label: "EN" },
            { value: "DE", label: "DE" },
          ]}
          // @ts-ignore
          value={questionLang}
          onBlur={(e) => {
            // @ts-ignore
            const language = e.target.value;
            setQuestion(questionsByLang[language] || "");
            setFullText(fullTextByLang[language] || "");
            setExplanation(explanationsByLang[language] || "");
            setInfo(infosByLang[language] || "");
            setQuestionLang(language);
          }}
        />

        <div className="py-2">
          <SelectInput
            required
            id="directive"
            name="directive"
            label="Welcher Richtlinie wird diese Frage zugeordnet?"
            options={[
              { value: Directive.lvd, label: "Niederspannungsrichtlinie" },
              { value: Directive.mrl, label: "Maschinenrichtlinie" },
              { value: Directive.mvo, label: "Maschinenverordnung" },
              { value: Directive.pressureequip, label: "Druckgeräte" },
              { value: Directive.pressurevessel, label: "Druckbehälter" },
              { value: Directive.rohs, label: "RoHS" },
              { value: Directive.atex, label: "Atex" },
              { value: Directive.outdoor, label: "Outdoor" },
              { value: Directive.emv, label: "EMV" },
            ]}
            defaultValue={directive}
            // @ts-ignore
            onBlur={(e) => {
              // @ts-ignore
              const directive = e.target.value as Directive;
              setDirective(directive);
            }}
          />
        </div>

        <div className="py-2">
          <input
            type="checkbox"
            id="scales"
            name="scales"
            checked={isInformational}
            onChange={() => setIsInformational(!isInformational)}
          />
          <label>
            {" "}
            Diese Anhangfrage wird nur zur Information in der EU
            Rechtsvorschriftenrecherche angezeigt
          </label>
        </div>

        <TextInput
          id="section"
          label="Anhang 1 Sektion"
          info="z.b. 1.1.5"
          // @ts-ignore
          onBlur={(e) => setSection(e.target.value)}
        />

        <TextInput
          id="question"
          label="Anforderung"
          // @ts-ignore
          defaultValue={question}
          // @ts-ignore
          onBlur={(e) => {
            setQuestion(e.target.value);
            setQuestionsByLang({
              ...questionsByLang,
              [questionLang]: e.target.value,
            });
          }}
        />

        <WysiwygInput
          id="fulltext"
          label="Anhang Sektion Volltext"
          defaultValue={fullText}
          onBlur={(content) => {
            setFullText(content);
            setFullTextByLang({
              ...fullTextByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="explanation"
          label="Erläuterung"
          defaultValue={explanation}
          onBlur={(content) => {
            setExplanation(content);
            setExplanationsByLang({
              ...explanationsByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="example"
          label="Beispiel"
          defaultValue={info}
          onBlur={(content) => {
            setInfo(content);
            setInfosByLang({
              ...infosByLang,
              [questionLang]: content,
            });
          }}
        />
      </div>

      <div className="p-4">
        <h2 className="text-lg">Vorgeschlagene Normen</h2>
        <div className="grid grid-cols-5 gap-4">
          {suggestedStandards?.map((s) => (
            <div
              className="relative rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm flex items-center justify-center space-x-3"
              key={s}
            >
              <span>{s}</span>
              <TrashIcon
                className="flex-shrink-0 h-4 text-red-500 hover:text-black cursor-pointer"
                onClick={() => {
                  const filteredFollowups = suggestedStandards!.filter(
                    (a) => a !== s
                  );
                  setSuggestedStandards(filteredFollowups);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <StandardSearch
        onSelect={(p) => setSuggestedStandards([...suggestedStandards, p.name])}
      />

      <div className="py-2 px-4">
        <PrimaryButton
          onClick={() =>
            createQuestion({
              directive,
              name: section,
              ref_number: refNumber,
              is_informational: isInformational,
              suggested_standards: suggestedStandards,
              questions: Object.keys(questionsByLang).map((lang) => {
                return {
                  language: lang,
                  content: questionsByLang[lang],
                };
              }),
              infos: Object.keys(infosByLang).map((lang) => {
                return {
                  language: lang,
                  content: infosByLang[lang],
                };
              }),
              explanations: Object.keys(explanationsByLang).map((lang) => {
                return {
                  language: lang,
                  content: explanationsByLang[lang],
                };
              }),
              fullTexts: Object.keys(fullTextByLang).map((lang) => {
                return {
                  language: lang,
                  content: fullTextByLang[lang],
                };
              }),
            })
          }
        >
          Frage hinzufügen
        </PrimaryButton>
      </div>
    </BasePage>
  );
};
