import { UploadType } from "components/Documents/types";
import { Risk, RiskGroupping } from "components/Risk/types";
import { Directive } from "pages/Backoffice/Questionaire/types";
import { Category } from "components/common/ProductCategorySelector";

export interface DeleteButtonProps {
  onClick: () => void;
  disabled?: boolean;
  flat?: boolean;
  fullWidth?: boolean;
  small?: boolean;
}

export interface SecondaryButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export interface PrimaryButtonProps {
  testId?: string;
  float?: "left" | "right";
  onClick: () => void;
  disabled?: boolean;
}

export interface ProjectData {
  id: string;
  crn: string;
  resource_id: string;
  type: string;
  cost_center: string;
  description: string;
  product_name: string;
  serial_number: string;
  version: number;
  category: Category;
  required_steps: Steps[];
  created_at: string;
  status: Status;
  standards: string[];
  completed_research: boolean;
  completed_toplevel_research: boolean;
}

export interface ConfirmationContextProps {
  openConfirmation: (
    onDelete: () => void,
    text: { label: string; subline: string }
  ) => void;
}

export interface ConfirmationProps {
  isConfirmationOpen: boolean;
  closeConfirmation: () => void;
  text: { label: string; subline: string } | null;
  deleteAndClose: () => void;
}

export interface Manufacturer {
  id: number;
  crn: string;
  resource_id: string;
  name: string;
  first_name: string; // TODO: REMOVE
  last_name: string; // TODO: REMOVE
  email_adress: string;
  country: string;
  street_address: string;
  city: string;
  state: string;
  post_code: string;
  created_at: string;
  company: string;
  ceo: string;
  address_additional: string;
  phone: string;
  website: string;
  contact_construction_name: string;
  contact_construction_email: string;
  contact_construction_phone: string;
  contact_electronics_name: string;
  contact_electronics_email: string;
  contact_electronics_phone: string;
  contact_fluids_name: string;
  contact_fluids_email: string;
  contact_fluids_phone: string;
  is_blueprint?: boolean;
}
export interface FormData {
  type: string;
  payload: string;
}

export interface DocumentData {
  id: number;
  name: string;
  type: UploadType;
  version: string;
  location: string;
  created_at: string;
}

export interface Engineer {
  city: string;
  phone: string;
  state: string;
  company: string;
  country: string;
  last_name: string;
  post_code: string;
  name: string;
  first_name: string; // TODO: REMOVE
  created_at: string;
  email_adress: string;
  street_address: string;
}

export interface Boundary {
  id: string;
  weight: string;
  width: string;
  depth: string;
  expected_usage: string;
  expected_misuse: string;
  additional_boundaries: { description: string; value: string }[];
}

export interface Standard {
  id: string;
  name: string;
}

export interface TeamMember {
  id: string;
  area: string;
  name: string;
  first_name: string; // TODO: REMOVE
  last_name: string; // TODO: REMOVE
  email: string;
  phone: string;
}

export interface TranslatedStandard {
  id: string;
  name: string;
  type: string;
  latest: string;
  content: string;
  versions: string[];
  retracted?: boolean;
  directive: Directive;
  lastRecorded: string;
}

export interface DirectiveData {
  name: Directive;
  completed_research: boolean;
}

export interface Project {
  engineer: Engineer;
  projectdata: ProjectData;
  documents: DocumentData[];
  directives: DirectiveData[];
  form: FormData;
  boundary: Boundary;
  riskGrouping: RiskGroupping[];
  risks: Risk[];
  standardsExist: boolean;
  manualExist: boolean;
  risksExist: boolean;
  lastUpdated: string;
  manufacturer: Manufacturer;
  team: TeamMember[];
  languages: { code: string }[];
  standards: string[];
}

export interface APIProjectDataRequest {
  engineer: Engineer;
  projectdata: ProjectData;
  documents: DocumentData[];
  form: FormData;
}

export interface Country {
  name: {
    common: string;
  };
  translations: {
    [key: string]: {
      common: string;
    };
  };
}

export enum Steps {
  basedata = "basedata",
  documents = "documents",
  standards = "standards",
  questionaire = "questionaire",
  questionaireMrl = "questionaireMrl",
  questionaireMvo = "questionaireMvo",
  questionaireLvd = "questionaireLvd",
  boundary = "boundary",
  manual = "manual",
  risks = "risks",
}

export enum Status {
  archived = "archived",
  wip = "wip",
  done = "done",
}

export enum Translations {
  english = "eng",
  german = "deu",
  portuguese = "por",
}

export enum Languages {
  english = "en",
  german = "de",
}

export interface Column {
  label: string;
  name: string;
  sortable: boolean;
  start?: boolean;
}

export interface SortableTableProps<T> {
  data: T[];
  columns: Column[];
  sort: (sortOrder: string, sortColumn: string) => T[];
  renderRow: (row: T, index: number) => React.ReactNode;
}

export enum Sorting {
  asc = "asc",
  desc = "desc",
}

export enum ProjectColumn {
  project = "project",
  manufacturer = "manufacturer",
  category = "category",
  status = "status",
  last_update = "last_update",
}
export enum ManufacturerColumn {
  manufacturer = "manufacturer",
  city = "city",
}

export interface ColumnSortConfig<T> {
  accessor: (entity: T) => string;
}

export interface CategoryMappingEntry {
  translation: string;
  value: Category;
}

export interface Mapping {
  [key: string]: CategoryMappingEntry[];
}
