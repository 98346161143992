import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { DeleteButton } from "components/Button/DeleteButton";
import { useTranslation } from "react-i18next";
import { ConfirmationProps } from "types";

const Confirmation = ({
  isConfirmationOpen,
  closeConfirmation,
  text,
  deleteAndClose,
}: ConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <Transition appear show={isConfirmationOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white  shadow-xl transition-all items-center p-5 relative">
                <XIcon
                  onClick={closeConfirmation}
                  className="text-gray-400 w-4 h-4 cursor-pointer hover:text-gray-600 hover:bg-gray-200 hover:bg-opacity-50 absolute top-3 right-3 "
                />
                <div className="flex flex-row content-start gap-2">
                  <ExclamationIcon className="h-8 w-8 text-red-500" />
                  <div className="flex-1">
                    <p className="font-bold ">{text?.label}</p>
                    <p className="text-sm">{text?.subline}</p>
                  </div>
                </div>
                <div className="flex flex-row justify-end mt-8 gap-2">
                  <PrimaryButton onClick={closeConfirmation}>
                    {t("steps.risks.confirmation.cancel")}
                  </PrimaryButton>
                  <DeleteButton onClick={deleteAndClose}>
                    {t("dashboard.table.delete")}
                  </DeleteButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Confirmation;
