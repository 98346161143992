import * as React from "react";
import { useTranslation } from "react-i18next";

import Form from "forms/Form";

import { Risk } from "components/Risk/types";
import { useRiskGroups } from "pages/Risk/hooks/useRiskGroups";
import { FormCb } from "components/Risk/formdata";
import { useUpdateRiskTemplate } from "pages/Risk/hooks/useRisks";
import {
  buildBackofficeRiskFormData,
  handleBackofficeRiskGroupChange,
} from "./formdata";

interface RiskEditProps {
  risk: Risk;
}

export const BackofficeRiskEdit: React.FunctionComponent<RiskEditProps> = ({
  risk,
}) => {
  const { t } = useTranslation();
  const { riskGroups } = useRiskGroups();
  const mutate = useUpdateRiskTemplate(risk.resource_id);
  const [formData, setFormData] = React.useState<any>();

  React.useEffect(() => {
    if (!riskGroups || !risk) {
      return;
    }

    const formdata = buildBackofficeRiskFormData(
      riskGroups,
      (key: string) => t(key),
      risk.group_id
    );
    formdata.sections[1].count = risk.mitigations.length;
    setFormData(formdata);
  }, [riskGroups, t, risk]);

  if (!formData) {
    return null;
  }

  const handleChange = (form: any) => {
    const selectedRiskGroup = riskGroups?.find(
      (rg) => rg.riskGroup.name === form.risk.group_id
    )!;

    const newForm = handleBackofficeRiskGroupChange(
      selectedRiskGroup,
      (key: string) => t(key)
    );
    setFormData(newForm);
  };

  const { mitigations, ...restrisk } = risk;

  return (
    <div>
      <Form
        formData={formData}
        onChange={handleChange}
        saveText={"Gefährdung speichern"}
        defaultValues={{ mitigations, risk: restrisk }}
        onSubmit={async (v) => mutate(v as FormCb)}
      />
    </div>
  );
};
