import React from "react";
import { useTranslation } from "react-i18next";

import { Manufacturer } from "types";
import { Modal } from "components/Modal/Modal";
import TextInput from "components/inputs/TextInput";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useCreateManufacturer } from "./hooks/useCreateManufacturer";

interface CreateProjectOnDashboardProps {
  button: React.FunctionComponent<React.ButtonHTMLAttributes<any>>;
}

export const CreateManufacturerOnDashboard: React.FunctionComponent<
  CreateProjectOnDashboardProps
> = (props) => {
  const { t } = useTranslation();
  const create = useCreateManufacturer();

  const [payload, setPayload] = React.useState({ name: "" });
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <>
      <div className="mt-4">
        <props.button onClick={() => setModalVisible(true)} />
      </div>

      <>
        <Modal
          onClose={() => setModalVisible(false)}
          show={modalVisible}
          width="1/2"
          content={
            <div>
              <span className="text-xl">
                {t("form.manufacturer.create_new")}
              </span>
              <div className="pt-4">
                <TextInput
                  id="manufacturer-name"
                  required
                  uncontrolled
                  label={t("form.manufacturer.manufacturerName")}
                  // @ts-ignore
                  onChange={(e) => {
                    setPayload({ name: e.target.value });
                  }}
                  onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                    if (evt.key === "Enter") {
                      payload.name &&
                        create({
                          manufacturer: {
                            company: payload.name,
                          } as Manufacturer,
                        });
                    }
                  }}
                />
              </div>

              <PrimaryButton
                float="right"
                disabled={!payload.name}
                onClick={() =>
                  payload.name &&
                  create({
                    manufacturer: { company: payload.name } as Manufacturer,
                  })
                }
              >
                {t("form.manufacturer.save")}
              </PrimaryButton>
            </div>
          }
        />
      </>
    </>
  );
};
