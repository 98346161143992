import React, { PropsWithChildren } from "react";
import { classNames } from "utils/classNames";
import { PrimaryButtonProps } from "types";

export const PrimaryButton: React.FunctionComponent<
  PropsWithChildren<PrimaryButtonProps>
> = (props) => {
  return (
    <button
      data-test={`${props.testId}-cta`}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      className={classNames(
        !props.disabled
          ? "hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          : "",
        props.float ? `float-${props.float}` : "",
        "disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
      )}
    >
      {props.children}
    </button>
  );
};
