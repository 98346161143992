import React from "react";
import { useParams } from "react-router";
import { TrashIcon } from "@heroicons/react/outline";

import {
  useEditAnnex,
  useDeleteAnnex,
  useAnnexSection,
  useAnnexSections,
} from "./hooks/useAnnexSections";
import { useQuestionaireItems } from "./hooks";
import { useTranslatedStandards } from "hooks/useStandards";
import TextInput from "components/inputs/TextInput";
import SelectInput from "components/inputs/SelectInput";
import { BasePage } from "components/BasePage/BasePage";
import { StandardSearch } from "components/StandardSearch";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { SecondaryButton } from "components/Button/SecondaryButton";
import { WysiwygInput } from "components/inputs/WysiwygInput";
import { Directive } from "./types";

interface RouteParams {
  annexId: string;
}

export const EditAnnex = () => {
  const questionaireItems = useQuestionaireItems();
  const { annexSections, isLoading } = useAnnexSections();
  const { standards } = useTranslatedStandards();
  const { annexId } = useParams<RouteParams>();
  const annexSection = useAnnexSection(annexId);

  const updateAnnex = useEditAnnex(annexId);
  const deleteAnnex = useDeleteAnnex(annexId);

  const [section, setSection] = React.useState<string>(
    annexSection?.name || ""
  );
  const [directive, setDirective] = React.useState<Directive | null>();
  const [isInformational, setIsInformational] = React.useState<boolean>(false);
  const [fullText, setFullText] = React.useState<string>("");
  const [fullTextByLang, setFullTextByLang] = React.useState<
    Record<string, string>
  >({});

  const [question, setQuestion] = React.useState<string>("");
  const [questionLang, setQuestionLang] = React.useState<string>("");
  const [questionsByLang, setQuestionsByLang] = React.useState<
    Record<string, string>
  >({});
  const [suggestedStandards, setSuggestedStandards] = React.useState<string[]>(
    []
  );

  const [explanation, setExplanation] = React.useState<string>("");
  const [explanationsByLang, setExplanationsByLang] = React.useState<
    Record<string, string>
  >({});

  const [refNumber, setRefNumber] = React.useState<string>("");
  const [info, setInfo] = React.useState<string>("");
  const [infosByLang, setInfosByLang] = React.useState<Record<string, string>>(
    {}
  );

  React.useEffect(() => {
    if (!annexSection) return;

    const gByLang =
      annexSection?.questions?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const fullByLang =
      annexSection?.fullTexts?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const exByLang =
      annexSection?.explanations?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    const infoByLang =
      annexSection?.infos?.reduce((acc, q) => {
        acc[q.language] = q.content;
        return acc;
      }, {} as Record<string, string>) || {};

    setSuggestedStandards(annexSection?.suggested_standards || []);

    setQuestion(
      (annexSection?.questions?.length &&
        annexSection?.questions.find((e) => e.language === "DE")?.content) ||
        ""
    );
    setIsInformational(annexSection?.is_informational);
    setDirective(annexSection?.directive || Directive.mrl);
    setFullText(
      (annexSection?.fullTexts?.length &&
        annexSection?.fullTexts.find((e) => e.language === "DE")?.content) ||
        ""
    );
    setInfo(
      (annexSection?.infos?.length &&
        annexSection?.infos.find((e) => e.language === "DE")?.content) ||
        ""
    );
    setExplanation(
      (annexSection?.explanations?.length &&
        annexSection?.explanations.find((e) => e.language === "DE")?.content) ||
        ""
    );
    setQuestionsByLang(gByLang);
    setFullTextByLang(fullByLang);
    setQuestionLang("DE");
    setRefNumber(annexSection.ref_number);
    setInfosByLang(infoByLang);
    setExplanationsByLang(exByLang);
  }, [annexSection, standards]);

  const loading =
    !annexSection ||
    !questionaireItems?.length ||
    !annexSections ||
    !standards?.length ||
    isLoading;

  return (
    <BasePage
      loading={loading}
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        { label: "Questionaire", location: "/_backoffice/_questionaire" },
        { label: "Anhangfrage bearbeiten", location: "" },
      ]}
    >
      <div>
        <h2 className="text-lg">Anhangfrage bearbeiten</h2>
        <SecondaryButton onClick={deleteAnnex}>Frage Löschen</SecondaryButton>
        <TextInput
          id="referenceNumber"
          label="Referenz-Nummer"
          // @ts-ignore
          defaultValue={refNumber}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            setRefNumber(e.target.value);
          }}
        />
        <SelectInput
          id="language"
          label="Sprache"
          options={[
            { value: "EN", label: "EN" },
            { value: "DE", label: "DE" },
          ]}
          // @ts-ignore
          value={questionLang}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            const language = e.target.value;
            setQuestion(questionsByLang[language] || "");
            setFullText(fullTextByLang[language] || "");
            setExplanation(explanationsByLang[language] || "");
            setInfo(infosByLang[language] || "");
            setQuestionLang(language);
          }}
        />

        <div className="py-2">
          <SelectInput
            required
            id="directive"
            name="directive"
            label="Welcher Richtlinie wird diese Frage zugeordnet?"
            options={[
              { value: Directive.lvd, label: "Niederspannungsrichtlinie" },
              { value: Directive.mrl, label: "Maschinenrichtlinie" },
              { value: Directive.mvo, label: "Maschinenverordnung" },
              { value: Directive.pressureequip, label: "Druckgeräte" },
              { value: Directive.pressurevessel, label: "Druckbehälter" },
              { value: Directive.rohs, label: "RoHS" },
              { value: Directive.atex, label: "Atex" },
              { value: Directive.outdoor, label: "Outdoor" },
              { value: Directive.emv, label: "EMV" },
            ]}
            defaultValue={directive || Directive.mrl}
            // @ts-ignore
            onBlur={(e) => {
              // @ts-ignore
              const directive = e.target.value as Directive;
              setDirective(directive);
            }}
          />
        </div>

        <TextInput
          id="section"
          label="Anhang 1 Sektion"
          info="z.b. 1.1.5"
          uncontrolled
          defaultValue={annexSection?.name}
          // @ts-ignore
          onBlur={(e) => setSection(e.target.value)}
        />

        <TextInput
          id="question"
          label="Anforderung"
          uncontrolled={false}
          defaultValue={question}
          // @ts-ignore
          onBlur={(e) => {
            // @ts-ignore
            setQuestion(e.target.value);
            setQuestionsByLang({
              ...questionsByLang,
              [questionLang]: e.target.value,
            });
          }}
        />

        <WysiwygInput
          id="fulltext"
          label="Anhang Sektion Volltext"
          defaultValue={fullText}
          onBlur={(content) => {
            setFullText(content);
            setFullTextByLang({
              ...fullTextByLang,
              [questionLang]: content,
            });
          }}
        />
        <WysiwygInput
          id="explanation"
          label="Erläuterung"
          defaultValue={explanation}
          onBlur={(content) => {
            setExplanation(content);
            setExplanationsByLang({
              ...explanationsByLang,
              [questionLang]: content,
            });
          }}
        />

        <WysiwygInput
          id="example"
          label="Beispiel"
          defaultValue={info}
          onBlur={(content) => {
            setInfo(content);
            setInfosByLang({
              ...infosByLang,
              [questionLang]: content,
            });
          }}
        />

        <div className="py-2">
          <input
            type="checkbox"
            id="scales"
            name="scales"
            checked={isInformational}
            onChange={() => setIsInformational(!isInformational)}
          />
          <label>
            {" "}
            Diese Anhangfrage wird nur zur Information in der EU
            Rechtsvorschriftenrecherche angezeigt
          </label>
        </div>
      </div>

      <StandardSearch
        onSelect={(p) => setSuggestedStandards([...suggestedStandards, p.name])}
      />

      <div className="p-4">
        <h2 className="text-lg">Vorgeschlagene Normen</h2>
        <div className="grid grid-cols-5 gap-4">
          {suggestedStandards?.map((s) => (
            <div
              className="relative rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm flex items-center justify-center space-x-3"
              key={s}
            >
              <span>{s}</span>
              <TrashIcon
                className="flex-shrink-0 h-4 text-red-500 hover:text-black cursor-pointer"
                onClick={() => {
                  const filteredFollowups = suggestedStandards!.filter(
                    (a) => a !== s
                  );
                  setSuggestedStandards(filteredFollowups);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="py-2 px-4">
        <PrimaryButton
          onClick={() =>
            updateAnnex({
              ...annexSection,
              directive: directive!,
              ref_number: refNumber,
              is_informational: isInformational,
              name: section || annexSection!.name,
              suggested_standards: suggestedStandards,
              questions: Object.keys(questionsByLang).map((lang) => {
                return {
                  ...annexSection?.questions?.find((q) => q.language === lang),
                  language: lang,
                  content: questionsByLang[lang],
                };
              }),
              infos: Object.keys(infosByLang).map((lang) => {
                return {
                  ...annexSection?.infos?.find((q) => q.language === lang),
                  language: lang,
                  content: infosByLang[lang],
                };
              }),
              explanations: Object.keys(explanationsByLang).map((lang) => {
                return {
                  ...annexSection?.explanations?.find(
                    (q) => q.language === lang
                  ),
                  language: lang,
                  content: explanationsByLang[lang],
                };
              }),
              fullTexts: Object.keys(fullTextByLang).map((lang) => {
                return {
                  ...annexSection?.fullTexts?.find((q) => q.language === lang),
                  language: lang,
                  content: fullTextByLang[lang],
                };
              }),
            })
          }
        >
          Änderungen speichern
        </PrimaryButton>
      </div>
    </BasePage>
  );
};
