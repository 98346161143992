import * as React from "react";
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import Form from "forms/Form";
import { useClient } from "utils/client";
import { useTranslation } from "react-i18next";
import { ControlContext } from "context/Controls";

import {
  FormCb,
  defaultVal,
  buildRiskFormData,
  handleRiskGroupChange,
} from "./formdata";
import { useRiskGroups } from "pages/Risk/hooks/useRiskGroups";
import { TrackingContext } from "context/Tracking";
import { Risk } from "./types";

export const postNewRisk = async (
  client: AxiosInstance,
  { risk, mitigations }: FormCb,
  projectId: string
) => {
  try {
    const { data } = await client.post<Risk>(`/projects/${projectId}/risks`, {
      ...risk,
      assessment: {
        impact: risk.impact,
        people_count: risk.people_count,
        duration: risk.duration,
        probability: risk.probability,
        detectability: risk.detectability,
      },
      mitigations: mitigations,
    });

    return data;
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }
};

const postNewTemplateRisk = async (
  client: AxiosInstance,
  { risk, mitigations }: FormCb
) => {
  try {
    await client.post(`/backoffice/risks`, {
      ...risk,
      is_blueprint: true,
      assessment: {
        impact: risk.impact,
        people_count: risk.people_count,
        duration: risk.duration,
        probability: risk.probability,
        detectability: risk.detectability,
      },
      mitigations: mitigations,
    });
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }

  return risk;
};

interface RiskCreateProps {
  projectId: string;
  isTemplate?: boolean;
}

export const RiskCreate: React.FunctionComponent<RiskCreateProps> = ({
  projectId,
  isTemplate,
}) => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { riskGroups } = useRiskGroups();
  const { track } = React.useContext(TrackingContext);
  const { displayNotification } = React.useContext(ControlContext);
  const [formData, setFormData] = React.useState<any>();

  const { mutate } = useMutation(
    async (v: FormCb) => {
      if (isTemplate) {
        return await postNewTemplateRisk(client, v);
      }

      return await postNewRisk(client, v, projectId);
    },
    {
      onError: (e) =>
        displayNotification!(
          "Error creating Risk",
          (e as Error).message,
          "error"
        ),
      onSuccess: (newRisk) => {
        if (isTemplate) {
          queryClient.refetchQueries(["backoffice", "risks"]);
          history.push(`/_backoffice/_risks`);
          return;
        }

        queryClient.refetchQueries(["risks", projectId]);
        queryClient.refetchQueries(["projects", projectId]);

        history.push(`/projects/${projectId}/risks?saved=true`);

        track("create risk");

        displayNotification!(
          "Risiko erzeugt",
          `Risk "${newRisk.description}" was created`
        );
      },
    }
  );

  React.useEffect(() => {
    if (!riskGroups) {
      return;
    }

    const formdata = buildRiskFormData(
      riskGroups,
      (key: string) => t(key),
      riskGroups[0].riskGroup.name
    );
    setFormData(formdata);
  }, [riskGroups, t]);

  if (!formData) {
    return null;
  }

  const handleChange = (form: any) => {
    const selectedRiskGroup = riskGroups?.find(
      (rg) => rg.riskGroup.name === form.risk.group_id
    )!;

    const newForm = handleRiskGroupChange(selectedRiskGroup, (key: string) =>
      t(key)
    );
    setFormData(newForm);
  };

  return (
    <div>
      <Form
        formData={formData}
        onChange={handleChange}
        defaultValues={defaultVal}
        saveText={"Gefährdung speichern"}
        onSubmit={async (v) => mutate(v as FormCb)}
      />
    </div>
  );
};
