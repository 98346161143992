import {
  FeedbackLocation,
  FeedbackPopup,
} from "components/Feedback/FeedbackPopup";
import { useFeedbackStorage } from "components/Feedback/hooks/useFeedbackStorage";
import { Modal } from "components/Modal/Modal";
import React, { PropsWithChildren } from "react";

interface IFeedbackContext {
  displayFeedback: (location: FeedbackLocation) => void;
}

export const FeedbackContext = React.createContext<Partial<IFeedbackContext>>(
  {}
);

export const FeedbackProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { getRead } = useFeedbackStorage();
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState<{
    location: FeedbackLocation;
  }>({
    location: FeedbackLocation.None,
  });

  const displayFeedback = (location: FeedbackLocation) => {
    const isRead = getRead(location);
    if (!isRead) {
      setShowModal(true);
      setModalData({ location });
    }
  };

  const handleClose = () => setShowModal(false);

  return (
    <FeedbackContext.Provider value={{ displayFeedback }}>
      {children}

      {false && showModal && (
        <Modal
          show={showModal}
          content={
            <FeedbackPopup
              onClose={handleClose}
              location={modalData.location}
            />
          }
          onClose={handleClose}
        />
      )}
    </FeedbackContext.Provider>
  );
};
