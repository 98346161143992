import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { useClient } from "utils/client";
import { Directive } from "./types";

export interface QuestionaireContent {
  id?: string;
  content: string;
  language: string;
}

export interface QuestionaireItem {
  id?: number;
  resource_id?: string;
  article?: string;
  comment?: string;
  ref_number: string;
  followups: string[];
  directive: Directive;
  is_first_level: boolean;
  annex_sections: string[];
  is_exit_question: boolean;
  is_directive_question: boolean;
  product_category: string | null;
  infos?: QuestionaireContent[];
  questions: QuestionaireContent[];
  explanations?: QuestionaireContent[];
  originalTexts1?: QuestionaireContent[];
  originalTexts2?: QuestionaireContent[];
}

export interface QuestionaireSelectionResponse {
  projectId: string;
  questionaires: QuestionaireSelectionResponseQuestionaire[];
}

export interface QuestionaireSelectionResponseQuestionaire {
  questionaireId: string;
  isSelected: boolean;
}

const fetchQuestionaireItems = async (client: AxiosInstance) => {
  const { data } = await client.get<QuestionaireItem[]>("/questionaireItems");
  data?.sort((a, b) => a.id! - b.id!);

  return data;
};

const fetchQuestionaireItemsForProject = async (
  client: AxiosInstance,
  projectId: string
) => {
  const { data } = await client.get<QuestionaireSelectionResponse>(
    `projects/${projectId}/questionaireItems`
  );

  return data;
};

const fetchOneQuestionaireItem = async (
  client: AxiosInstance,
  questionaireId: string
) => {
  const { data } = await client.get<QuestionaireItem>(
    `/questionaireItems/${questionaireId}`
  );

  return data;
};

export const useQuestionaireItems = () => {
  const client = useClient();
  const [prevData, setPrevData] = useState<QuestionaireItem[]>([]);
  const { data = [], ...rest } = useQuery(["questionaireItems"], () =>
    fetchQuestionaireItems(client)
  );

  useEffect(() => {
    if (data?.length <= prevData.length) {
      return;
    }

    setPrevData(data);
  }, [data, setPrevData, prevData.length]);

  return prevData;
};

export const useQuestionaireItemsForProject = (projectId: string) => {
  const client = useClient();

  const { data, isLoading } = useQuery(["questionaireItems", projectId], () =>
    fetchQuestionaireItemsForProject(client, projectId)
  );

  return { questionaireItems: data, isLoading };
};

export const useQuestionaireItem = (questionaireId: string) => {
  const client = useClient();

  const { data } = useQuery(["questionaireItem", questionaireId], () =>
    fetchOneQuestionaireItem(client, questionaireId)
  );

  return data;
};
