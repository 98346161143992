import React from "react";
import { useHistory } from "react-router";
import _axios, { AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { Manufacturer } from "types";
import { useClient } from "utils/client";
import { ControlContext } from "context/Controls";
import { ImageUploads } from "pages/Project/hooks/useCreateProject";

const updateManufacturer = async (
  client: AxiosInstance,
  manufacturer: { manufacturer: Manufacturer; _uploads: ImageUploads },
  manufacturerId: string
) => {
  await client.put(
    `/manufacturers/${manufacturerId}`,
    manufacturer.manufacturer
  );

  if (manufacturer._uploads["manufacturer_logo"]) {
    const file = manufacturer._uploads["manufacturer_logo"];
    const uploadData = await client.post(
      `/manufacturers/${manufacturerId}/logo`,
      {
        fileName: file.key,
      }
    );

    await _axios.put(uploadData.data.signedRequest, file.image, {
      headers: {
        "Content-Type": file.image.type,
      },
    });
  }

  return manufacturer;
};

export const useUpdateManufacturer = (manufacturerId: string) => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate } = useMutation(
    async (v: { manufacturer: Manufacturer; _uploads: ImageUploads }) =>
      await updateManufacturer(client, v, manufacturerId),
    {
      onError: (e) =>
        displayNotification!(
          "Error creating Manufacturer",
          (e as Error).message,
          "error"
        ),
      onSuccess: (manufacturer) => {
        queryClient.refetchQueries(["manufacturers"]);
        history.push(`/manufacturers`);
        displayNotification!(
          "Hersteller erfolgreich geupdated",
          `"${manufacturer.manufacturer.company}" wurde aktualisiert`
        );
      },
    }
  );

  return mutate;
};
