import React from "react";

import axios from "utils/client";

import { StarRating } from "./StarRating";
import TextArea from "components/inputs/TextAreaInput";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useFeedbackStorage } from "./hooks/useFeedbackStorage";

export enum FeedbackLocation {
  None = "none",
  Risk = "risk",
  Boundary = "boundary",
  Documents = "documents",
  Questionaire = "questionaire",
  Questionaire1 = "questionaireStep1",
  Questionaire2 = "questionaireStep2",
  Questionaire3 = "questionaireStep3",
  Questionaire4 = "questionaireStep4",
  BaseInformation = "baseinformation",
}

interface FeedbackPopupProps {
  location: FeedbackLocation;
  onClose: () => void;
}

interface FeedbackContent {
  title: string;
  subline: string;
}

const FeedbackContentMap: Record<FeedbackLocation, FeedbackContent> = {
  [FeedbackLocation.BaseInformation]: {
    title: "Feedback zu den Basisdaten",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Boundary]: {
    title: "Feedback zu den Grenzen",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Questionaire]: {
    title: "Abschließendes Feedback zu der EU Rechtsvorschriftenrecherche",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Questionaire1]: {
    title: "Feedback zu den Ausschlussfragen",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Questionaire2]: {
    title: "Feedback zu der Produktkategorie-Auswahl",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Questionaire3]: {
    title: "Feedback zu der Vorauswahl des Anhang III",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Questionaire4]: {
    title: "Feedback zu der Auswahl der relevanten Sektionen des Anhang III",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Risk]: {
    title: "Feedback zu der Risikobeurteilung",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.Documents]: {
    title: "Feedback zu den erzeugten Dokumenten",
    subline: "Hier ein wenig Kontext zu Feedback",
  },
  [FeedbackLocation.None]: { title: "", subline: "" },
};

export const FeedbackPopup = (props: FeedbackPopupProps) => {
  const { markRead } = useFeedbackStorage();
  const content = FeedbackContentMap[props.location];
  const [feedback, setFeedback] = React.useState<string>("");
  const [starRating, setStarRating] = React.useState<number>(0);

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-xl">{content.title}</h2>
        <p>{content.subline}</p>
      </div>

      <div className="mb-4">
        <StarRating onChange={setStarRating} />
      </div>

      <div>
        <TextArea
          required
          id="feedback"
          name="feedback"
          label="Zusätzliches Feedback"
          placeholder="Zusätzliches Feedback hier..."
          onChange={({ target }) => setFeedback(target.value)}
        ></TextArea>
        <div className="pt-9 text-right">
          <a
            className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
            onClick={props.onClose}
          >
            Ohne Feedback fortfahren
          </a>
          <PrimaryButton
            onClick={async () => {
              await axios.post("/feedback", {
                location: props.location,
                feedback: `${starRating} Stars|${feedback}`,
              });

              markRead(props.location);
              props.onClose();
            }}
          >
            Feedback absenden
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
