import { PrimaryButton } from "components/Button/PrimaryButton";
import { useUser } from "pages/Settings/hooks/useUser";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

export const UserTab = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("form.user.label")}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {t("form.user.subline")}
        </p>
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.company")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.company || "-"}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.name")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.name || "-"}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.mail")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user?.email_adress || "-"}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.street")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user?.street_address || "-"}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.post_code")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user?.post_code || "-"}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.city")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.city || "-"}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.state")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.state || "-"}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.country")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.country || "-"}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            {t("form.user.phone")}
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.phone || "-"}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Certain Resource Name{" "}
            <a className="text-cyan-600 text-xs cursor-pointer italic">(CRN)</a>
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{user?.crn}</dd>
        </div>
      </dl>
      <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-">
        <div className="flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-2">
          <PrimaryButton onClick={() => history.push("/settings/edit")}>
            {t("form.user.edit")}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
