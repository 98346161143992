import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";
import { DebounceInput } from "react-debounce-input";
import { DeleteButton } from "components/Button/DeleteButton";
import { useConfirmation } from "context/Confirmation";
import { SelectInputProps } from "./types";
import { TranslatedStandard } from "types";
import { useAllTranslatedStandards } from "hooks/useStandards";
import { getRelativeTime } from "utils/date";

const BASE_MACHINE_STANDARD = "EN ISO 12100:2010";

const StandardsSelectInput: React.FunctionComponent<SelectInputProps> = (
  props
) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selection, setSelection] = React.useState<TranslatedStandard[]>([]);
  const [isFocused, setIsFocused] = React.useState(false);

  const { translations } = useAllTranslatedStandards();
  const { values, setFieldValue } = useFormikContext();
  const { openConfirmation } = useConfirmation();

  const focusHandler = () => setIsFocused(true);
  const blurHandler = () => setTimeout(() => setIsFocused(false), 200);

  React.useEffect(() => {
    const idx = parseInt(props.id.match(/\[([0-9]{1,2})\]/)?.pop()!);
    const hasDefaultStandard =
      // @ts-ignore
      values["mitigations"][idx]["applied_standards"]?.includes(
        BASE_MACHINE_STANDARD
      );

    if (!hasDefaultStandard) {
      setFieldValue(props.id, [BASE_MACHINE_STANDARD]);
    }
  }, [values, props.id, setFieldValue]);

  React.useEffect(() => {
    if (!translations) return;
    const idx = parseInt(props.id.match(/\[([0-9]{1,2})\]/)?.pop()!);

    setSelected(
      // @ts-ignore
      [...(values["mitigations"][idx]["applied_standards"] || [])]
    );
  }, [values, props.id, translations]);

  const searchmatches = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.toLowerCase();
      const regexVal = val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      let sel = translations!.filter((set) => {
        return (
          set.content.toLowerCase().match(regexVal) ||
          set.name.toLowerCase().match(regexVal)
        );
      });

      if (val === "") {
        sel = [];
      }

      setSelection(sel);
      setValue(val);
    },
    [translations]
  );

  const deleteStandard = (standardName: string) => {
    const newSelected = selected.filter((s) => s !== standardName);

    setSelected(newSelected);

    props.onBlur!({
      // @ts-ignore
      target: { value: newSelected.map(({ name }) => name) },
    });
  };

  const handleStandardClick = React.useCallback(
    (standardName: string) => {
      const selectedStandard = translations!.find(
        (s) => s.name === standardName
      );
      const prevSelectedNames = selected?.map((s) => s);

      if (!prevSelectedNames.includes(standardName)) {
        const newSelected = [
          ...selected,
          [selectedStandard!.name, selectedStandard!.latest].join(":"),
        ];

        props.onBlur!({
          // @ts-ignore
          target: { value: newSelected },
        });
      }
    },
    [props.onBlur, selected, translations]
  );

  const handleDelete = (f: string) => {
    openConfirmation(() => deleteStandard(f), {
      label: t("form.basic.label"),
      subline: t("standards.search.table.delete_confirmation_subline"),
    });
  };

  return (
    <div className="col-span-6 ">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
      </div>
      <div className="relative">
        <StandardSearchInput
          onChange={searchmatches}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
        {isFocused && selection.length > 0 && (
          <div className="rounded-md border border-cyan-600 mt-2 bg-white">
            <ul className="divide-y divide-gray-200 max-h-56 overflow-y-scroll">
              {selection.length > 0 &&
                selection.map((el) => (
                  <div
                    key={el.name}
                    onClick={() => handleStandardClick(el.name)}
                  >
                    <li
                      className={`py-4 flex cursor-pointer hover:bg-cyan-300 ${
                        el.retracted ? "opacity-60" : ""
                      }`}
                    >
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          <Highlighter
                            className={`${el.retracted ? "line-through" : ""}`}
                            highlightClassName="bg-cyan-600 text-white px-1"
                            searchWords={[value]}
                            autoEscape={true}
                            textToHighlight={[el.name, el.latest].join(":")}
                          />
                          {el.retracted && (
                            <span className="text-xs text-gray-500">
                              {" "}
                              Zurückgezogen,
                            </span>
                          )}
                          <span className="text-xs text-gray-500">
                            {" "}
                            (
                            {getRelativeTime(
                              new Date(el.lastRecorded).getTime()
                            )}{" "}
                            auf Aktualität überprüft)
                          </span>
                        </p>
                        <p className="text-sm text-gray-500">
                          <Highlighter
                            highlightClassName="bg-cyan-600 text-white px-1"
                            searchWords={[value]}
                            autoEscape={true}
                            textToHighlight={el.content}
                          />
                        </p>
                        <p className="text-sm">
                          <span>Versionen der Norm: </span>
                          <span className="text-gray-500">
                            {el.versions.join(", ")}
                          </span>
                        </p>
                      </div>
                    </li>
                  </div>
                ))}
            </ul>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-2">
        {selected?.map((f) => (
          <div
            className="relative rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm flex items-center justify-center space-x-3"
            key={f}
          >
            <span className="text-xs">{f}</span>
            <DeleteButton onClick={() => handleDelete(f)} flat small />
          </div>
        ))}
      </div>
    </div>
  );
};

const StandardSearchInput = (props: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <DebounceInput
            type="text"
            minLength={3}
            id="search_candidate"
            name="search_candidate"
            debounceTimeout={300}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={props.onChange}
            placeholder={t("standards.search.inputPlaceholder")}
            className="focus:ring-cyan-500 focus:border-cyan-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
          />
        </div>
      </div>
    </div>
  );
};

export default StandardsSelectInput;
