import React from "react";
import { useTranslation } from "react-i18next";

import { TextAreaProps } from "./types";
import Collapsible from "components/Collapsible/Collapsible";

const TextArea: React.FunctionComponent<TextAreaProps> = (props) => {
  const { collapsibleItems, uncontrolled, ...rest } = props;
  const { t } = useTranslation();

  return (
    <div className="col-span-6">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>
      <div className="mt-1">
        <textarea
          {...rest}
          id={props.id}
          name={props.id}
          rows={3}
          className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
          placeholder={t(props.placeholder || "")}
        ></textarea>
      </div>
      {props.info && (
        <p className="mt-2 text-sm text-gray-500">{t(props.info)}</p>
      )}
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextArea;
