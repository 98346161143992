import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react";
import { ConfirmationContextProps } from "types";
import Confirmation from "components/Modal/Confirmation";
import { useLocation } from "react-router";

const ConfirmationContext = createContext<ConfirmationContextProps | undefined>(
  undefined
);

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error(
      "useConfirmation must be used within a ConfirmationProvider"
    );
  }
  return context;
};

export const ConfirmationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [onDeleteCallback, setOnDeleteCallback] = useState<(() => void) | null>(
    null
  );

  const [confirmationText, setConfirmationText] = useState<{
    label: string;
    subline: string;
  } | null>(null);

  useEffect(() => {
    setIsConfirmationOpen(false);
  }, [location]);

  const openConfirmation = (
    onDelete: () => void,
    text: { label: string; subline: string }
  ) => {
    setOnDeleteCallback(() => onDelete);
    setConfirmationText(text);
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
    setOnDeleteCallback(null);
  };

  const deleteAndClose = () => {
    if (onDeleteCallback) {
      onDeleteCallback();
    }
    closeConfirmation();
  };

  return (
    <ConfirmationContext.Provider value={{ openConfirmation }}>
      {children}
      <Confirmation
        closeConfirmation={closeConfirmation}
        deleteAndClose={deleteAndClose}
        isConfirmationOpen={isConfirmationOpen}
        text={confirmationText}
      />
    </ConfirmationContext.Provider>
  );
};
