import { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Languages } from "types";

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebounceCallback = (
  callback: (...args: any) => void,
  delay: number
) => {
  const timer = useRef<NodeJS.Timeout>();

  // Function to be returned from the hook, which gets debounced
  const debouncedCallback = (...args: any) => {
    // Clear the existing timer if the function is called again within the delay
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return debouncedCallback;
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useLanguageQuery = () => {
  const query = useQuery();
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = query.get("lang");
    if (lang) {
      const langSupported = Object.values(Languages).includes(
        lang as Languages
      );

      if (langSupported) {
        i18n.changeLanguage(lang as string);
      }

      const url = new URL(window.location.href);
      url.searchParams.delete("lang");
      window.history.replaceState({}, document.title, url.toString());
    }
  }, [query]);
};