import React from "react";
import { useHistory } from "react-router";

import TextInput from "components/inputs/TextInput";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { QuestionaireItem, useQuestionaireItems } from "./hooks";

export const QuestionaireItemTab = () => {
  const history = useHistory();
  const questionaireItems = useQuestionaireItems();

  const [questionaireFilter, setQuestionaireFilter] = React.useState(
    new URLSearchParams(history.location.search).get("query") || ""
  );
  const [selectedQuestionaires, setSelectedQuestionaires] =
    React.useState<QuestionaireItem[]>();

  React.useEffect(() => {
    if (!questionaireItems?.length) return;
    const regexVal = questionaireFilter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    const filteredItems = questionaireItems?.filter((item) => {
      return (
        item.directive?.toLowerCase().match(regexVal) ||
        item.article?.toLowerCase().match(regexVal) ||
        item.ref_number.toLowerCase().match(regexVal) ||
        item?.questions
          ?.find((q) => q.language === "DE")
          ?.content?.toLowerCase()
          .match(regexVal)
      );
    });

    setSelectedQuestionaires(filteredItems);
  }, [questionaireFilter, questionaireItems, setSelectedQuestionaires]);

  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterQuery = event.target.value;
    const urlQuery = new URLSearchParams(history.location.search);
    urlQuery.set("query", filterQuery);

    const newSearch = {
      search: urlQuery.toString(),
    };

    history.push(newSearch);
    setQuestionaireFilter(filterQuery);
  };

  return (
    <>
      <h1 className="text-xl">
        Backoffice Questionaire Fragen ({questionaireItems?.length || "0"})
      </h1>
      <div className="py-2">
        <PrimaryButton
          onClick={() => history.push("/_backoffice/_questionaire/new")}
        >
          Hinzufügen
        </PrimaryButton>
      </div>
      <TextInput
        defaultValue={questionaireFilter}
        // @ts-ignore
        onBlur={setFilter}
        placeholder="Filtern nach Referenznummer oder Artikel, z.B. '102100'"
      />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {selectedQuestionaires
          ?.sort((a, b) => a.ref_number.localeCompare(b.ref_number))
          ?.map((q) => {
            return (
              <div
                key={q.resource_id}
                onClick={() =>
                  history.push(
                    `/_backoffice/_questionaire/${q.resource_id}/edit`
                  )
                }
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
              >
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-m font-medium text-gray-900">
                    Ref: {q.ref_number} - {q.article}
                  </p>

                  <p className="text-sm text-gray-500 truncate">
                    <b>Richtlinie</b>: {q.directive || "Nicht zugeordnet"}
                  </p>

                  <p className="text-sm text-gray-500 truncate">
                    <b>Frage</b>:{" "}
                    {q.questions?.length &&
                      q.questions?.find(({ language }) => language === "DE")
                        ?.content}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Followups</b>:{" "}
                    {(q.followups?.length && q.followups.join(",")) || "Keine"}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Anhangfragen</b>:{" "}
                    {(q.annex_sections?.length && q.annex_sections.join(",")) ||
                      "Keine"}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>ist First Level Frage</b>:{" "}
                    {q.is_first_level ? "Ja" : "Nein"}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    <b>Übersetzungen</b>:{" "}
                    {q.questions?.map((q) => q.language).join(",")}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
