import { Trans, useTranslation } from "react-i18next";

import { Modal } from "components/Modal/Modal";
import { PrimaryButton } from "components/Button/PrimaryButton";

interface FidoReminderProps {
  onReject: () => void;
  onAccept: () => void;
}

export const FidoReminder = (props: FidoReminderProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        width="1/2"
        content={
          <div>
            <span className="text-xl font-bold">
              {t("authdevices.reminder.headline")}{" "}
            </span>
            <span className="text-xl text-gray-500">- Passkeys</span>
            <p className="text-sm text-gray-500">
              {t("authdevices.reminder.subline")}
            </p>
            <p className="text-md font-bold pt-4">
              {t("authdevices.reminder.what_is_it.headline")}
            </p>
            <p className="text-sm text-gray-500">
              <Trans i18nKey="authdevices.reminder.what_is_it.explanation">
                <a
                  href="https://de.wikipedia.org/wiki/FIDO2#Passkey"
                  className="text-cyan-500 underline"
                >
                  mit Passkeys
                </a>
                <a
                  href="https://www.yubico.com/products/yubikey-5-overview/"
                  className="text-cyan-500 underline"
                >
                  Yubi-Keys
                </a>
              </Trans>
            </p>
            <p className="text-md font-bold pt-4">
              {t("authdevices.reminder.do_it_now.headline")}
            </p>
            <p className="text-sm text-gray-500">
              {t("authdevices.reminder.do_it_now.explanation")}
            </p>
            <p className="text-md font-bold pt-4">
              {t("authdevices.reminder.how_to.headline")}
            </p>
            <p className="text-sm text-gray-500">
              <Trans i18nKey="authdevices.reminder.how_to.explanation" />
            </p>

            <div className="pt-9 text-right">
              <a
                className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
                onClick={() => props.onReject()}
              >
                {t("authdevices.reminder.cancel")}
              </a>
              <PrimaryButton float="right" onClick={() => props.onAccept()}>
                {t("authdevices.reminder.cta")}
              </PrimaryButton>
            </div>
          </div>
        }
        show={true}
        onClose={() => {}}
      />
    </>
  );
};
