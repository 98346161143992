import React from "react";
import { AxiosInstance } from "axios";
import { useHistory } from "react-router";
import { useMutation, useQueryClient } from "react-query";

import { Manufacturer } from "types";
import { useClient } from "utils/client";
import { ControlContext } from "context/Controls";

const createManufacturer = async (
  client: AxiosInstance,
  manufacturer: {
    manufacturer: Manufacturer;
  }
) => {
  const { data } = await client.post(
    `/manufacturers`,
    manufacturer.manufacturer
  );

  return { manufacturer: data };
};

export const useCreateManufacturer = () => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate } = useMutation(
    async (v: { manufacturer: Manufacturer }) =>
      await createManufacturer(client, v),
    {
      onError: (e) =>
        displayNotification!(
          "Fehler beim anlegen des Herstellers!",
          (e as Error).message,
          "error"
        ),
      onSuccess: (manufacturer) => {
        queryClient.invalidateQueries(["manufacturers"]);

        history.push(`/manufacturers/${manufacturer.manufacturer.crn}`);

        displayNotification!(
          "Hersteller erfolgreich angelegt",
          `"${manufacturer.manufacturer.company}" wurde angelegt`
        );
      },
    }
  );

  return mutate;
};
