import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/outline";
import React, { useEffect, useState, useCallback } from "react";
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
} from "react-beautiful-dnd";

import TextInput from "components/inputs/TextInput";
import TextArea from "components/inputs/TextAreaInput";
import { Risk as RiskComponent } from "components/Risk";
import Collapsible from "../../components/Collapsible/Collapsible";
import { RiskGroupping, Risk as RiskType } from "components/Risk/types";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useConfirmation } from "context/Confirmation";
import { DEFAULT_RISK_GROUP } from "./Risks";

interface RiskGroupsProps {
  grouping?: RiskGroupping[];
  risks: RiskType[];
  projectId: string;
  onGroupsReorder: (newGroups: RiskGroupping[]) => void;
}

export const RiskGroups: React.FC<RiskGroupsProps> = ({
  grouping,
  risks,
  projectId,
  onGroupsReorder,
}) => {
  const { t } = useTranslation();
  const { openConfirmation } = useConfirmation();
  const [editedName, setEditedName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editingGroup, setEditingGroup] = useState<string | null>(null);
  const [groups, setGroups] = useState<RiskGroupping[]>(grouping || []);

  useEffect(() => {
    setGroups(grouping || []);
  }, [grouping]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (type === "GROUP") {
      // Prevent reordering of the default group
      if (destination.index === 0) {
        window.alert(t("risks.group.default_group_reorder"));
        return;
      }

      const newGroups = Array.from(groups);
      const [reorderedGroup] = newGroups.splice(source.index, 1);
      newGroups.splice(destination.index, 0, reorderedGroup);

      setGroups(newGroups);
      onGroupsReorder(newGroups);
    } else if (type === "RISK") {
      const sourceGroup = groups.find((g) => g.id === source.droppableId);
      const destGroup = groups.find((g) => g.id === destination.droppableId);

      if (sourceGroup && destGroup) {
        if (sourceGroup === destGroup) {
          const newMembers = Array.from(sourceGroup.members);
          const [movedRisk] = newMembers.splice(source.index, 1);
          newMembers.splice(destination.index, 0, movedRisk);

          const newGroups = groups.map((group) => {
            if (group.id === sourceGroup.id) {
              return { ...group, members: newMembers };
            }
            return group;
          });

          setGroups(newGroups);
          onGroupsReorder(newGroups);
        } else {
          const newSourceMembers = Array.from(sourceGroup.members);
          const newDestinationMembers = Array.from(destGroup.members);
          const [movedRisk] = newSourceMembers.splice(source.index, 1);
          newDestinationMembers.splice(destination.index, 0, movedRisk);

          const newGroups = groups.map((group) => {
            if (group.id === sourceGroup.id) {
              return { ...group, members: newSourceMembers };
            }

            if (group.id === destGroup.id) {
              return { ...group, members: newDestinationMembers };
            }

            return group;
          });

          setGroups(newGroups);
          onGroupsReorder(newGroups);
        }
      }
    }
  };

  const handleEdit = useCallback((group: RiskGroupping) => {
    setEditingGroup(group.id);
    setEditedName(group.label);
    setEditedDescription(group.description || "");
  }, []);

  const handleSave = useCallback(
    (group: RiskGroupping) => {
      const updatedGroup = {
        ...group,
        label: editedName,
        description: editedDescription,
      };

      const newGroups = groups.map((g) => {
        if (g.id === group.id) {
          return updatedGroup;
        }

        return g;
      });

      onGroupsReorder(newGroups);
      setGroups(newGroups);
      setEditingGroup(null);
    },
    [editedName, editedDescription, groups, onGroupsReorder, setGroups]
  );

  const handleDelete = (deletingGroupId: string) => {
    const deleteRiskGroup = (deletingGroupId: string) => {
      const members = groups.find(
        (group) => group.id === deletingGroupId
      )?.members;

      const newGroups = groups
        .map((group) => {
          if (group.id === deletingGroupId) {
            return null;
          }

          if (group.id === DEFAULT_RISK_GROUP) {
            group.members = [...(group.members || []), ...(members || [])];
          }

          return group;
        })
        .filter(Boolean) as RiskGroupping[];

      onGroupsReorder(newGroups);
      setGroups(newGroups);
    };

    openConfirmation(() => deleteRiskGroup(deletingGroupId), {
      label: t("form.basic.label"),
      subline: t("risks.group.delete_confirmation_subline"),
    });
  };

  const handleCancel = useCallback(() => {
    setEditingGroup(null);
  }, []);

  return (
    <div className="risk-groups">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-groups" type="GROUP">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {groups.map((group, groupIndex) => (
                <Draggable
                  key={group.id}
                  index={groupIndex}
                  draggableId={group.id || group.label}
                  isDragDisabled={group.id === DEFAULT_RISK_GROUP}
                >
                  {(providedGroup) => (
                    <div
                      ref={providedGroup.innerRef}
                      {...providedGroup.draggableProps}
                      {...providedGroup.dragHandleProps}
                      className="mb-8"
                    >
                      <div className="flex center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 mr-2"
                        >
                          {group.id !== DEFAULT_RISK_GROUP && (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          )}
                        </svg>

                        <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full">
                          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                              <div className="ml-4 mt-4 w-full">
                                <div className="flex items-center mb-2 justify-between">
                                  {editingGroup === group.id ? (
                                    <div className="text-sm text-gray-500">
                                      <b>{t("risks.group.group")}:</b>{" "}
                                      <TextInput
                                        id="label"
                                        required
                                        defaultValue={group.label}
                                        uncontrolled
                                        // @ts-ignore
                                        onChange={(e) =>
                                          setEditedName(e.target.value)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 mr-2">
                                      <b>
                                        {t("risks.group.group")} #
                                        {groupIndex + 1}:
                                      </b>{" "}
                                      {group.label}
                                    </h3>
                                  )}

                                  {group.id !== DEFAULT_RISK_GROUP && (
                                    <div className="text-right flex justify-center items-center float-right">
                                      {(!editingGroup ||
                                        editingGroup !== group.id) && (
                                        <div className="flex items-center">
                                          <button
                                            onClick={() => handleEdit(group)}
                                            className="ml-3 hover:bg-gray-200 rounded-full p-2"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-5 w-5"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                            >
                                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                            </svg>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleDelete(group.id)
                                            }
                                            className="hover:bg-gray-200 rounded-full p-2"
                                          >
                                            <TrashIcon className="flex-shrink-0 h-5 text-red-500" />
                                          </button>
                                        </div>
                                      )}

                                      {editingGroup === group.id && (
                                        <>
                                          <a
                                            className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
                                            onClick={handleCancel}
                                          >
                                            {t("risks.group.cancel")}
                                          </a>
                                          <PrimaryButton
                                            onClick={() => handleSave(group)}
                                          >
                                            {t("risks.group.save")}
                                          </PrimaryButton>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="mt-1 text-sm text-gray-500">
                                  <div>
                                    <span>
                                      <b>
                                        {t("form.mitigations.description")}:
                                      </b>{" "}
                                      {editingGroup === group.id ? (
                                        <TextArea
                                          required
                                          id="description"
                                          name="description"
                                          label=""
                                          defaultValue={group.description}
                                          onChange={(e) =>
                                            setEditedDescription(e.target.value)
                                          }
                                        ></TextArea>
                                      ) : (
                                        group.description
                                      )}
                                    </span>
                                  </div>
                                  <Droppable
                                    droppableId={group.id || group.label}
                                    type="RISK"
                                  >
                                    {(providedRisks) => (
                                      <div
                                        {...providedRisks.droppableProps}
                                        ref={providedRisks.innerRef}
                                      >
                                        <Collapsible
                                          open={group.id === DEFAULT_RISK_GROUP}
                                          label={t(
                                            "risks.group.risks_in_group",
                                            { group: group.label }
                                          )}
                                        >
                                          {group.members?.map(
                                            (riskId) =>
                                              risks.find(
                                                ({ resource_id }) =>
                                                  resource_id === riskId
                                              )!
                                          ).length ? (
                                            <>
                                              {group.members
                                                ?.map(
                                                  (riskId) =>
                                                    risks.find(
                                                      ({ resource_id }) =>
                                                        resource_id === riskId
                                                    )!
                                                )
                                                .filter(Boolean)
                                                .map((risk, riskIndex) => (
                                                  <Draggable
                                                    key={risk.resource_id}
                                                    draggableId={risk.resource_id.toString()}
                                                    index={riskIndex}
                                                  >
                                                    {(providedRisk) => (
                                                      <div
                                                        ref={
                                                          providedRisk.innerRef
                                                        }
                                                        {...providedRisk.draggableProps}
                                                        {...providedRisk.dragHandleProps}
                                                        className="flex items-center my-2"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                          strokeWidth={1.5}
                                                          stroke="currentColor"
                                                          className="w-6 h-6 mr-2"
                                                        >
                                                          <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                                          />
                                                        </svg>
                                                        <RiskComponent
                                                          risk={risk}
                                                          projectId={projectId}
                                                          idx={riskIndex}
                                                        />
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                ))}
                                              {providedRisks.placeholder}
                                            </>
                                          ) : (
                                            <>
                                              <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                {providedRisks.placeholder}
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={1.5}
                                                  stroke="currentColor"
                                                  className="mx-auto h-8 w-8 text-gray-400"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
                                                  />
                                                </svg>
                                                <span className="mt-2 block text-sm font-semibold text-gray-400">
                                                  {t("risks.group.dnd_hint")}
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </Collapsible>
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
