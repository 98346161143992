import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { useClient } from "utils/client";

export interface RiskGroupConsequence {
  id: number;
  name: string;
  order: number;
}

export interface RiskGroupOrigin {
  id: number;
  name: string;
  order: number;
}

export interface RiskGroup {
  riskGroup: { id: number; name: string; order: number };
  consequences: RiskGroupConsequence[];
  origins: RiskGroupOrigin[];
}

const fetchRiskGroups = async (client: AxiosInstance) => {
  const { data } = await client.get(`/risk-groups`);

  return data;
};

export const useRiskGroups = () => {
  const [sorted, setSorted] = useState<RiskGroup[] | undefined>();
  const client = useClient();
  const { data: riskGroups } = useQuery<RiskGroup[]>(["risksGroups"], () =>
    fetchRiskGroups(client)
  );

  useEffect(() => {
    const sortedRiskGroups = riskGroups
      ?.map((riskgroup) => ({
        ...riskgroup,
        consequences: riskgroup.consequences.sort((a, b) => a.order - b.order),
        origins: riskgroup.origins.sort((a, b) => a.order - b.order),
      }))
      ?.sort((a, b) => a.riskGroup.order - b.riskGroup.order);

    setSorted(sortedRiskGroups);
  }, [riskGroups]);

  return { riskGroups: sorted };
};
