import React from "react";
import { useTranslation } from "react-i18next";
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";
import { ControlContext } from "context/Controls";
import { Account, Member } from "pages/Settings/hooks/useAccount";

const removeMember = async (client: AxiosInstance, email: string) => {
  try {
    const { data } = await client.delete(`/accounts/members`, {
      data: { member_email: email },
    });

    return data;
  } catch (error) {
    console.error(error);
    throw new Error((error as AxiosError).response?.data);
  }
};

export const useRemoveMember = () => {
  const client = useClient();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { displayNotification } = React.useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    async (member: Member) => {
      if (member.role === "admin") {
        throw new Error(t("notification.saving.remove_admin_failure"));
      }

      await removeMember(client, member.email);
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(["account"]);

        displayNotification!(
          t("notification.saving.remove_member_success"),
          ""
        );
      },
      onError: async (e: Error) => {
        await queryClient.refetchQueries(["account"]);

        displayNotification!(e.message, "", "error");
      },
    }
  );

  return { remove: mutate, isLoading };
};
