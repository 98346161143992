import { PropsWithChildren, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { CollapsibleProps } from "./types";

const Collapsible = ({
  open = false,
  child,
  label,
  useCustomStyles = false,
  children,
}: PropsWithChildren<CollapsibleProps>) => {
  const [isCollapsed, setIsCollapsed] = useState(!open);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={
        !useCustomStyles
          ? `${
              isCollapsed ? "" : "border "
            } rounded overflow-hidden mt-2 text-xs text-gray-600 w-full`
          : ""
      }
    >
      <div
        className="flex items-center justify-start p-2 pl-0 cursor-pointer "
        onClick={handleToggle}
      >
        <ChevronDownIcon
          className={`h-5 w-5 transition-transform ${
            isCollapsed ? "" : "transform rotate-180"
          }`}
        />
        <span className={!useCustomStyles ? "font-bold" : ""}>{label}</span>
      </div>
      <div>
        {!isCollapsed ? (
          <div className="overflow-hidden">
            <div
              className={
                !useCustomStyles
                  ? "p-2 bg-gray-50 leading-relaxed text-sm"
                  : "leading-relaxed text-sm"
              }
            >
              {child || children}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Collapsible;
