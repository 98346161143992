import i18n from "i18n";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import {
  QuestionaireItem,
  useQuestionaireItems,
  useQuestionaireItemsForProject,
} from "pages/Backoffice/Questionaire/hooks";
import {
  Form as FormType,
  Certification as FormCertfication,
} from "@slootsantos/certain-forms/dist/types";

import { Project } from "types";
import Form from "forms/Form";
import { useClient } from "utils/client";
import { FeedbackContext } from "context/Feedback";
import { TrackingContext } from "context/Tracking";
import { Loading } from "components/Loading/Loading";
import { useTranslatedStandards } from "hooks/useStandards";
import { Directive } from "pages/Backoffice/Questionaire/types";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import { ControlContext } from "context/Controls";
import { buildQuestionFields, uniqueArrayByProperty } from "./util/baseHelpers";

interface BaseInformationProps {
  type: "ce" | "ukCa";
}

const ALLOW_LISTED_DIRECTIVES = ["mrl", "lvd"];

const getExitQuestions = (questionaireItems: QuestionaireItem[]) => {
  return questionaireItems
    .filter(
      (q) =>
        q.is_directive_question && ALLOW_LISTED_DIRECTIVES.includes(q.directive)
    )
    .sort((a, b) => a.ref_number.localeCompare(b.ref_number));
};

interface DirectiveSearchRouteParams {
  projectId: string;
}

export const TopLevelDirectiveResearch = (props: BaseInformationProps) => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const langCode = i18n.language.split("-")[0].toUpperCase();

  const { track } = React.useContext(TrackingContext);
  const { displayFeedback } = React.useContext(FeedbackContext);
  const { displayNotification } = React.useContext(ControlContext);

  const { projectId } = useParams<DirectiveSearchRouteParams>();
  const { project, fetchInProgress } = useProjectDetails(projectId);

  const [fd, setFd] = React.useState<any>(null);
  const [preloaded, setPreloaded] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState<any>({});
  const questionaireItemsUnsorted = useQuestionaireItems();

  const {
    questionaireItems: questionaireItemsProject,
    isLoading: questionaireLoading,
  } = useQuestionaireItemsForProject(projectId);

  const questionaireItems = questionaireItemsUnsorted?.sort((a, b) =>
    a.ref_number?.localeCompare(b.ref_number)
  );
  const standardsProject = project?.standards;
  const { translations } = useTranslatedStandards();

  React.useEffect(() => {
    if (!questionaireItems?.length) return;

    const directiveResponses = getExitQuestions(questionaireItems);
    const formD: FormType = {
      type: FormCertfication.ce,
      documents: [],
      directives: [],
      sections: [
        {
          label: t("form.directiveResponses.label"),
          name: "directiveResponses",
          subline: t("form.directiveResponses.subline"),
          fields: buildQuestionFields(directiveResponses, langCode),
          isGroup: false,
        },
      ],
    };

    setFd(formD);
  }, [questionaireItems, langCode, t]);

  const handleSave = React.useCallback(
    async (form: any, isManualSubmit?: boolean) => {
      const directiveResponses = form?.directiveResponses
        ? Object.keys(form.directiveResponses)
            .filter((exitKey) => form?.directiveResponses[exitKey] !== "")
            .map((exitKey) => {
              const id = exitKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(form.directiveResponses[exitKey])),
              };
            })
        : [];

      const questionaireIds = [...directiveResponses].map(
        ({ id, isSelected, ...r }) => ({
          questionaireId: id,
          isSelected: isSelected,
        })
      );

      function onlyUnique(
        value: Directive,
        index: number | string,
        self: (Directive | undefined)[]
      ) {
        return self.indexOf(value) === index;
      }
      // TODO: needs to be more explicit about rules
      function mutexForLvdAndMrl(arr: string[]): string[] {
        if (arr.includes(Directive.lvd) && arr.includes(Directive.mrl)) {
          return arr.filter((item) => item !== Directive.lvd);
        }

        return arr;
      }

      const extractedDirectives = questionaireIds
        .filter((q) => q.isSelected)
        .map(
          (id) =>
            questionaireItems.find((q) => q.resource_id === id.questionaireId)
              ?.directive
        )
        .filter((d) => d !== undefined)
        .filter((d, i, s) => onlyUnique(d!, i, s)) as Directive[];

      const mutexDirectives = mutexForLvdAndMrl(extractedDirectives);

      await client.put(`/projects/${projectId}`, {
        payload: {
          ...project,
          projectdata: {
            ...project?.projectdata,
            ...(isManualSubmit ? { completed_toplevel_research: true } : {}),
          },
          directives: mutexDirectives.map((dir) => ({
            name: dir,
            completed_research: false,
          })),
        } as Project,
      });

      const uniqueQuestionaires = uniqueArrayByProperty(
        [
          ...questionaireIds,
          ...(questionaireItemsProject?.questionaires || []),
        ],
        "questionaireId"
      );

      await client.post(
        `/projects/${projectId}/questionaireItems`,
        uniqueQuestionaires
      );

      queryClient.refetchQueries(["projects", projectId]);
      queryClient.refetchQueries(["questionaireItems", projectId]);

      displayNotification!(
        t("notification.saving.generic_success"),
        t("notification.saving.research_success")
      );
    },
    [
      client,
      project,
      projectId,
      questionaireItems,
      queryClient,
      displayNotification,
    ]
  );

  const handleSubmit = useCallback(
    async (form: any) => {
      await handleSave(form, true);

      track("finish questionaire");

      displayFeedback!(FeedbackLocation.Questionaire);
      history.push(`/projects/${projectId}?saved=true`);
    },
    [displayFeedback, history, projectId, track, handleSave]
  );

  React.useEffect(() => {
    if (
      !questionaireItems?.length ||
      !questionaireItemsProject ||
      fetchInProgress
    ) {
      return;
    }

    const rawExitQuestions = getExitQuestions(questionaireItems);
    const exitQuestionIds = rawExitQuestions.map((q) => q.resource_id!);

    const directiveResponses = questionaireItemsProject?.questionaires
      ?.filter((q) => exitQuestionIds.includes(q.questionaireId))
      .reduce((acc, key) => {
        acc[key.questionaireId.toString() + "field"] = key.isSelected
          ? "1"
          : "0";

        return acc;
      }, {} as Record<string, string>);

    const defaultData = {
      directiveResponses,
    };

    setDefaultData(defaultData);

    if (
      defaultData &&
      !preloaded &&
      translations?.length &&
      fd?.sections.length
    ) {
      setPreloaded(true);
    }
  }, [
    fetchInProgress,
    questionaireItemsProject,
    questionaireItems,
    standardsProject,
    preloaded,
    translations,
    fd,
  ]);

  if (
    !fd?.sections ||
    questionaireLoading ||
    (questionaireItemsProject?.questionaires && !defaultData.directiveResponses)
  ) {
    return <Loading />;
  }

  return (
    <div className="questionaire">
      <Form
        persistStep
        submitLabel="Richtlinienrecherche abschließen"
        formData={fd}
        onSubmit={handleSubmit}
        onAutosave={handleSave}
        defaultValues={defaultData}
        disableStepper={
          !defaultData.directiveResponses || !defaultData.category
        }
      />
    </div>
  );
};
