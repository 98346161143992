import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import axios from "utils/client";

export interface DocumentationArticle {
  name: string;
  data: string;
}

const updateDocumentation = async (
  documentation: DocumentationArticle,
  id: string
) => {
  const { data } = await axios.put(`/docs/${id}`, documentation);

  return data;
};

export const useEditDocumentation = (documentationId: string) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    async (documentation: DocumentationArticle) =>
      await updateDocumentation(documentation, documentationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["documentations"]);
        history.goBack();
      },
    }
  );

  return { editDocumentation: mutate };
};
