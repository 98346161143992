import axios from "axios";
import { useContext, useEffect, useRef } from "react";

import { AuthContext } from "context/Auth";

let _token = "";
export const useClient = () => {
  const { refreshSession, token } = useContext(AuthContext);

  const client = useRef(
    axios.create({
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "X-Certain-Client": `web/${process.env.REACT_APP_VERSION}`,
      },
    })
  ).current;

  useEffect(() => {
    client.interceptors.request.use((request) => {
      request.headers["Authorization"] = `Bearer ${_token}`;

      return request;
    });

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        const statusCode = error.response ? error.response.status : null;

        if (statusCode === 403 || statusCode === 500) {
          refreshSession();
        }

        throw error;
      }
    );
  }, [token, refreshSession, client]);

  return client;
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

export const setClientToken = (token: string) => {
  _token = token;

  client.defaults.headers["Authorization"] = `Bearer ${token}`;
};

export default client;
