import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/solid";
import { useParams, useHistory } from "react-router-dom";

import { RiskGroups } from "./RiskGroups";
import { useDebounceCallback } from "utils/hooks";
import { useUpdateProject } from "hooks/useProjects";
import { BasePage } from "components/BasePage/BasePage";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { RiskGroupping, Risk as RiskType } from "components/Risk/types";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import { CreateRiskOnDashboard } from "components/Risk/CreateRiskOnDashboard";
import { CreateRiskGrouppingOnDashboard } from "components/Risk/CreateRiskGroupingOnDashboard";

interface RiskRouteParams {
  projectId: string;
}

export const DEFAULT_RISK_GROUP = "Default";

export const Risks = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<RiskRouteParams>();
  const { project, fetchInProgress } = useProjectDetails(projectId);
  const { updateProject } = useUpdateProject(
    project!,
    t("steps.risks.edit.resorting_success")
  );

  const [items, setItems] = useState<RiskType[]>([]);
  const [riskGroups, setRiskGroups] = useState<RiskGroupping[]>(
    project?.riskGrouping || []
  );

  const handleUpdate = useDebounceCallback((newGroups: RiskGroupping[]) => {
    if (project) {
      const orderedRisks = newGroups?.flatMap(({ members }) =>
        members.map((id) => items.find((item) => item.resource_id === id)!)
      );

      project.risks = orderedRisks;
      project.riskGrouping = newGroups.filter(
        (group) => group.id !== DEFAULT_RISK_GROUP
      );
      updateProject(projectId);
    }
  }, 2000);

  const onReorder = useCallback(
    (newGroups: RiskGroupping[]) => {
      handleUpdate(newGroups);
    },
    [handleUpdate]
  );

  useEffect(() => {
    if (project?.risks || project?.riskGrouping) {
      setItems(project.risks);

      const alreadyMappedItems =
        project.riskGrouping?.flatMap((group) => group.members) || [];

      const defaultRiskGroup: RiskGroupping = {
        id: DEFAULT_RISK_GROUP,
        label: t("risks.group.default_group_label"),
        description: t("risks.group.default_group_description"),
        members:
          items
            ?.filter((item) => !alreadyMappedItems.includes(item.resource_id))
            ?.map((item) => item.resource_id) || [],
      };

      setRiskGroups([
        defaultRiskGroup,
        ...(project.riskGrouping?.map((group) => ({
          ...group,
          members: group.members || [],
        })) || []),
      ]);
    }
  }, [project, items, t]);

  const handleCreateRisk = useCallback((newRisk: RiskType) => {
    setItems((prevItems) => [...prevItems, newRisk]);
  }, []);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.risks.label",
          location: `/projects/${projectId}/risks`,
        },
      ]}
      loading={fetchInProgress}
    >
      <div>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-3xl leading-6 font-medium text-gray-900">
            {t("steps.risks.label")}
          </h3>
          <div className="flex">
            <CreateRiskGrouppingOnDashboard
              projectCRN={projectId}
              button={(props) => (
                <button
                  onClick={props.onClick}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-black shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 disabled:opacity-50 inline-flex justify-center py-2 px-4 shadow-sm text-xs font-medium rounded-md"
                >
                  <PlusIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("risks.createGroupping.cta")}
                </button>
              )}
            />

            <CreateRiskOnDashboard
              projectCRN={projectId}
              button={(props) => (
                <button
                  onClick={props.onClick}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:ml-3 disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
                >
                  <PlusIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
                  {t("risks.create_new")}
                </button>
              )}
            />
          </div>
        </div>

        <div>
          {riskGroups.length ? (
            <RiskGroups
              risks={items}
              projectId={projectId}
              grouping={riskGroups}
              onGroupsReorder={onReorder}
            />
          ) : (
            <EmptyRisks
              projectId={projectId}
              onRiskCreated={handleCreateRisk}
            />
          )}
        </div>
      </div>

      <div className="pt-9 text-right flex justify-center items-center float-right">
        <a
          className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
          onClick={() => history.push(`/projects/${projectId}`)}
        >
          {t("risks.back_to_overview")}
        </a>

        <PrimaryButton onClick={() => history.push(`/projects/${projectId}`)}>
          {t("risks.save_all")}
        </PrimaryButton>
      </div>
    </BasePage>
  );
};

interface EmptyRiskProps {
  projectId: string;
  onRiskCreated: (risk: RiskType) => void;
}

const EmptyRisks = ({ projectId }: EmptyRiskProps) => {
  const { t } = useTranslation();

  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t("risks.no_risks")}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {t("risks.create_new_subline")}
      </p>
      <div className="mt-6">
        <CreateRiskOnDashboard
          projectCRN={projectId}
          button={(props) => (
            <button
              onClick={props.onClick}
              type="button"
              className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3 disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {t("risks.create_new")}
            </button>
          )}
        />
      </div>
    </div>
  );
};

export default Risks;
