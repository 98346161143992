import * as React from "react";

import { Risk } from "components/Risk/types";
import { useHistory } from "react-router";
import { SecondaryButton } from "components/Button/SecondaryButton";
import { PrimaryButton } from "components/Button/PrimaryButton";
import {
  calculatePerformanceLevel,
  calculateScore,
  calculateScoreAfterMitigation,
} from "components/Risk/utils";
import { useDeleteRiskTemplate } from "pages/Risk/hooks/useRisks";

type RiskProps = { risk: Risk; idx: number };

export const BackofficeRisk: React.FunctionComponent<RiskProps> = (props) => {
  const { risk } = props;
  const history = useHistory();
  const deleteRisk = useDeleteRiskTemplate(risk.resource_id);

  const totalRisk = calculateScore(risk.assessment);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Vorlage: {risk.blueprint_name}
            </h3>
            <div className="mt-1 text-sm text-gray-500">
              <div>
                <span>
                  <b>Vorlagenbeschreibung:</b> {risk.blueprint_description}{" "}
                  <br />
                  <b>Beschreibung:</b> {risk.description}
                </span>
              </div>
              <div>
                <span>
                  {" "}
                  <b>Typ:</b> {risk.group_id}{" "}
                </span>{" "}
              </div>
              <br />{" "}
              <div>
                <span className="font-bold">Ausgangrisiko: </span>{" "}
                <span>{totalRisk}</span>
              </div>
              <div>
                {risk.mitigations?.length > 1 && (
                  <>
                    <span className="font-bold">
                      Risiko nach den Maßnahmen:{" "}
                    </span>{" "}
                    <span>
                      {calculateScoreAfterMitigation(
                        risk,
                        risk.mitigations.length - 1
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 space-x-1 self-start">
            <PrimaryButton
              onClick={() =>
                history.push(`/_backoffice/_risks/${risk.resource_id}/edit`)
              }
            >
              Bearbeiten
            </PrimaryButton>
            <SecondaryButton onClick={deleteRisk}>
              Vorlage löschen
            </SecondaryButton>
          </div>
        </div>
      </div>

      <div className="border-gray-200 border-t px-4 py-5 sm:px-6">
        <h3 className="text-m leading-4 font-medium text-gray-900">
          Gefährdungsmindernde Maßnahmen
        </h3>
        {(risk.mitigations?.length &&
          risk.mitigations.map((mitigation, idx) => {
            return (
              <div key={idx} className="pb-6">
                <h3 className="text-md font-bold">Maßnahme#{idx + 1}</h3>
                <dl
                  key={mitigation.id}
                  className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3"
                >
                  <div className="sm:col-span-1">
                    <dt className="text-md font-medium text-gray-500">Art</dt>
                    <dd className="mb-1 text-sm text-gray-900">
                      {mitigation.type}{" "}
                      {mitigation.type === "ESS" && (
                        <i>
                          (Performance-Level-Required{" "}
                          {calculatePerformanceLevel(risk)})
                        </i>
                      )}
                      {mitigation.type === "PIK" && (
                        <img
                          src={
                            process.env.REACT_APP_DATA_URL +
                            "/pictograms/svgs/" +
                            mitigation.pictogram.match(/^[A-Z]\d{3}/)?.[0]
                          }
                          className="h-8 inline ml-2"
                          alt=""
                        />
                      )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-md font-medium text-gray-500">
                      Beschreibung
                    </dt>
                    <dd className="mb-1 text-sm text-gray-900">
                      {mitigation.description}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-md font-medium text-gray-500">
                      Risiko nach der Maßnahme
                    </dt>
                    <dd className="mb-1 text-sm text-gray-900">
                      <span className="font-bold">Score:</span>{" "}
                      <span>{calculateScoreAfterMitigation(risk, idx)}</span>
                      <br />
                      <span className="font-bold">Restrisiko: </span>
                      <span>{mitigation.left_risk}</span>
                    </dd>
                  </div>
                </dl>
              </div>
            );
          })) || <p className="mt-1 text-sm text-gray-900">Keine</p>}
      </div>
    </div>
  );
};
