import { useQuery } from "react-query";
import { AxiosInstance } from "axios";

import { useClient } from "utils/client";
import { DocumentData, Manufacturer } from "types";

export type ManufacturerWithDocuments = Manufacturer & {
  documents: DocumentData[];
};

const fetchManufacturers = async (
  client: AxiosInstance
): Promise<ManufacturerWithDocuments[]> => {
  const { data } = await client.get(`/manufacturers`);

  return data;
};

export const useManufacturers = () => {
  const client = useClient();
  const { data: manufacturers, isLoading: loadingQuery } = useQuery(
    ["manufacturers"],
    () => fetchManufacturers(client)
  );

  return {
    manufacturers,
    fetchInProgress: loadingQuery,
  };
};
