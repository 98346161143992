import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { useSteps } from "./hooks/useSteps";
import { BasePage } from "components/BasePage/BasePage";
import { ProjectDetailTabs } from "./ProjectDetailTabs";
import { Progress } from "components/Progress/Progress";
import { useProjectDetails } from "./hooks/useProjectDetails";

interface DetailsRouteParams {
  projectId: string;
}

export const ProjectDetails = () => {
  const { projectId } = useParams<DetailsRouteParams>();
  const history = useHistory();
  const { project, fetchInProgress } = useProjectDetails(projectId);

  const { steps } = useSteps(project);

  useEffect(() => {
    if (!fetchInProgress && !project) {
      history.push(`/dashboard`);
    }
  }, [fetchInProgress]);

  return (
    <BasePage
      loading={fetchInProgress}
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: `${project?.projectdata?.product_name || projectId}`,
          location: `/projects/${projectId}`,
        },
        { label: "Details", location: `/projects/${projectId}` },
      ]}
    >
      <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-">
        <div className="2xl:block min-w-0 flex-1">
          <span className="text-3xl pr-2">
            {project?.projectdata.product_name || project?.projectdata.id}
          </span>
          <span className="text-xs">
            Revision: {project?.projectdata.version}
          </span>
        </div>
      </div>

      <div className="flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-2">
        <div className="w-8/12">
          {project && <ProjectDetailTabs project={project!} />}
        </div>
        <div className="w-4/12 pl-4">
          <Progress steps={steps} />
        </div>
      </div>
    </BasePage>
  );
};
