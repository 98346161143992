import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";

import { User } from "./useUser";
import { useClient } from "utils/client";

export const useUpdateUser = () => {
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    async (user: User) => {
      const { data } = await client.put("/users", user);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
        history.goBack();
      },
    }
  );

  return { updateUser: mutate };
};
