import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Modal } from "components/Modal/Modal";
import TextInput from "components/inputs/TextInput";
import { useUpdateProjectPayload } from "hooks/useProjects";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import TextArea from "components/inputs/TextAreaInput";

interface CreateRiskGrouppingOnDashboardProps {
  projectCRN: string;
  button: React.FunctionComponent<React.ButtonHTMLAttributes<any>>;
}

export const CreateRiskGrouppingOnDashboard: React.FunctionComponent<
  CreateRiskGrouppingOnDashboardProps
> = (props) => {
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <>
      <div>
        <props.button onClick={() => setModalVisible(true)} />
      </div>

      <>
        <Modal
          onClose={() => setModalVisible(false)}
          show={modalVisible}
          width="1/2"
          content={
            <CreateRiskGrouppingForm
              projectCRN={props.projectCRN}
              onClose={() => setModalVisible(false)}
            />
          }
        />
      </>
    </>
  );
};

interface CreateRiskFormProps {
  projectCRN: string;
  onClose: () => void;
}

const CreateRiskGrouppingForm = (props: CreateRiskFormProps) => {
  const { t } = useTranslation();
  const { project, fetchInProgress } = useProjectDetails(props.projectCRN);
  const { updateProject, isLoading } = useUpdateProjectPayload(
    props.projectCRN
  );

  const [payload, setPayload] = React.useState({ name: "", description: "" });

  const handleCreateRiskGroupping = () => {
    updateProject({
      ...project!,
      riskGrouping: [
        ...(project!.riskGrouping || []),
        {
          id: Math.random().toString(36).substring(7),
          description: payload.description,
          label: payload.name,
          members: [],
        },
      ],
    });

    props.onClose();
  };

  return (
    <div>
      <span className="text-xl">{t("risks.createGroupping.headline")}</span>
      <div className="text-sm text-gray-500 pt-4">
        <Trans i18nKey="risks.createGroupping.subline">
          <p>
            Mit einer Gefährdungsgruppe können Sie Gefährdungen logisch
            gruppieren und so einen besseren Überblick behalten.
          </p>
          <p>
            Sie können Gefährdungen aus der Standard-Gruppe "Gefährdungen" in
            Ihre selbsterstellten Gruppen per{" "}
            <span className="font-bold">"Drag and Drop"</span> hin und her
            ziehen, um ihre Zuordnung und Reihenfolge zu ändern.
          </p>
        </Trans>
      </div>
      <div className="pt-4 pb-4">
        <TextInput
          required
          uncontrolled
          id="groupping"
          data-test="create-groupping-name"
          label={t("risks.createGroupping.groupName")}
          // @ts-ignore
          onChange={(e) => {
            setPayload((p) => ({ ...p, name: e.target.value }));
          }}
          placeholder={t("risks.createGroupping.placeholder")}
          onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
            if (evt.key === "Enter") {
              payload.name && handleCreateRiskGroupping();
            }
          }}
        />

        <TextArea
          required
          uncontrolled
          id="groupping_description"
          name="groupping_description"
          data-test="create-groupping-description"
          label={t("risks.createGroupping.description")}
          // @ts-ignore
          onChange={(e) => {
            setPayload((p) => ({ ...p, description: e.target.value }));
          }}
          placeholder={t("risks.createGroupping.placeholder")}
          onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
            if (evt.key === "Enter") {
              payload.name && handleCreateRiskGroupping();
            }
          }}
        />
      </div>

      <div className="text-right flex justify-center items-center float-right">
        <a
          className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
          onClick={() => props.onClose()}
        >
          {t("risks.group.cancel")}
        </a>

        <PrimaryButton
          float="right"
          disabled={!payload || isLoading}
          onClick={handleCreateRiskGroupping}
        >
          {isLoading
            ? t("risks.createGroupping.cta_loading")
            : t("risks.createGroupping.cta")}
        </PrimaryButton>
      </div>
    </div>
  );
};
