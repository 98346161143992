import React from "react";
import { useTranslation } from "react-i18next";

import { TextInputProps } from "./types";
import Collapsible from "components/Collapsible/Collapsible";

const ControlledInput = (props: TextInputProps) => {
  const { t } = useTranslation();
  const { defaultValue, collapsibleItems, uncontrolled, ...rest } = props;

  return (
    <input
      {...rest}
      placeholder={t(props.placeholder || "")}
      type="text"
      id={props.id}
      name={props.id}
      // @ts-ignore
      onChange={props.onBlur}
      value={defaultValue}
      autoComplete={props.autoComplete}
      className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
    />
  );
};

const UncontrolledInput = (props: TextInputProps) => {
  const { t } = useTranslation();
  const { collapsibleItems, uncontrolled, ...rest } = props;

  return (
    <input
      {...rest}
      placeholder={t(props.placeholder || "")}
      type="text"
      id={props.id}
      name={props.id}
      autoComplete={props.autoComplete}
      className="mt-1 focus:ring-cyan-500 focus:border-cyan-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
    />
  );
};

const ShortTextInput: React.FunctionComponent<TextInputProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
      <div className="flex justify-between">
        <label
          htmlFor={props.id}
          className="block text-sm font-medium text-gray-700"
        >
          {t(props.label)}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>

      {props.uncontrolled ? (
        <UncontrolledInput {...props} />
      ) : (
        <ControlledInput {...props} />
      )}
      {props.collapsibleItems && props.collapsibleItems.length > 0 ? (
        props.collapsibleItems?.map((x, index) => {
          return <Collapsible key={index} child={x.child} label={x.label} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShortTextInput;
