import React from "react";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "components/Button/SecondaryButton";
import { DeleteButton } from "components/Button/DeleteButton";
import { useConfirmation } from "context/Confirmation";

interface FormSectionProps {
  title: string;
  subline: string;
  infoText?: string;
  sectionName: string;
  children?: React.ReactNode;
  withDivider?: boolean | false;
  withDeletion?: boolean;
  deletionHandler?: () => void;
  confirmationLabel?: string;
  confirmationSubline?: string;
}

const FormSection: React.FunctionComponent<FormSectionProps> = (props) => {
  const { t } = useTranslation();
  const { openConfirmation } = useConfirmation();

  const handleDelete = () => {
    openConfirmation(
      () => {
        props.deletionHandler && props.deletionHandler();
      },
      {
        label: props.confirmationLabel
          ? t(props.confirmationLabel)
          : t("form.basic.label"),
        subline: props.confirmationSubline
          ? t(props.confirmationSubline)
          : t("form.basic.delete_confirmation_subline"),
      }
    );
  };

  return (
    <div className="mb-4">
      {props.withDivider && (
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-400"></div>
          </div>
        </div>
      )}

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t(props.title)}
              </h3>
              <p className="mt-1 text-sm text-gray-600 whitespace-pre-line">
                {t(props.subline)}
              </p>
              <p className="mt-6 text-sm text-gray-600 whitespace-pre-line h-0">
                {t(
                  props.infoText
                    ? props.infoText
                    : props.sectionName
                    ? `form.${props.sectionName}.info`
                    : ""
                )}
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">{props.children}</div>
                {props.withDeletion && (
                  <div className="text-right col-span-6 pt-4">
                    <DeleteButton onClick={handleDelete}>
                      {t("form.basic.remove")}
                    </DeleteButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSection;
