import { useParams } from "react-router-dom";

import { BasePage } from "components/BasePage/BasePage";
import { BackofficeRiskEdit } from "components/Backoffice/Risk/Edit";
import { useOneRiskTemplate } from "pages/Risk/hooks/useRisks";

interface RiskCreateRouteParams {
  riskId: string;
}

export const EditRiskBackoffice = () => {
  const { riskId } = useParams<RiskCreateRouteParams>();
  const { risk, isLoading } = useOneRiskTemplate(riskId);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Risiko-Vorlagen",
          location: "/_backoffice/_risks",
        },
        {
          label: "Vorlage bearbeiten",
          location: `/_backoffice/_risks/${riskId}/edit`,
        },
      ]}
      loading={!risk || isLoading}
    >
      <div>
        <BackofficeRiskEdit risk={risk!} />
      </div>
    </BasePage>
  );
};
