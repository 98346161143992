import { useParams } from "react-router";

import { BasePage } from "components/BasePage/BasePage";
import BaseInformation from "pages/tabs/BaseInformation";
import { useProjectDetails } from "./Details/hooks/useProjectDetails";

interface DirectiveSearchRouteParams {
  projectId: string;
}

const DirectiveSearch = () => {
  const { projectId } = useParams<DirectiveSearchRouteParams>();
  const { project } = useProjectDetails(projectId);

  return (
    <BasePage
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: project?.projectdata?.product_name || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "steps.questionaire.label",
          location: `/projects/${projectId}/questionaire`,
        },
      ]}
    >
      <BaseInformation type={"ce"} />
    </BasePage>
  );
};

export default DirectiveSearch;
