import { Plan } from "pages/Backoffice/TEST/PlanSelection";
import { useQuery } from "react-query";
import { useClient } from "utils/client";

export interface Account {
  email_adress: string;
  city: string;
  state: string;
  street_address: string;
  post_code: string;
  country: string;
  is_admin: boolean;
  phone: string;
  resource_id: string;
  subscription?: {
    plan: Plan;
    frequency: string;
    trailEnd: number;
    cancelled: boolean;
    freeSeats: number;
  };
  members?: Member[];
}

export interface Member {
  email: string;
  role: string;
}

export const useAccount = () => {
  const axios = useClient();
  const { data, isLoading } = useQuery("account", async () => {
    const { data } = await axios.get<Account>("/accounts");
    return data;
  });

  return { account: data, isLoading };
};
