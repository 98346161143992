import i18n from "i18n";
import { useMutation } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";

import client from "utils/client";
import Logo from "logos/Logo_dark.png";
import CountrySelectInputUnconnected from "components/inputs/CountrySelectInputUnconnected";

import { Feature, useFeatureFlags } from "context/FeatureFlag";
import { LanguageSelector } from "components/common/LanguageSelector";
import { ControlContext } from "context/Controls";
import { AxiosError } from "axios";

interface RegistrationPayload {
  email: string;
  name: string;
  city: string;
  street: string;
  postcode: string;
  company: string;
  country: string;
  state: string;
  invitationToken: string;
}
const useRegistration = () => {
  const { displayNotification } = useContext(ControlContext);

  const { mutate, error, isSuccess, isError, isLoading } = useMutation(
    async (payload: RegistrationPayload) => {
      await client.post("/signup", {
        email_adress: payload.email,
        name: payload.name,
        city: payload.city,
        state: payload.state,
        company: payload.company,
        country: payload.country,
        street_address: payload.street,
        post_code: payload.postcode,
        invitation_token: payload.invitationToken,
        language_code: i18n.language,
      });
    },
    {
      onError: (e: AxiosError) => {
        if (e.response?.data.match(/Expired/)) {
          displayNotification!(
            "Registrierung fehlgeschlagen",
            "Der Einladungslink ist abgelaufen. Bitte wenden Sie sich an den Einladenden.",
            "error"
          );
        } else {
          displayNotification!(
            "Registrierung fehlgeschlagen",
            e.response?.data,
            "error"
          );
        }

        return e.response?.data;
      },
    }
  );

  return { register: mutate, error, isSuccess, isError, isLoading };
};

export const Registration = () => {
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostCode] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [invitationToken, setInvitationToken] = useState("");

  const [registered, setRegistered] = useState(false);

  const { search } = useLocation();

  const { t } = useTranslation();
  const { register, error, isSuccess, isLoading } = useRegistration();
  const { isFeatureEnabled } = useFeatureFlags();
  const currentLanguage = i18n.language.split("-")[0];

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    setInvitationToken(searchParams.get("invitation") || "");
    setEmail(searchParams.get("prefill") || "");
  }, [search, setEmail]);

  useEffect(() => {
    if (isSuccess) {
      setRegistered(true);
    }
  }, [error, isSuccess]);

  const handleSubmit = async () => {
    register({
      email,
      name,
      city,
      street,
      postcode,
      company,
      country,
      state,
      invitationToken,
    });

    // setRegistered(true);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <section className="flex h-screen">
        {registered && (
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a
              href="#"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
            >
              <img className="w-8 h-8 mr-2" src={Logo} alt="logo" />
            </a>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <SuccessfullyRegistered email={email} />
              </div>
            </div>
          </div>
        )}

        {!registered && (
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-fit ">
            <a
              href="#"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
            >
              <img className="w-8 h-8 mr-2" src={Logo} alt="logo" />
            </a>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
                  {t("account.sign_up.title")}
                </h1>

                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  {/* <div>
                    <span className="block mb-2 text-md font-medium text-gray-900">
                      {t("account.sign_up.user_data")}
                    </span>
                  </div> */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.email")}
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      defaultValue={email}
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  {/* <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.name")}
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Max Mustermann"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      required
                    />
                  </div> */}
                  <div className="border-b border-gray-300"></div>
                  {/* <div>
                    <span className="block mb-2 text-md font-medium text-gray-900">
                      {t("account.sign_up.company.title")}
                    </span>
                  </div>

                  <div>
                    <label
                      htmlFor="adress"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.company.name")}
                    </label>
                    <input
                      onChange={(e) => setCompany(e.target.value)}
                      type="text"
                      name="adress"
                      id="adress"
                      placeholder="Hersteller GmbH"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="adress"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.company.street")}
                    </label>
                    <input
                      onChange={(e) => setStreet(e.target.value)}
                      type="text"
                      name="adress"
                      id="adress"
                      placeholder={t(
                        "account.sign_up.company.street_placeholder"
                      )}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="postcode"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.company.zip")}
                    </label>
                    <input
                      onChange={(e) => setPostCode(e.target.value)}
                      type="text"
                      name="postcode"
                      id="postcode"
                      placeholder="12345"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="city"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.company.city")}
                    </label>
                    <input
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      name="city"
                      id="city"
                      placeholder="Berlin"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="state"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.company.state")}
                    </label>
                    <input
                      onChange={(e) => setState(e.target.value)}
                      type="text"
                      name="state"
                      id="state"
                      placeholder={t(
                        "account.sign_up.company.state_placeholder"
                      )}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 "
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t("account.sign_up.company.country")}
                    </label>
                    <CountrySelectInputUnconnected
                      required
                      label=""
                      id="country"
                      name="country"
                      defaultValue={country}
                      onBlur={(e) => setCountry(e.target.value)}
                    />
                  </div> */}
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-cyan-600 text-cyan-600"
                        required
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="terms"
                        className="font-light text-gray-500"
                      >
                        <Trans i18nKey="account.sign_up.accept_terms">
                          <a
                            className="font-medium text-cyan-600 hover:underline"
                            href={`https://certain-cloud.com/${currentLanguage}/AGB`}
                            target="_blank"
                            rel="noreferrer"
                          />
                        </Trans>
                      </label>
                    </div>
                  </div>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className={`flex w-full items-center gap-x-1.5 justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600 ${
                      isLoading ? "opacity-25" : "opacity-100 hover:bg-cyan-500"
                    }`}
                  >
                    {invitationToken
                      ? t("account.sign_up.accept_invitation")
                      : t("account.sign_up.title")}
                  </button>
                  <p className="text-sm font-light text-gray-500">
                    <Trans i18nKey="account.sign_up.account_exits">
                      <Link
                        to="/signin"
                        className="font-medium text-cyan-600 hover:underline"
                      />
                    </Trans>
                  </p>
                </form>
              </div>
            </div>
          </div>
        )}
      </section>

      <div className="absolute bottom-0 left-0 m-4 p-4">
        {isFeatureEnabled(Feature.MultiLanguage) && <LanguageSelector />}
      </div>
    </div>
  );
};

interface SuccessfullyRegisteredProps {
  email: string;
}

export const SuccessfullyRegistered = ({
  email,
}: SuccessfullyRegisteredProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-md bg-white p-4">
      <div className="flex items-center text-center">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-md font-medium text-green-800">
            {t("account.sign_up.account_created_title")}
          </p>
          <p className="text-xs text-green-800">
            {t("account.sign_up.account_created_subline")}
          </p>
        </div>
      </div>
      <div className="pt-8 text-center">
        <Trans i18nKey="account.sign_up.account_created_cta">
          <Link
            className="hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 disabled:opacity-50 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-cyan-600"
            to={`/signin?login=${encodeURIComponent(email)}`}
          />
        </Trans>
      </div>
    </div>
  );
};
