import { BasePage } from "components/BasePage/BasePage";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useClient } from "utils/client";
import { PlanSelection } from "./PlanSelection";

export const CheckoutTest = () => {
  const client = useClient();
  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        {
          label: "Test Checkout",
          location: "/_backoffice/_risks",
        },
      ]}
    >
      <div>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-3xl leading-6 font-medium text-gray-900">
            ! CHECKOUT TEST !
          </h3>
        </div>

        <section>
          <PlanSelection />
          {/* <div className="product">
            <img
              src="https://i.imgur.com/EHyR2nP.png"
              alt="The cover of Stubborn Attachments"
            />
            <div className="description">
              <h3>Certain PRO</h3>
              <h5>€299 / Monat</h5>
            </div>
          </div>

          <div className="flex flex-col py-2">
            <span className="font-bold">
              NICHT EURE EIGENEN DATEN EINFÜGEN!!
            </span>
            <span>Kredikarten Nummer: 4242 4242 4242 4242</span>
            <span>Expiration: 02/24</span>
            <span>CVC: 424</span>
          </div>

          <PrimaryButton
            onClick={async () => {
              const { data } = await client.get("/payment/checkout", {
                maxRedirects: 0,
              });
              window.location.href = data;
            }}
          >
            Checkout to Stripe
          </PrimaryButton>
           */}
        </section>
      </div>
    </BasePage>
  );
};

export default CheckoutTest;
