import React, { PropsWithChildren } from "react";
import mixpanel from "mixpanel-browser";

import { AuthContext } from "./Auth";

const TEMP_MIXPANEL_TOKEN = "3e198b6c72c2f467175cba546d23c5c4";

interface ITrackingContext {
  track: (event: string, additionalData?: any) => void;
}

export const TrackingContext = React.createContext<ITrackingContext>({
  track: () => {},
});

export const TrackingProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { user, isAuthenticated } = React.useContext(AuthContext);

  React.useEffect(() => {
    // if (!user?.username) {
    //   return;
    // }

    mixpanel.init(TEMP_MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    });

    mixpanel.identify(user?.username || "none");
    mixpanel.track("Logged in");
  }, [user, isAuthenticated]);

  const track = (event: string, additionalData?: any) => {
    // mixpanel.track(event, additionalData);
  };

  return (
    <TrackingContext.Provider value={{ track }}>
      {children}
    </TrackingContext.Provider>
  );
};
